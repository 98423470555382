import bus from "../bus";
import settingsTemplate from "./delivery_settings.html";
import Api from "../api";
import formats from "../models/formats";

let DeliverySettings = {
  name: "DeliverySettings",

  props: [
    "caseObject",
  ],
  template: settingsTemplate,
  data: function() {
    return {
      delivery_paused: this.caseObject.delivery_paused,
    }
  },
  created() {

  },
  methods: {
    updateCaseData() {
      bus.$emit('case-data-changed', {
        delivery_paused: this.caseObject.delivery_paused,// === "true",
      });
    }
  },
  watch: {

  },
};

export default DeliverySettings;
