export default {
  props: {
    field: {type: Object},
    sortBy: {type: String},
    sortDesc: {type: Boolean},
  },
  data: function() {
    return {
      slot: 'HEAD_' + this.field.key
    }
  },
  template:`<div class="sort-component">
              <div v-html="field.label" class="sort-label">
              </div>
              <!--<div v-if="field.sortable" v-bind:class="{'sort-arrows':field.sortable}">-->
                <!--<font-awesome-icon :class="{ active: field.key === sortBy && !sortDesc }" -->
                  <!--icon="caret-up"></font-awesome-icon>-->
                <!--<font-awesome-icon :class="{ active: field.key === sortBy && sortDesc }" -->
                  <!--icon="caret-down"></font-awesome-icon>-->
              <!--</div>-->
            </div>`
}