export default {
  template: `<div class="row p-3 justify-content-center">
                <div class="column align-items-center">
                  <p>You do not have permission to access this page.</p>
                  
                  <!--<b-button-->
                    <!--variant="outline-secondary"-->
                    <!--@click="$router.go(-2)">-->
                    <!--<i class="fa fa-angle-left"></i> Back-->
                  <!--</b-button>-->
                </div>
            </div>`
}