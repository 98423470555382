<template>
    <b-form-row>
       <b-col
          v-for="dataField in fields"
          :key="getDataFieldKey(dataField)"
          :class="suppressMargin || isHidden(dataField) ? '': 'mb-3'"
          :offset="dataField.offset_twelfths || 0"
          :md="dataField.display_twelfths || (12 - (dataField.offset_twelfths || 0))">
         <component
               :is="getComponentForDataField(dataField)"
               v-bind="dataField"
               :initial_value="getValueForDataField(dataField)"
               :applicants="applicants"
               :selectedCoverages="selectedCoverages"
               :applicantContext="getApplicantContext(dataField)"
               :coverageContext="getCoverageContext(dataField)"
               :premiumMode="premiumMode"
               :products="products"
               :globalBeneficiaries="globalBeneficiaries"
               :paymentInformation="paymentInformation"
               :siteConfig="siteConfig"
               :suppressMargin="suppressMargin"
               :rates="rates"
               @value-change="handleDataFieldChanged(dataField, $event, $event.value)"
         ></component>
       </b-col>
  </b-form-row>
</template>

<script>
import {
  enrollDateInput,
  enrollBirthdateInput,
  enrollEmailInput,
  enrollTextInput,
  enrollTextAreaInput,
  integerInput,
  eofiInput,
  radioInput,
  nonButtonsRadioInput,
  checkboxInput,
  salaryInput,
  radioYesNo,
  heightInput,
  selectInput, ssnInput, phoneInput, zipCodeInput, coverageQuestionLabel, coverageQuestionYesNo, coverageQuestionTextArea, GenericOptionCoverageSelection,
  Paragraph,
  sectionLabel,
  staticContent, coverageQuestionHeader
} from "./FormInput";
import childrenEditor from "./ChildrenEditor"
import beneficiariesEditor from "./BeneficiariesEditor"
import ConditionalFieldSet from "./ConditionalFieldSet"
import SendCallCenterReviewEmailButton from "./SendCallCenterReviewEmailButton"

import bus from "./messages"
import {Applicant, ApplicantList, Coverage, CoverageList, ProductList, PremiumMode} from "./models";
import ReviewDocuments from "./ReviewDocuments";
import paymentInformationCapture from "./PaymentInformationCapture";
import paymentWithAppCapture from "./PaymentWithAppCapture";
import UsePrimaryContactInfoYesNo from "./UsePrimaryContactInfoYesNo";
import FaceValueCoverageSelection from "./FaceValueCoverageSelection";
import BirthStateCountry from "./BirthStateCountry";
import UnderwritingDecisionDetails from "./UnderwritingDecisionDetails";
import AudioAnnouncementFormInput from "./AudioAnnouncementFormInput";


let EnrollmentFieldSet = {
  name: "EnrollmentFieldSet",
  props: {
    fields: {
      type: Array,
      required: true
    },
    applicants: {
      type: ApplicantList,
      required: true,
    },
    products: {
      type: ProductList,
      required: false,
    },
    selectedCoverages: {
      type: CoverageList,
      required: true
    },
    applicantContext: {
      type: Applicant,
      required: false
    },
    coverageContext: {
      type: Coverage,
      required: false
    },
    premiumMode: {
      type: PremiumMode,
      required: true
    },
    globalBeneficiaries: {
      type: Array,
      required: false
    },
    paymentInformation: {
      type: Object,
      required: false
    },
    siteConfig: {
      type: Object,
      required: true
    },
    suppressMargin: {
      type: Boolean,
      required: false,
      default: false
    },
    rates: {
      type: Array,
      required: false
    }
  },
  data: function() {
    return {

    }
  },
  methods: {
    getComponentForDataField(dataField) {
      let fieldMap = new Map([
        ["section-label", sectionLabel],
        ["coverage-question-label", coverageQuestionLabel],
        ["coverage-question-header", coverageQuestionHeader],
        ["coverage-question-yes-no", coverageQuestionYesNo],
        ["coverage-question-text-area", coverageQuestionTextArea],
        ["enroll-text-input", enrollTextInput],
        ["enroll-text-area", enrollTextAreaInput],
        ["enroll-email-input", enrollEmailInput],
        ["enroll-date-input", enrollDateInput],
        ["enroll-birthdate-input", enrollBirthdateInput],
        ["enroll-integer-input", integerInput],
        ["height-input", heightInput],
        ["checkbox-input", checkboxInput],
        ["radio-input", radioInput],
        ["standard-radio-input", nonButtonsRadioInput],
        ["yes-no-select", radioYesNo],
        ["enroll-select", selectInput],
        ["children-editor", childrenEditor],
        ["beneficiaries-editor", beneficiariesEditor],
        ["enroll-ssn-input", ssnInput],
        ["phone-input", phoneInput],
        ["zipcode-input", zipCodeInput],
        ["salary-input", salaryInput],
        ["face-value-selection", FaceValueCoverageSelection],
        ["birth-state-country", BirthStateCountry],
        ["generic-coverage-option-selection", GenericOptionCoverageSelection],
        ["conditional-fieldset", ConditionalFieldSet],
        ["review-documents", ReviewDocuments],
        ["send-review-email-button", SendCallCenterReviewEmailButton],
        ["paragraph", Paragraph],
        ['static-content', staticContent],
        ["payment-information-capture", paymentInformationCapture],
        ["payment-with-app-capture", paymentWithAppCapture],
        ["use-primary-contact-info-yes-no-select", UsePrimaryContactInfoYesNo],
        ["underwriting-decision-details", UnderwritingDecisionDetails],
        ["audio-announcement-form-input", AudioAnnouncementFormInput]
      ]);
      let inputComponent = fieldMap.get(dataField.input_type);
      if (inputComponent === undefined) {
        console.error(`Bad field component type`, dataField.input_type);
        return
      }

      return inputComponent;
    },

    getCoverageContext(dataField) {
      if (dataField.contextType === "coverage"
          && dataField.coverageApplicant !== undefined
          && dataField.coverageProduct !== undefined) {
        let applicant = this.applicants.getApplicantById(dataField.coverageApplicant);
        let product = this.products.getByName(dataField.coverageProduct);
        return this.selectedCoverages.getOrCreateCoverageForApplicantProduct(applicant, product);
      }
      return this.coverageContext;
    },

    getApplicantContext(dataField) {
      if (dataField.contextType === "applicant"
          && dataField.coverageApplicant !== undefined
          && dataField.coverageProduct !== undefined) {
        return this.applicants.getApplicantById(dataField.coverageApplicant);
      } else if (dataField.contextType === "applicant"
          && dataField.applicantId !== undefined) {
        return this.applicants.getApplicantById(dataField.applicantId);
      }
      return this.applicantContext;
    },

    getDataFieldKey(dataField) {
      let coverage_context = this.getCoverageContext(dataField);

      if (this.getApplicantContext(dataField)) {
        return `applicant_${this.getApplicantContext(dataField).id}_${dataField.name}`;
      } else if (coverage_context) {
        return `coverage_${coverage_context.product.id}_${coverage_context.applicant.id}_${dataField.name}`;
      } else {
        return `enrollment_${dataField.name}`;
      }
    },

    getValueForDataField(dataField) {

      // Treat value on the field as an override
      if (dataField.initial_value) {
        return dataField.initial_value;
      }

      let contextData = this.getFieldContextData(dataField);
      if (contextData === null) {

        return null;
      }

      let val = contextData[dataField.name];
      if (val === undefined) {
        return null;
      } else {
        return val;
      }
    },

    shouldInclude(dataField) {
      if (dataField.showIfTrue) {
        let data = this.getFieldContextData(dataField);
        let val = data[dataField.showIfTrue];
        return val === true || val === "yes";
      } else if (dataField.showIfFalse) {
        let data = this.getFieldContextData(dataField);
        let val = data[dataField.showIfFalse];
        return val === false || val === "no";
      }

      return true;
    },

    getFieldContextData(dataField) {
      if (this.getApplicantContext(dataField)) {
        return this.getApplicantContext(dataField).data;
      } else if (this.getCoverageContext(dataField)) {
        return this.getCoverageContext(dataField).data;
      }
      // TODO lookup enrollemnt-level field initial data
      //return
      return null;
    },

    handleDataFieldChanged(dataField, e, data) {
      if (this.getApplicantContext(dataField)) {
        bus.$emit("applicant-data-changed", {applicant: this.getApplicantContext(dataField), name: e.name, value: e.value});
      } else if (this.getCoverageContext(dataField)) {
        let coverage = this.getCoverageContext(dataField);
        bus.$emit("coverage-data-changed", {coverage: coverage, name: e.name, value: e.value});
      } else {
        bus.$emit("enrollment-data-changed", {step: this.name, name: e.name, value: e.value});
      }
    },

    isHidden(dataField) {
      return (dataField.input_type === 'conditional-fieldset' && !this.isShowing);
    }

  }

};


export default EnrollmentFieldSet;
</script>
