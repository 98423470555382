import bus from "../bus";
import settingsTemplate from "./api_settings.html";
import api from "../api";

let APISettings = {
  name: "APISettings",

  props: [
    "caseObject",
    "users",
    "userPermissions",
    "caseUsers",
  ],
  template: settingsTemplate,
  data: function () {
    return {
      selectedAPIUser: null,

      apiKey: null,
      apiKeyExpiration: null,
      apiKeyExpirationFormatted: null,

      showCopyCaseIDTooltip: false,
      showCopyAPIKeyTooltip: false,

      apiUsers: null,
      selectedNonAPIUsers: null,
      selectedAPIUsers: null
    }
  },
  computed: {
    selectedUsers() {
      return this.selectedNonAPIUsers.concat(this.selectedAPIUsers);
    },
  },
  created() {
    this.apiUsers = [...this.users].filter(user => user.isApiUser);
    this._processCaseUsers();
  },
  methods: {
    resetAPIKey() {
      if (!this.selectedAPIUser) {
        return;
      }
      api.resetAuthToken(this.selectedAPIUser.value)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.getAuthTokenInfo();
          }
        });
    },
    renewAPIKey() {
      if (!this.selectedAPIUser) {
        return;
      }
      api.renewAuthToken(this.selectedAPIUser.value)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.getAuthTokenInfo();
          }
        });
    },
    _processCaseUsers() {
      this.selectedNonAPIUsers = [...this.caseUsers].filter(user => !user.isApiUser);
      this.selectedAPIUsers = [...this.caseUsers].filter(user => user.isApiUser);
      if (this.selectedAPIUsers.length > 0) {
        this.selectedAPIUser = this.selectedAPIUsers[0];
      }
    },
    handleCopyClick(ref) {
      let el = this.$refs[ref];//.$el;
      el.select(); // select the text in the element

      try {
        if (document.execCommand('copy')) {
          if (ref === 'caseIDElement') {
            this.showCopyCaseIDTooltip = true
          } else if (ref === 'apiKeyElement') {
            this.showCopyAPIKeyTooltip = true
          }
        }
      } catch (err) {
        alert('Problem copying to clipboard. Try copying manually.');
      }

      // close tooltip after 2 seconds
      setTimeout(() => {
        this.showCopyCaseIDTooltip = false;
        this.showCopyAPIKeyTooltip = false;
      }, 2000);

      // unselect the range
      window.getSelection().removeAllRanges()
    },
    removeUser(user) {
      user.restrict = false;
      this.selectedAPIUsers = this.selectedAPIUsers.filter(u => u.value !== user.value);
      this.selectedAPIUser = null;
      this.caseUsersChanged();
    },
    updateSelectedAPIUser(user) {
      this.selectedAPIUser = user;
    },
    userCanAssignUserToCase() {
      return this.userPermissions.canManageCaseUsers();
    },
    getAuthTokenInfo() {
      this.apiKey = null;
      this.apiKeyExpiration = null;

      if (!this.selectedAPIUser) {
        return;
      }

      api.getAuthTokenInfo(this.selectedAPIUser.value)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.apiKey = response.auth_token;
            this.apiKeyExpiration = response.auth_token_expiration;
            this.apiKeyExpirationFormatted = response.auth_token_expiration_display;
          }
        })
    },
    updateCase() {
      bus.$emit('case-data-changed', {
        tagged_restricted_to_own_enrollments: this.caseObject.tagged_restricted_to_own_enrollments
      })
    },
    caseUsersChanged() {
      bus.$emit('case-users-changed', this.selectedUsers);
    }
  },
  watch: {
    selectedAPIUser(newVal, oldVal) {
      this.getAuthTokenInfo();
      if (newVal) {
        this.selectedAPIUsers = [newVal];
      } else {
        this.selectedAPIUsers = [];
      }
      this.caseUsersChanged();
    },
    caseUsers(val) {
      this._processCaseUsers();
    }
  },
};

export default APISettings;
