import loginForm from './login-form.html'
import Api from './api.js'
import HideNavigation from "./nav/HideNavigation";
import colorDefaults from './models/color-defaults';
import {siteConfig, maintenanceDetail} from "./app";

export default {
  template: loginForm,
  components: {HideNavigation},
  props: {
    userPermissions: { type: Object },
    defaultPath: { type: String }
  },
  data () {
    return {
      siteConfig: siteConfig,
      isLoading: true,
      errors:[],
      usernameValue:null,
      passwordValue:null,
      emailConfirmed: false,
      redirectTo: null,
      colorDefaults,
      maintenanceMode: siteConfig.maintenance_mode,
      maintenanceMsg: maintenanceDetail && maintenanceDetail.maintenance_msg
    }
  },
  methods: {
    doLogin() {
      Api.login({
        email: this.usernameValue,
        password: this.passwordValue
      })
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.errors = response.errors;
        } else {
          this.errors = [];
          this.usernameValue = null;
          this.passwordValue = null;
          this.clearTabFilters();
          this.clearEnrollmentLocation();

          this.pushNextPage();
        }
      });
    },
    clearTabFilters() {
      if(localStorage.savedTabs) {
        let savedTabs = JSON.parse(localStorage.savedTabs);
        savedTabs.forEach(t => {
          t.filters.caseSearch = '';
          t.filters.enrollmentFilterValue = 'all';
        });
        localStorage.savedTabs = JSON.stringify(savedTabs);
      }
    },
    clearEnrollmentLocation() {
      delete localStorage.enrollmentState;
      delete localStorage.enrollmentCity;
    },
    pushNextPage() {
      if (this.redirectTo && this.redirectTo.length > 0) {
        this.$router.replace(this.redirectTo);
        this.redirectTo = null;
      } else {
        this.$router.push(this.defaultPath);
      }
    },
    toggleLoginBtn(){
      this.maintenanceMode = false
    }
  },
  created() {
    this.redirectTo = this.$route.query.redirectTo;

    Api.me()
    .then(response => {
      if (!response.errors) {
        this.pushNextPage();
      } else {
        this.isLoading = false;
        if (this.$route.path.includes('confirmation')) {
          //console.log(this. $route.params.token);
          Api.confirmUserEmailFinalize({token: this.$route.params.token})
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              this.errors = response.errors;
            } else {
              this.emailConfirmed = true;
            }
          });
        }
      }
    });
  }
}
