import formats from "./formats";
import {siteConfig} from "../app";

export default function getEnrollmentTableFields() {
  let fields = [
    {
      key: 'actions',
      label: '',
      sortable: false
    },
    {
      key: 'insured_name',
      label: 'Insured',
      sortable: true
    },
    {
      key: 'employee_name',
      label: 'Employee',
      sortable: true
    },
    {
      key: 'product_name',
      label: 'Product',
      class: 'd-none d-sm-table-cell',
      sortable: true
    },
    {
      key: 'case_name',
      label: 'Case',
      class: 'd-none d-sm-table-cell',
      sortable: true
    },
    {
      key: 'case_division_name',
      label: 'Division',
      class: 'd-none d-sm-table-cell',
      sortable: true
    },
    {
      key: 'premium',
      label: 'Annual Premium',
      formatter: (value) => {
        if (parseFloat(value) === 0.0) {
          return '';
        }
        return formats.premium(value);
      },
      class: 'd-none d-lg-table-cell',
      sortable: true
    },
    {
      key: 'agent_name',
      label: 'Agent',
      class: 'd-none d-lg-table-cell',
      sortable: true
    },
    {
      key: 'time',
      label: 'Date',
      formatter: (value) => {
        return formats.utcToLocalDateTime(value);
      },
      sortable: true
    },
    {
      key: 'status',
      label: 'Status',
      class: 'd-none d-lg-table-cell',
      formatter: (value) => {
        return formats.enrollmentStatus(value);
      },
      sortable: true
    }
  ];

  console.debug('getEnrollmentTableFields', fields);
  console.debug('siteConfig', siteConfig);

  // Site-wide config changes to columns. See in backend tulsa.models.SiteConfigService.ClientSiteConfig - EF 2022-08-10
  if (!siteConfig.shouldShowEmployeeColumns) {
    fields = fields.filter((c) => c.key !== 'employee_name');
  }
  if (!siteConfig.shouldShowCaseColumns) {
    fields = fields.filter((c) => c.key !== 'case_name');
  }
  if (!siteConfig.ffEnableCaseDivisions) {
    fields = fields.filter((c) => c.key !== 'case_division_name');
  }

  console.debug('getEnrollmentTableFields after filtering', fields);

  return fields;
}
