<template>
  <b-form-group :invalid-feedback="invalidFeedback" :required="!optional" :state="validationState">
    <b-row>
      <b-col>
        <b-form-group label="Birth State" :invalid-feedback="invalidFeedback" :required="!optional" :state="validationState">
          <b-form-select v-model="selectedValue.state" :options="stateOptions"
                         :required="!optional"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Birth Country" v-show="showCountrySelector" :invalid-feedback="invalidFeedback" :required="!optional" :state="validationState">
          <b-form-select v-model="selectedValue.country" :options="countryOptions"
                         :required="!optional"></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form-group>
</template>

<script>
import {Applicant} from "./models";
import bus from "./messages";
import {baseCheckValidationState} from "./FormInput";

const UNITED_STATES = "United States";

export default {
  name: "BirthStateCountry",
  props: {
    name: {
      type: String,
      required: true
    },
    stateOptions: {
      type: Array,
      required: true
    },
    countryOptions: {
      type: Array,
      required: true
    },
    applicantContext: {
      type: Applicant,
      required: true
    },
    siteConfig: {
      type: Object,
      required: true
    },
    initial_value: {
      type: Object,
      required: false,
      default: {
        state: null,
        country: UNITED_STATES,
      }
    },
  },
  created() {
    if (this.initial_value) {

      if (this.initial_value.state) {
        this.selectedValue.state = this.initial_value.state;
      } else {
        this.selectedValue.state = this.stateOptions[0].value;
      }

      if (this.initial_value.country) {
        this.selectedValue.country = this.initial_value.country;
      } else {
        this.selectedValue.country = UNITED_STATES;
      }

    }
    bus.$on("validate", this.handleValidate);
  },
  destroyed() {
  },
  data() {
    return {
      OTHER_COUNTRY_STATE: "OTH",
      OTHER_COUNTRY_STATE_ALIAS: "OTHER",

      stateValidationState: null,
      stateInvalidFeedback: null,
      countryValidationState: null,
      countryInvalidFeedback: null,
      invalidFeedback: null,

      selectedValue: {
        state: null,
        country: UNITED_STATES,
      },
      disabled: false,
      optional: false,
      required: false,
    };
  },
  watch: {
    selectedValue: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.state !== this.OTHER_COUNTRY_STATE) {
          // if we picked a US state, we change the country to the United States automatically
          this.selectedValue.country = UNITED_STATES;
        }
        this.emitValue();
      }
    },
    stateSelectedValue() {
      return this.selectedValue.state;
    },
    countrySelectedValue() {
      return this.selectedValue.country;
    }
  },
  computed: {
    validationState(){
      if (!this.optional && this.validated) {
        if (this.selectedValue.state && this.selectedValue.country) {
          return true;
        }
      }
      return false;
    },
    formGroupLabel() {
      if (this.selectedValue.state !== this.OTHER_COUNTRY_STATE) {
        return "Birth State";
      } else {
        return "Birth State and Country";
      }
    },
    showCountrySelector() {
      return this.isOtherCountryStateSelected;
    },
    isOtherCountryStateSelected() {
      return (
        (this.selectedValue.state || '').toUpperCase() === this.OTHER_COUNTRY_STATE.toUpperCase() ||
        (this.selectedValue.state || '').toUpperCase() === this.OTHER_COUNTRY_STATE_ALIAS.toUpperCase()
      );
    },
  },
  methods: {
    emitValue() {
      this.$emit("value-change", {name: this.name, value: this.selectedValue});
    },
    handleValidate() {
      this.validated = true;
    },
  }

}
</script>

<style scoped>

</style>
