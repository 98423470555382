import registerAgent from './register-agent.html'
import Api from './api.js'

export default {
  template: registerAgent,
  data () {
    return {
      errors: [],
      firstNameValue: null,
      lastNameValue: null,
      emailValue: null,
      passwordValue: null,
      phoneNumber:null,
      codeValue: null,
      showThanks: false
    }
  },
  methods: {
    registerUser(e) {
      e.preventDefault();
      Api.registerUser({
        email: this.emailValue,
        password: this.passwordValue,
        first_name: this.firstNameValue,
        last_name: this.lastNameValue,
        phone_number: this.phoneNumber,
        code: this.codeValue
      })
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.errors = response.errors;
        } else {
          this.errors = [];
          this.firstNameValue = null;
          this.lastNameValue = null;
          this.emailValue = null;
          this.passwordValue = null;
          this.phoneNumber=null;
          this.codeValue = null;
          this.showThanks = true;
        }
      });
    }
  }
}