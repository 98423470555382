import bus from "../bus";
import settingsTemplate from "./agent_splits.html";
import Api from "../api";
import formats from "../models/formats";

let AgentSplits = {
  name: "AgentSplits",

  props: [
    "caseObject",
  ],
  template: settingsTemplate,
  data: function() {
    return {
      agent_splits_enabled: this.caseObject.agent_splits_enabled,
    }
  },
  created() {

  },
  methods: {
    updateCaseData() {
      bus.$emit('case-data-changed', {
        agent_splits_enabled: this.caseObject.agent_splits_enabled,// === "true",
      });
    }
  },
  watch: {

  },
};

export default AgentSplits;
