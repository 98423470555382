

class EnrollmentTerminology {
  format({isTitle, isPlural, isShort, isVerb, isPastTense}) {
    if (isVerb && !isPastTense) {
      if (isTitle) {
        return "Enroll";
      }
      return "enroll";
    } else if (isVerb && isPastTense) {
      if (isTitle) {
        return "Enrolled";
      }
      return "enrolled";
    }
    if (isShort) {
      return 'Enroll';
    }
    if (isTitle === true) {
      if (isPlural === true) {
        return 'Enrollments'
      } else {
        return 'Enrollment'
      }
    } else {
      if (isPlural === true) {
        return 'enrollments'
      } else {
        return 'enrollment'
      }
    }
  }
  getEnrollmentsPathName() {
    return 'enrollments';
  }
  getEnrollmentDetailsPathName() {
    return 'enrollment-details'
  }
  getWizardPathName() {
    return 'enroll'
  }
}


class ApplicationTerminology {
  format({isTitle, isPlural, isShort, isVerb, isPastTense}) {
    if (isVerb && !isPastTense) {
      if (isTitle) {
        return 'Apply';
      } else {
        return 'apply';
      }
    } else if (isVerb && isPastTense) {
      if (isTitle) {
        return 'Applied';
      } else {
        return 'applied';
      }
    }
    if (isShort) {
      return 'App';
    }

    if (isTitle === true) {
      if (isPlural === true) {
        return 'Applications'
      } else {
        return 'Application'
      }
    } else {
      if (isPlural === true) {
        return 'applications'
      } else {
        return 'application'
      }
    }
  }
  getEnrollmentsPathName() {
    return 'applications';
  }
  getEnrollmentDetailsPathName() {
    return 'application-details'
  }
  getWizardPathName() {
    return 'apply'
  }
}


class EmployeeTerminology {
  format({isTitle, isPlural}) {
    if (isTitle === true) {
      if (isPlural === true) {
        return 'Employees'
      } else {
        return 'Employee'
      }
    } else {
      if (isPlural === true) {
        return 'employees'
      } else {
        return 'employee'
      }
    }
  }
}


class ApplicantTerminology {
  format({isTitle, isPlural}) {
    if (isTitle === true) {
      if (isPlural === true) {
        return 'Applicants'
      } else {
        return 'Applicant'
      }
    } else {
      if (isPlural === true) {
        return 'applicants'
      } else {
        return 'applicant'
      }
    }
  }
}


class CaseTerminology {
  format({isTitle, isPlural}) {
    if (isTitle === true) {
      if (isPlural === true) {
        return 'Cases'
      } else {
        return 'Case'
      }
    } else {
      if (isPlural === true) {
        return 'cases'
      } else {
        return 'case'
      }
    }
  }
  formatOpenCase() {
    return 'Open a case'
  }
  getCasesPathName() {
    return 'cases'
  }
  getCaseDetailsPathName() {
    return 'case'
  }
}


class GroupTerminology {
  format({isTitle, isPlural}) {
    if (isTitle === true) {
      if (isPlural === true) {
        return 'Groups'
      } else {
        return 'Group'
      }
    } else {
      if (isPlural === true) {
        return 'groups'
      } else {
        return 'group'
      }
    }
  }
  formatOpenCase() {
    return 'Open a group'
  }
  getCasesPathName() {
    return 'groups'
  }
  getCaseDetailsPathName() {
    return 'group'
  }
}


class TerminologyInterface {
  constructor(terminologyEmployee, terminologyEnrollment, terminologyCase) {
    this.terminologyEmployee = terminologyEmployee;
    this.terminologyEnrollment = terminologyEnrollment;
    this.terminologyCase = terminologyCase;
  }
  formatEmployee(opts) {
    return this.terminologyEmployee.format(opts);
  }
  formatEnrollment(opts) {
    return this.terminologyEnrollment.format(opts);
  }
  formatEnrollmentStatus(status) {
    if (status === 'enrolled') {
      return this.formatEnrollment({isVerb: true, isPastTense: true, isTitle: true});
    }
    return status;
  }
  formatCase(opts) {
    return this.terminologyCase.format(opts);
  }
  formatOpenCase() {
    return this.terminologyCase.formatOpenCase();
  }

  getEnrollmentsPathName() {
    return this.terminologyEnrollment.getEnrollmentsPathName();
  }
  getEnrollmentDetailsPathName() {
    return this.terminologyEnrollment.getEnrollmentDetailsPathName();
  }
  getCasesPathName() {
    return this.terminologyCase.getCasesPathName();
  }
  getCaseDetailsPathName() {
    return this.terminologyCase.getCaseDetailsPathName();
  }
  getWizardPathName() {
    return this.terminologyEnrollment.getWizardPathName();
  }
}

/**
 * Main entrypoint for formatting tenant-specific terms.
 * Given a SiteConfig, return a TerminologyInterface object that
 * will format enrollments, employees, cases with the correct
 * terminology for the current site configuration / tenant.
 * @param siteConfig
 * @returns {TerminologyInterface}
 */
export function getTerminology(siteConfig) {
  let enrollmentTerminology;
  let employeeTerminology;
  let caseTerminology;

  if (siteConfig.terminologyEnrollment === 'enrollment') {
    enrollmentTerminology = new EnrollmentTerminology();
  } else {
    enrollmentTerminology = new ApplicationTerminology();
  }

  if (siteConfig.terminologyEmployee === 'employee') {
    employeeTerminology = new EmployeeTerminology();
  } else {
    employeeTerminology = new ApplicantTerminology();
  }

  if (siteConfig.terminologyCase === 'case') {
    caseTerminology = new CaseTerminology();
  } else {
    caseTerminology = new GroupTerminology();
  }

  return new TerminologyInterface(employeeTerminology, enrollmentTerminology, caseTerminology)
}

// Allows for vue template expressions like {{ "case" | terminology({isPlural: true})}}
export function getTerminologyVueFilter(terminology) {
  return (value, opts) => {
    if (value === 'enrollment' || value === 'enrollments') {
      return terminology.formatEnrollment(opts);
    } else if (value === 'employee' || value === 'employees') {
      return terminology.formatEmployee(opts);
    } else if (value === 'case' || value === 'cases') {
      return terminology.formatCase(opts)
    }
  }
}
