<template>
  <b-modal id="view-pdf-modal" title="App Form" size="xl" :busy="isLoading" scrollable centered ok-only
           :visible="visible"
           @hidden="$emit('hidden')"
  >
    <b-overlay :show="isLoading">
      <div class="text-right mb-4">
        <b-btn @click="downloadPDF(enrollmentId)" variant="outline-primary">
          <font-awesome-icon icon="download"></font-awesome-icon>
          Download
        </b-btn>
      </div>


      <b-card class="mb-4" no-body>
        <vue-pdf-embed ref="pdfComponent" :source="pdfSrc"
                       style="display: block; width: 100%;"
                       @rendered="handlePDFRendered"
                       @loading-failed="handlePDFFailed(error)"
                       @rendering-failed="handlePDFFailed(error)"
        ></vue-pdf-embed>
      </b-card>
    </b-overlay>
  </b-modal>
</template>

<script>
import api from "./api";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: "PdfModal",
  components: {
    VuePdfEmbed
  },
  props: {
    enrollmentId: {
      type: Number,
      required: false
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      pdfSrc: null,
      numPages: 0,
      isLoading: false,
    }
  },
  created() {
  },
  watch: {
    visible: function (val) {
      console.debug("visible changed to", val);
      if (!!val && !!this.enrollmentId) {
        console.debug("loading enrollment pdf; enrollmentId is", this.enrollmentId);
        this.loadEnrollmentPDF(this.enrollmentId);
      }
    }
  },
  methods: {

    loadEnrollmentPDF(enrollmentId) {
      this.isLoading = true;  // we won't turn this off until we get the PDF or catch an error

      this.pdfSrc = null;
      this.numPages = 0;

      const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;
      this.pdfSrc = `${API_BASE_PATH}/api/enrollments/${enrollmentId}/pdf`;


    },

    handlePDFRendered() {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfComponent.pageCount;
      console.debug("Updated page count to", this.pageCount);
    },

    handlePDFFailed() {
      this.isLoading = false;
      this.pageCount = 0;
      console.error("Failed to load PDF");
    },

    downloadPDF(enrollmentId) {
      this.isLoading = true;

      return api.getDownloadBlob(
        `/api/enrollments/${enrollmentId}/pdf`,
        null,
      )
        .then(async (response_blob) => {
          const url = window.URL.createObjectURL(response_blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `preview_enrollment_app_${enrollmentId}.pdf`;
          link.click();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  }
}
</script>

<style scoped>

</style>
