<template>
  <div>
    <div class="hide-selection form-group">
      <b-card-title>Voluntary Products Offered</b-card-title>
      <h6 v-if="products.length > 1">Reorder product presentation by dragging up or down</h6>
      <draggable v-model="products" :options="{handle:'.move'}">
        <div v-for="product in products"
             class="row px-3 mb-2">
          <div class="col-0 move">
            <i class="fa fa-bars"></i>
          </div>
          <div class="col">
            {{ product.text }}
          </div>

          <div class="col-0 pl-3 pointer">
            <font-awesome-icon class="grey-checkbox" icon="times"
                               @click="removeProduct(product.value)"></font-awesome-icon>
          </div>
        </div>
      </draggable>

      <label>Add Product:</label>
      <multiselect
        v-model="products"
        :options="availableProducts"
        label="text"
        track-by="value"
        :multiple="true"
        :hide-selected="true"
        :taggable="false"
        :close-on-select="true"
        :show-labels="false"
        placeholder="Search and select products">
      </multiselect>
    </div>


  </div>

</template>

<script>
import bus from "../bus";
import draggable from 'vuedraggable';

let ProductCaseSettings = {
  name: "ProductCaseSettings",
  components: {
    draggable,
  },
  props: [
    "caseObject",
    "selectedProducts",
    "availableProducts",
  ],
  data: function () {
    return {
      products: this.selectedProducts
    }
  },
  methods: {

    removeProduct(productValue) {
      this.products = this.products.filter(p => p.value !== productValue);
    },
  },
  watch: {
    products(val) {
      bus.$emit("case-products-changed", val);
    },
    // React to prop change
    selectedProducts(val) {
      this.products = val;
    }
  },
};

export default ProductCaseSettings;
</script>

<style scoped>

</style>
