<template>
  <b-overlay
    :show="showOverlay"
    no-fade
    opacity="0.9"
    variant="light">
    <b-card v-if="currentPaymentSchedule.payment_with_app" title="Make Payment With App">

      <div v-if="paymentWithAppMade" class="text-success">
        <font-awesome-icon icon="check"/>
        Payment Received.
      </div>
      <div v-else>
        <b-button @click="makePaymentWithApp"
                  :disabled="paymentInformation.paymentWithAppMade"
                  variant="success">
          Submit <span class="totalPremiumAmount">{{ formattedAmount }}</span> {{premiumMode.text}} premium now
        </b-button>
        <b-alert variant="danger" dismissible :show="paymentWithAppErrors.length > 0" class="mt-2 mb-2">
          <div>Errors while making payment:</div>
          <ul>
            <li v-for="error in paymentWithAppErrors">{{ error }}</li>
          </ul>
        </b-alert>

      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {Applicant, ApplicantList, Coverage, CoverageList, PremiumMode, ProductList} from "./models";
import bus from "../bus";
import Api from "../api";
import PremiumTotal from "./PremiumTotal";

export default {
  name: "PaymentWithAppCapture",
  components: {PremiumTotal},
  props: {
    applicants: {
      type: ApplicantList,
      required: true,
    },
    products: {
      type: ProductList,
      required: false,
    },
    selectedCoverages: {
      type: CoverageList,
      required: true
    },
    applicantContext: {
      type: Applicant,
      required: false
    },
    coverageContext: {
      type: Coverage,
      required: false
    },
    premiumMode: {
      type: PremiumMode,
      required: true
    },
    globalBeneficiaries: {
      type: Array,
      required: false
    },
    paymentInformation: {
      type: Object,
      required: false
    },
    // this is filled-in from the backend
    currentPaymentSchedule: {
      type: Object,
      required: false,
    },
    // passed from backend
    amount_to_charge: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      paymentWithAppMade: false,

      paymentWithAppErrors: [],

      showOverlay: false,
    }
  },
  computed: {
    formattedAmount() {
      return this.formattedTotalPremium = `$${this.amount_to_charge}`;
    },
  },
  methods: {
    emitValue() {
      // we only actually update one item, but have to send all of them
      let paymentInformation = {
        paymentWithAppMade: this.paymentWithAppMade, // this is our only locally-changing value

        paymentMethodId: this.currentPaymentSchedule.payment_method_token,
        paymentCustomerId: this.currentPaymentSchedule.customer_token,
        paymentScheduleType: this.currentPaymentSchedule.schedule_type,
        paymentScheduleDayOfMonth: this.currentPaymentSchedule.day_of_month,
        paymentScheduleDayOfWeek: this.currentPaymentSchedule.day_of_week,
        paymentScheduleWeekdayOccurrence: this.currentPaymentSchedule.weekday_occurrence,
        paymentWithApp: this.currentPaymentSchedule.payment_with_app,
      };

      // this should make the wizard's paymentInformation
      bus.$emit("payment-information-updated", paymentInformation);
    },
    makePaymentWithApp() {
      if (this.paymentWithAppMade === true) {
        return;  // can't pay if it was made. Using this to guard against race conditions
      }

      // enable the curtain to hide
      this.showOverlay = true;

      let paymentData = {
        customer_token: this.currentPaymentSchedule.customer_token,
        payment_method_token: this.currentPaymentSchedule.payment_method_token,
      };

      return Api.makePayment(paymentData)
        .then((response) => {
          this.paymentWithAppMade = !!response.payment_with_app_made;

          if (response && response.errors) {
            this.paymentWithAppErrors = [];
            for (let x = 0; x < response.errors.length; x++) {
              if (response.errors[x].message) {
                this.paymentWithAppErrors.push(`${response.errors[x].message}`)
              } else {
                this.paymentWithAppErrors.push(`${response.errors[x]}`);
              }
            }
          }

          // so the value can be updated on the event bus
          this.emitValue();
        })
        .catch((err) => {
          this.paymentWithAppErrors = [];
          for (const errKey in err) {
            this.paymentWithAppErrors.push(`${err[errKey]}`)
          }
        })
        .finally(() => {
          this.showOverlay = false;
        })
    }
  },
  created() {
    if (!this.currentPaymentSchedule) {
      this.paymentWithAppErrors = ['Error: component did not receive the expected payment schedule.'];
      return;
    }

    // on startup, set this local value
    this.paymentWithAppMade = !!this.currentPaymentSchedule.payment_with_app_made;

    // set our initial local values based on the backed-passed currentPaymentSchedule
    this.paymentCustomerId = this.currentPaymentSchedule.customer_token;
    this.paymentMethodId = this.currentPaymentSchedule.payment_method_token;

    // emit the payment information back to the event bus, so it gets the current paymentWithAppMade state
    this.emitValue();
  },
}
</script>

<style scoped>

</style>
