import Api from "../api";

export default class CaseValidation {
  constructor(caseId) {
    this.caseId = caseId;
  }

  async validateCase() {

    const caseValidResult = await Api.validateExistingCaseSettings(this.caseId);

    return (caseValidResult.errors.length === 0);
  }
}