<template>

  <div class="position-relative">
    <b-overlay
      :show="isAudioConferenceEnrollment && conferenceEnded && !canConcludeConference"
      no-fade
      opacity="0.9"
      variant="light">
      <template slot="overlay">
        <div class="text-center">
          <p>You or your caller unexpectedly disconnected. This {{ "enrollment" | terminology({}) }} session has
            been paused and can be re-initiated when ready to continue.</p>
          <b-button
            variant="primary"
            size="lg"
            @click="exitToEnrollmentDetails"
          >
            Ok
          </b-button>
        </div>
      </template>

<!--      <b-row>-->
<!--        <b-col>stepLinks.length: {{ this.stepLinks.length }}</b-col>-->
<!--        <b-col>currentStepIndex: {{ this.currentStepIndex }}</b-col>-->
<!--        <b-col>blockNavBeforeIdx: {{ this.blockNavBeforeIdx }}</b-col>-->
<!--        <b-col>forceHideCancelBtn: {{ this.forceHideCancelBtn }}</b-col>-->
<!--      </b-row>-->

      <div v-if="!isLoading">
        <h3 class="row">
          <b-col sm="7">
            <span v-show="siteConfig.terminologyCase === 'case'">{{
                caseName
              }} {{ "enrollment" | terminology({isTitle: true}) }}</span>
          </b-col>
          <b-col sm="5" class="text-sm-right">
            <premium-total
              v-if="shouldShowPremiumTotal"
              :applicants="applicants"
              :selectedCoverages="selectedCoverages"
              :products="products"
              :premiumMode="premiumMode"
            ></premium-total>
          </b-col>
        </h3>

        <b-card bg-variant="light">
          <div slot="header">
            <ul class="nav nav-pills nav-fill">
              <li class="nav-item" v-for="stepLink in stepLinks">
                <a class="nav-link" href="#"
                   :class="{ active: isCurrentLink(stepLink), disabled: (isFutureLink(stepLink) || restrictNavForPaymentWithAppSubmit || isBlockedLink(stepLink)) }"
                   @click="clickStepLink(stepLink)">
                  {{ stepLink.text }}
                </a>
              </li>
            </ul>
          </div>
          <p class="card-text" v-if="currentStep !== null">
            <b-form :validated="isValidating" ref="form" :novalidate="true">
              <transition name="fade" mode="out-in">
                <keep-alive>
                  <generic-step :stepTitle="currentStep.title"
                                :breadcrumb="currentStep.breadcrumb"
                                :fields="currentStep.fields"
                                :applicants="applicants"
                                :selectedCoverages="selectedCoverages"
                                :stepValidationError="stepValidationError"
                                :products="products"
                                :applicantContext="currentApplicantContext"
                                :coverageContext="currentCoverageContext"
                                :premiumMode="premiumMode"
                                :globalBeneficiaries="globalBeneficiaries"
                                :paymentInformation="paymentInformation"
                                :siteConfig="siteConfig"
                                :rates="rates"
                                @enrollment-data-changed="handleEnrollmentDataChanged"
                                @applicant-data-changed="handleApplicantDataChanged"
                                @coverage-data-changed="handleCoverageDataChanged"

                  ></generic-step>
                </keep-alive>
              </transition>
            </b-form>
          </p>
          <div slot="footer">

            <b-button v-if="shouldShowDeclineBtn" :disabled="restrictNavForPaymentWithAppSubmit" size="sm"
                      class="mt-2 align-middle"
                      @click="declineEnrollment">Decline
            </b-button>
            <b-button v-if="shouldShowCancelBtn" :disabled="restrictNavForPaymentWithAppSubmit" size="sm"
                      class="mt-2 align-middle"
                      @click="cancelEnrollment">Cancel
            </b-button>
            <b-button v-if="shouldShowPauseBtn" size="sm" class="mt-2 ml-3 align-middle" variant="warning"
                      @click="pauseEnrollment">
              <font-awesome-icon icon="pause-circle"></font-awesome-icon>
              Pause
            </b-button>

            <div class="float-right">
              <b-spinner v-show="isNavigating" variant="secondary" small class="align-middle mr-3"></b-spinner>

              <template v-if="isAudioConferenceEnrollment">
                <b-btn v-if="isConferenceEnded" variant="outline-secondary" class="mx-4" disabled size="sm">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                  <span>Conference Ended.</span>
                </b-btn>
                <b-btn v-if="isConferenceStarting" variant="outline-warning" class="mx-4" disabled size="sm">
                  <font-awesome-icon icon="phone"></font-awesome-icon>
                  <span v-text="conferenceStatusLabel"></span>
                </b-btn>
                <b-dropdown v-if="isConferenceInProgress" class="mx-4" size="sm"
                            :disabled="isNavigating || isUpdatingConference"
                            :variant="`outline-${conferenceStatusVariant}`"
                            v-b-popover.hover.top="`${conferenceStatusLabel}`">

                  <template slot="button-content">
                    <font-awesome-icon icon="phone"></font-awesome-icon>
                    <b-spinner type="grow" small :variant="conferenceStatusVariant"
                               :label="conferenceStatus"></b-spinner>
                    <span v-if="isUpdatingConference">Updating Conference</span>
                    <span v-else v-text="conferenceStatusLabel"></span>
                  </template>

                  <b-dropdown-group>
                    <b-dropdown-header>Play on current Audio {{
                        "enrollment" | terminology({isTitle: true})
                      }}:
                    </b-dropdown-header>
                    <b-dropdown-item-button v-for="(scriptLabel, scriptId) in autoReadScripts"
                                            @click="sayScriptOnConference(scriptId)" :key="scriptId">
                      <font-awesome-icon icon="play-circle"></font-awesome-icon>
                      {{ scriptLabel }}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="sayScriptOnConference('__cancel__')" key="__cancel__">
                      <font-awesome-icon icon="times"></font-awesome-icon>
                      Cancel Reading
                    </b-dropdown-item-button>
                  </b-dropdown-group>
                </b-dropdown>
              </template>

              <!-- PREV button -->
              <b-button size="sm" variant="secondary" class="align-middle mr-3"
                        v-show="hasPreviousStep" @click="goToPreviousStep" :disabled="stepNavigationDisabled">
                <font-awesome-icon icon="chevron-left"></font-awesome-icon>
                Previous
              </b-button>

              <!-- NEXT button -->
              <b-button size="lg" :variant="nextButtonVariant" class=" align-middle"
                        v-show="hasNextStep" @click="handleNextButton" :disabled="nextStepDisabled"
                        v-text="nextButtonText">
                <font-awesome-icon icon="chevron-right"></font-awesome-icon>
              </b-button>

              <!-- SUBMIT button -->
              <b-button size="lg" variant="success" class=" align-middle"
                        :disabled="isSubmissionModalShowing || (!canSubmit && !forceAllowSubmit)"
                        v-show="isLastStep" @click="validateAndSubmitApplication">{{ submitButtonText }}
              </b-button>

              <!-- DECLINE button -->
              <b-button size="lg" variant="danger" class=" align-middle"
                        v-show="isStopStep" @click="submitApplication">Decline all coverage
                <font-awesome-icon icon="times-circle"></font-awesome-icon>
              </b-button>


            </div>

          </div>
        </b-card>


        <!-- a bootstrapVue modal that shows a spinner while the underwriting kickoff is in progress,
         and an error message if the kickoff fails -->
        <b-modal
          no-close-on-backdrop
          no-close-on-esc
          v-if="isUnderwritingKickoffStep || isUnderwritingDecisionStep"
          id="underwriting-kickoff-modal"
          ref="underwritingKickoffModal"
          v-model="isUnderwritingModalShowing"
          :title="'Underwriting Case Information'"
          size="lg"
          @hide="isUnderwritingModalShowing = false"
        >
          <div>
            <b-row v-if="failedKickoffUnderwritingDecisions || kickoffFailedEntirely">
              <b-col>
                <div v-if="kickoffFailedEntirely">
                  <p>The underwriting system is currently unreachable to initiate your application(s); this session will be paused.</p>
                </div>
                <b-list-group>
                  <b-list-group-item v-for="decision in failedKickoffUnderwritingDecisions" :key="decision.id">
                    <h5>{{decision.enrollment_party.applicant.first_name}} {{decision.enrollment_party.applicant.last_name}}</h5>
                    <div v-if="decision.reason_failed === REASON_FAILED_NO_RESPONSE">
                      <p>The underwriting system is currently unreachable to initiate your application; we recommend you pause this application and resume at a later time.</p>
                    </div>
                    <div v-else-if="decision.reason_failed === REASON_FAILED_BAD_RESPONSE">
                      <p>An error has occurred with the underwriting system in initiating your application. Please see your home-office representative for more information. This application may be paused to review/resume at a later time.</p>
                    </div>
                    <div v-else>
                      <p>An unknown error has occurred with the underwriting system in initiating your application. Please see your home-office representative for more information. This application may be paused to review/resume at a later time.</p>
                    </div>
                    <p><strong>This {{ 'enrollment' | terminology({isTitle: true}) }} will be paused when you click the button below.</strong> Please try this {{ 'enrollment' | terminology({isTitle: true}) }} again later.</p>
                  </b-list-group-item>
                </b-list-group>

                <b-alert class="mt-4" variant="danger" v-show="underwritingKickoffGeneralErrorText" v-text="underwritingKickoffGeneralErrorText"></b-alert>
              </b-col>
            </b-row>


            <b-row v-if="failedDecisionUnderwritingDecisions">
              <b-col>
                <b-list-group>
                  <b-list-group-item v-for="decision in failedDecisionUnderwritingDecisions" :key="decision.id">
                    <h5>{{decision.enrollment_party.applicant.last_name}} {{decision.enrollment_party.applicant.first_name}}</h5>
                    <div v-if="decision.reason_failed === REASON_FAILED_NO_RESPONSE">
                      <p>The underwriting system is currently unreachable to retrieve your underwriting results; we recommend you pause this application and resume at a later time.</p>
                    </div>
                    <div v-else-if="decision.reason_failed === REASON_FAILED_BAD_RESPONSE">
                      <p>An error has occurred with the underwriting system in retrieving your underwriting results. Please see your home-office representative for more information. This application may be paused to review/resume at a later time.</p>
                    </div>
                    <div v-else>
                      <p>An unexpected error has occurred with the underwriting system in retrieving your underwriting results. Please see your home-office representative for more information. This application may be paused to review/resume at a later time.</p>
                    </div>
                    <p><strong>This {{ 'enrollment' | terminology({isTitle: true}) }} will be paused when you click the button below.</strong> Please try this {{ 'enrollment' | terminology({isTitle: true}) }} again later.</p>
                  </b-list-group-item>
                </b-list-group>

                <b-alert class="mt-4" variant="danger" v-show="underwritingKickoffGeneralErrorText" v-text="underwritingKickoffGeneralErrorText"></b-alert>
              </b-col>

            </b-row>
          </div>

          <div slot="modal-footer" class="align-self-end">
            <b-button variant="warning" @click="handleUnderwritingFailedPauseButton" :disabled="isLoading">
              <font-awesome-icon icon="pause-circle"></font-awesome-icon>
              Pause these {{ 'enrollments' | terminology({isTitle: true, isPlural: true}) }}
              <span v-if="!isAllUnderwritingKickoffsFailed && !isAllUnderwritingKickoffsFailed">and go to the next step</span>

            </b-button>
          </div>
        </b-modal>

        <b-modal v-model="isSubmissionModalShowing"
                 :title="'Submitting ' + terminology.formatEnrollment({isTitle: true})"
                 no-close-on-esc no-close-on-backdrop hide-header-close hide-footer>
          <div v-show="submissionProblem === ''">
            <b-progress :value="100" variant="success" :striped="true" class="mb-2"></b-progress>
            <b-row>
              <b-col>Submitting {{ 'enrollment' | terminology({isTitle: true}) }}, please wait...</b-col>
            </b-row>
          </div>
          <b-row>
            <b-col class="text-error">
              {{ submissionProblem }}
            </b-col>
          </b-row>
        </b-modal>

        <b-modal v-model="isCancelModalShowing"
                 :title="'Cancel' + terminology.formatEnrollment({isTitle: true}) + ' Session'"
                 size="lg"
                 @hide="handleCancelModalDismissed"
        >
          <div>
            <b-row>
              <b-col>Are you sure you want to cancel this {{ 'enrollment' | terminology({}) }}? All data will be
                discarded.
              </b-col>
            </b-row>
          </div>
          <div slot="modal-footer" class="align-self-end">
            <b-row>
              <b-btn
                @click="handleCancelModalDismissed('hide')"
                size="md"
                class="col-0 mr-3"
                variant="outline-secondary">
                <font-awesome-icon icon="times"></font-awesome-icon>
                Cancel
              </b-btn>
              <b-btn
                @click="handleEnrollmentPaused"
                size="md"
                v-if="shouldShowPauseBtn"
                class="col-0 mr-3"
                variant="warning">
                <font-awesome-icon icon="pause-circle"></font-awesome-icon>
                Save and Resume later
              </b-btn>
              <b-btn
                @click="handleEnrollmentCanceled"
                size="md"
                class="col-0 mr-3"
                variant="danger"
              >
                <font-awesome-icon icon="trash"></font-awesome-icon>
                Discard data and Exit
              </b-btn>

            </b-row>
          </div>
        </b-modal>

        <b-modal v-model="isDeclineModalShowing"
                 :title="'Decline '+ terminology.formatEnrollment({isTitle: true, isPlural: true})"
                 cancel-title="Yes, Decline"
                 cancel-variant="danger"
                 ok-title="No, Go Back"
                 ok-variant="primary"
                 @cancel="handleEnrollmentDeclined">
          <div>
            <b-row>
              <b-col>You are about to cancel this application and waive coverage of the presented benefits. Please be
                aware that once declined you will need to contact your benefit representative to re-initiate any new
                enrollment.
              </b-col>
            </b-row>
          </div>
        </b-modal>

        <b-modal v-model="isPauseModalShowing"
                 :title="'Pause '+ terminology.formatEnrollment({isTitle: true})"
                 ok-title="Yes, Pause"
                 ok-variant="warning"
                 cancel-title="No, Go Back"
                 cancel-variant="default"
                 @ok="handleEnrollmentPaused">
          <div>
            <b-row>
              <b-col>You are about to pause this {{ 'enrollment' | terminology({}) }}.</b-col>
            </b-row>
          </div>
        </b-modal>


      </div>
      <loading-spinner v-else size="large"></loading-spinner>

    </b-overlay>
  </div>

</template>

<script>

import GenericStep from "./StepComponent"

import {
  Applicant,
  ApplicantList,
  ApplicantTypeChild,
  ApplicantTypeEmployee,
  ApplicantTypeSpouse,
  CoverageList,
  getPremiumModeByType,
  Product,
  ProductList
} from "./models";
import {siteConfig, terminology} from "../app";
import {debounce} from '../utils'

import Api from '../api';

import bus from './messages'
import moment from "../vendor/moment-timezone-with-data-1970-2030.js";
import PremiumTotal from "./PremiumTotal";
import EnrollmentConferenceSessions from "../models/enrollment-conference-sessions";

// Since this component is often used recursively, it is easier to register it globally to avoid
//  recursive imports in JS.
import EnrollmentFieldSet from "./EnrollmentFieldSet";
import Vue from "vue";
import WizardStepData from "./WizardStepData.js";

Vue.component("enrollment-field-set", EnrollmentFieldSet);

// Load and bootstrap PDFjs library
//  Using legacy (ES5) build to support older browsers (IE11)
const pdfjs = require("../../node_modules/pdfjs-dist/legacy/build/pdf.js");
const PdfjsWorker = require("worker-loader?esModule=false&filename=[name].js!../../node_modules/pdfjs-dist/legacy/build/pdf.worker.js");
if (typeof window !== "undefined" && "Worker" in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker();
}


const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;

const REASON_FAILED_NO_RESPONSE = 'no_response';
const REASON_FAILED_BAD_RESPONSE = 'bad_response';

const NTO_COVERAGE_VALUE = -999;


let wizard = {
  name: "WizardComponent",
  components: {
    'generic-step': GenericStep,
    'premium-total': PremiumTotal,
  },
  data: function () {
    let employee = new Applicant({type: ApplicantTypeEmployee});

    return {
      isLoading: true,
      initialData: {},
      premiumMode: null,

      isSelfEnroll: false,
      isRemoteSignature: false,
      isAgentEnrollRemoteSignature: false,
      isCallCenterReview: false,

      isValidating: false,
      isNavigating: false,
      isUpdatingConference: false,
      caseName: null,
      applicants: new ApplicantList([employee]),

      steps: [],
      products: new ProductList(),
      currentStepIndex: null,
      currentApplicantContext: null,
      currentCoverageContext: null,
      empSelectedRemoteSignature: null,

      isUnderwritingModalShowing: false,
      isSubmissionModalShowing: false,
      submissionProblem: "",
      isCancelModalShowing: false,
      isPauseModalShowing: false,
      isDeclineModalShowing: false,
      submitButtonText: null,

      rates: null,
      selectedCoverages: new CoverageList(),

      stepValidationError: null,

      globalBeneficiaries: [],

      terminology: terminology,
      siteConfig: siteConfig,

      // @TODO mark this false when cancelling a conference
      isAudioConferenceEnrollment: false,
      conferenceStatus: null,
      conferenceStatusReason: null,
      conferenceEnded: null,

      conferenceStatusMap: EnrollmentConferenceSessions.conferenceStatusMap,

      isConferenceCancelling: false,
      isConferenceCancelled: false,

      _confStatusSubTimeout: null,

      autoReadScripts: [],

      paymentInformation: {
        paymentMethodId: null,
        paymentCustomerId: null,
        paymentScheduleType: 'monthly-day-of-month', // or 'monthly-day-of-week'
        paymentScheduleDayOfMonth: 1, // should be 1-28
        paymentScheduleDayOfWeek: 1, // 0-6
        paymentScheduleWeekdayOccurrence: 1, // 1-4
        paymentWithApp: false,
        paymentWithAppMade: false,
      },

      isUnderwritingSubmitted: false,
      isUnderwritingReceived: false,
      REASON_FAILED_NO_RESPONSE: REASON_FAILED_NO_RESPONSE,
      REASON_FAILED_BAD_RESPONSE: REASON_FAILED_BAD_RESPONSE,
      requestedUnderwritingDecisions: [],
      allUnderwritingDecisions: [],
      kickoffFailedEntirely: false,
      failedKickoffUnderwritingDecisions: [],
      failedDecisionUnderwritingDecisions: [],
      underwritingKickoffGeneralErrorText: null,

      forceStepNavigationDisabled: false,
      forceNextStepDisabled: false,
      forceAllowSubmit: false,
      forceHideCancelBtn: false,
      blockNavBeforeIdx: 0,
      forcePauseOnNextStep: false,
      rates: null
    }
  },
  computed: {

    canSubmit() {
      if (this.selectedCoverages && this.paymentInformation.paymentWithApp && !this.paymentInformation.paymentWithAppMade) {
        return false;
      }
      return true;
    },

    restrictNavForPaymentWithAppSubmit() {
      if (this.paymentInformation.paymentWithApp && this.paymentInformation.paymentWithAppMade && this.isLastStep) {
        return true;
      }
      return false;
    },

    stepNavigationDisabled() {
      if (!!this.forceStepNavigationDisabled) {
        return true;
      }

      if (this.restrictNavForPaymentWithAppSubmit) {
        return true;
      }

      return !!this.isNavigating;
    },

    nextStepDisabled() {

      if (!!this.stepNavigationDisabled) {
        return true;
      }

      if (!!this.forceNextStepDisabled) {
        return true;
      }

      return !!this.isNavigating;
    },
    stepLinks() {
      // group steps by step.breadcrumb
      let groupedSteps = {};
      this.steps.forEach((step, idx) => {
        if (step.breadcrumb) {
          groupedSteps[step.breadcrumb] = {
            text: step.breadcrumb,
            _origIdx: idx
          };
          // console.debug("addded step to groupedSteps:", step.breadcrumb, groupedSteps[step.breadcrumb]);
        }
      });

      return Object.values(groupedSteps);
    },

    hasPreviousStep() {
      if (this.blockNavBeforeIdx) {
        return this.currentStepIndex > this.blockNavBeforeIdx;
      }
      return this.currentStepIndex > 0;
    },
    isStopStep() {
      // guard against case where this is used before steps are loaded -- EF 2023-01-18
      if (this.currentStepIndex === null || this.currentStepIndex === undefined) {
        return false;
      }
      if (this.currentStepIndex < 0 || this.currentStepIndex >= this.steps.length) {
        return false;
      }

      return this.steps[this.currentStepIndex].is_terminal_step;
    },
    isUnderwritingKickoffStep() {
      // guard against case where this is used before steps are loaded -- EF 2023-01-18
      if (this.currentStepIndex === null || this.currentStepIndex === undefined) {
        return false;
      }
      return this.steps[this.currentStepIndex].kickoff_underwriting_if_valid;
    },
    isUnderwritingDecisionStep() {
      // guard against case where this is used before steps are loaded -- EF 2023-01-18
      if (this.currentStepIndex === null || this.currentStepIndex === undefined) {
        return false;
      }
      return this.steps[this.currentStepIndex].is_underwriting_decision_step;
    },
    hasNextStep() {
      return this.currentStepIndex < this.steps.length - 1 && !this.isStopStep;
    },
    isLastStep() {
      return this.hasNextStep === false && !this.isStopStep;
    },
    currentStep() {
      if (this.currentStepIndex === null) {
        return null;
      } else if (this.currentStepIndex >= this.steps.length) {
        return null;
      }

      return this.steps[this.currentStepIndex];
    },

    firstTerminalStepIndex() {
      return this.steps.findIndex(step => step.is_terminal_step);
    },

    // submitButtonText() {
    //   if (this.isRemoteSignature) {
    //     return "Sign Application";
    //   } else if (this.isAgentEnrollRemoteSignature) {
    //     return "Finish and Send for Signature";
    //   } else if (this.isCallCenterReview) {
    //     return "Finish and Close"
    //   }
    //   return "Finish and Submit Application";
    // },
    shouldShowPremiumTotal() {
      return !this.isRemoteSignature && !this.isCallCenterReview
    },
    shouldShowDeclineBtn() {
      return this.isRemoteSignature;
    },
    shouldShowCancelBtn() {
      return !this.isRemoteSignature && !this.isCallCenterReview && !this.forceHideCancelBtn;
    },
    shouldShowPauseBtn() {
      return !this.isRemoteSignature && !this.isCallCenterReview && !this.isSelfEnroll && !this.forcePauseOnNextStep;
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    isConferenceReadyToStart() {
      return EnrollmentConferenceSessions.helpers.isConferenceReadyToStart(this.conferenceStatus);
    },
    isConferenceStarting() {
      return EnrollmentConferenceSessions.helpers.isConferenceStarting(this.conferenceStatus);
    },
    isConferenceInitializing() {
      return EnrollmentConferenceSessions.helpers.isConferenceInitializing(this.conferenceStatus);
    },
    isConferenceInProgress() {
      return EnrollmentConferenceSessions.helpers.isConferenceInProgress(this.conferenceStatus);
    },
    isConferenceEnded() {
      return EnrollmentConferenceSessions.helpers.isConferenceEnded(this.conferenceStatus);
    },
    isConferenceEndedUnexpectedly() {
      return EnrollmentConferenceSessions.helpers.isConferenceEndedUnexpectedly(this.conferenceStatus);
    },
    canConcludeConference() {
      // @TODO is this when we can conclude?
      return this.isLastStep;
    },
    conferenceStatusLabel() {
      return EnrollmentConferenceSessions.helpers.label(this.conferenceStatus);
    },
    conferenceStatusVariant() {
      return EnrollmentConferenceSessions.helpers.variant(this.conferenceStatus);
    },

    isAllUnderwritingKickoffsSucceeded() {
      return this.failedKickoffUnderwritingDecisions.length === 0 && this.kickoffFailedEntirely === false;
    },
    isAllUnderwritingKickoffsFailed() {
      return this.failedKickoffUnderwritingDecisions && this.failedKickoffUnderwritingDecisions.length > 0 && this.failedKickoffUnderwritingDecisions.length === this.allUnderwritingDecisions.length;
    },
    isAllUnderwritingDecisionsSucceeded() {
      return this.failedDecisionUnderwritingDecisions.length === 0;
    },
    isAllUnderwritingDecisionsFailed() {
      return this.failedDecisionUnderwritingDecisions && this.failedDecisionUnderwritingDecisions.length > 0 && this.failedDecisionUnderwritingDecisions.length === this.allUnderwritingDecisions.length;
    },
    nextButtonVariant() {
      if (this.forcePauseOnNextStep) {
        return "warning";
      }
      return "primary";
    },
    nextButtonText() {
      if (this.forcePauseOnNextStep) {
        return "Pause";
      }
      return "Next";
    },
  },
  created() {
    // Hide nav
    bus.$emit("hide-navigation");

    Api.searchAutoReadScripts()
      .then((r) => {
        if (r && !r.errors) {
          this.autoReadScripts = r;
        }
      });

    this.retrieveInitialSessionData();

    // Listen to events from sub-components.
    bus.$on("review-documents", this.previewEnrollment);
    bus.$on("send-call-center-review-email", this.sendCallCenterReviewEmail);
    bus.$on("submit-application", this.submitApplication);

    bus.$on("applicant-data-changed", this.handleApplicantDataChanged);
    bus.$on("coverage-data-changed", this.handleCoverageDataChanged);
    bus.$on("enrollment-data-changed", this.handleEnrollmentDataChanged);

    bus.$on("update-premium", this.handleRatesShouldChange);
    bus.$on('search-rates', this.displayRates);
    bus.$on("update-coverage", this.updateCoverageList);
    bus.$on("reload-current-step", this.reloadCurrentStep);

    bus.$on("add-child", this.handleAddChild);
    bus.$on("remove-child", this.handleRemoveChild);

    bus.$on("beneficiary-added", (beneficiary) => {
      // search for a matching beneficiary on the globalBeneficiaries list and update it if found
      // otherwise, add it to the list

      if (beneficiary.name === "" || beneficiary.relationship === "") {
        console.debug("Beneficiary name or relationship is empty, not adding to global list.", beneficiary);
        return;
      }

      let existingIndex = this.globalBeneficiaries.map(b => b.name).indexOf(beneficiary.name);
      if (existingIndex >= 0) {
        this.globalBeneficiaries.splice(existingIndex, 1, beneficiary);
        console.debug(`Updated existing beneficiary at index ${existingIndex}`, beneficiary);
      } else {
        this.globalBeneficiaries.push(beneficiary);
        console.debug(`Added new beneficiary`, beneficiary);
      }
    });

    // events from the Enrollment Session Conference
    bus.$on("conference-status-updated", (data) => {
      console.debug("Received conference-status-updated data:", data);

      this.conferenceStatus = data['status'];
      this.conferenceStatusReason = data['status_reason'];
      this.conferenceEnded = data['ended'];
    });

    //
    bus.$on("payment-information-updated", (data) => {
      console.debug("Received payment-information-updated data:", data);
      this.paymentInformation.paymentMethodId = data.paymentMethodId;
      this.paymentInformation.paymentCustomerId = data.paymentCustomerId;
      this.paymentInformation.paymentScheduleType = data.paymentScheduleType;
      this.paymentInformation.paymentScheduleDayOfMonth = data.paymentScheduleDayOfMonth;
      this.paymentInformation.paymentScheduleDayOfWeek = data.paymentScheduleDayOfWeek;
      this.paymentInformation.paymentScheduleWeekdayOccurrence = data.paymentScheduleWeekdayOccurrence;
      this.paymentInformation.paymentWithApp = data.paymentWithApp;
      this.paymentInformation.paymentWithAppMade = data.paymentWithAppMade;
    });

    bus.$on("update-step-navigation-disabled", (disabled) => {
      console.debug("Received update-step-navigation-disabled:", disabled);
      this.forceStepNavigationDisabled = !!disabled;
    });

    bus.$on("update-force-next-step-disabled", (disabled) => {
      console.debug("update-force-next-step-disabled:", disabled);
      this.forceNextStepDisabled = !!disabled;
    });

    bus.$on("update-force-pause-on-next-step", (enabled) => {
      console.debug("Received update-force-pause-on-next-step:", enabled);
      this.forcePauseOnNextStep = !!enabled;
    });

    bus.$on("play-audio-announcement", (params) => {
      console.debug("received play-audio-announcement:", params);
      if (params && params.scriptId) {
        this.sayScriptOnConference(params.scriptId);
      }
    })

    bus.$on('kickoff-underwriting-success', (response_data) => {
      console.debug('Event Bus: kickoff-underwriting-success:', response_data);
      this.isUnderwritingModalShowing = false;
      this.isUnderwritingSubmitted = true;
    });

    bus.$on('kickoff-underwriting-error', (error) => {
      console.debug('Event Bus: kickoff-underwriting-error:', error);
      if (error.allFailed) {
        this.pauseEnrollment();
      } else {
        if (error.allUnderwritingDecisions) {
          for (let i = 0; i < error.failedUnderwritingDecisions.length; i++) {
            let thisApplicant = this.applicants.getApplicantById(error.allUnderwritingDecisions[i].applicant_id);
            if (thisApplicant) {
              console.error(`Underwriting decision for ${thisApplicant.firstName} ${thisApplicant.lastName} is ${error.allUnderwritingDecisions[i]}`);
            }
          }
        }
        console.log(`this.failedDecisionUnderwritingDecisions:`, this.failedDecisionUnderwritingDecisions);
      }
    });

    bus.$on('close-underwriting-kickoff-modal', (modalRef) => {
      console.debug('Event Bus: close-underwriting-kickoff-modal');
      this.isUnderwritingModalShowing = false;
      modalRef.hide();
    });

    bus.$on('wizard-set-step', (args) => {
      let stepIndex = args.stepIndex;
      let step = this.steps[stepIndex];
      let steps = this.steps;
      console.debug('Event Bus: wizard-set-step: ', {stepIndex, step, steps});

      // stuff to run when the step is set:
      this.initializeUnderwritingDecisions();

      // after render, set the step
      this.$nextTick(() => {
        this.updateUIForUnderwritingState();
      });
    });``

    bus.$on('requesting-underwriting-decision', (args) => {
      console.debug('Event Bus: requesting-underwriting-decision: args', args);

      if (!args.productId || !args.partyId) {
        console.error('Event Bus: requesting-underwriting-decision: missing productId or partyId');
        // @TODO maybe mark as failed?
        return;
      }

      // if it exists (check product and party), ignore it
      let existingIndex = this.requestedUnderwritingDecisions.map(d => {
        return { productId: d.productId, partyId: d.partyId }
      })
        .indexOf({ productId: args.productId, partyId: args.partyId });

      if (existingIndex >= 0) {
        return;
      }

      this.requestedUnderwritingDecisions.push({
        productId: args.productId,
        partyId: args.partyId
      });
    });

    bus.$on('got-underwriting-decision', (enrollmentUnderwritingDecision) => {
      console.debug('Event Bus: got-underwriting-decision:', enrollmentUnderwritingDecision);

      // if it exists, update it
      let existingIndex = this.allUnderwritingDecisions.map(d => d.id).indexOf(enrollmentUnderwritingDecision.id);
      if (existingIndex >= 0) {
        this.allUnderwritingDecisions.splice(existingIndex, 1, enrollmentUnderwritingDecision);
      } else {
        this.allUnderwritingDecisions.push(enrollmentUnderwritingDecision);
      }

      if (enrollmentUnderwritingDecision.status === 'failed') {
        let existingFailedIndex = this.failedDecisionUnderwritingDecisions.map(d => d.id).indexOf(enrollmentUnderwritingDecision.id);
        if (existingFailedIndex >= 0) {
          this.failedDecisionUnderwritingDecisions.splice(existingFailedIndex, 1, enrollmentUnderwritingDecision);
        } else {
          this.failedDecisionUnderwritingDecisions.push(enrollmentUnderwritingDecision);
        }
      } else {
        // remove it from failed decisions!
        let existingFailedIndex = this.failedDecisionUnderwritingDecisions.map(d => d.id).indexOf(enrollmentUnderwritingDecision.id);
        if (existingFailedIndex >= 0) {
          this.failedDecisionUnderwritingDecisions.splice(existingFailedIndex, 1);
        }
      }
    });
  },
  methods: {
    retrieveInitialSessionData() {
      // Load initial data
      return fetch(API_BASE_PATH + "/api/wizard/session", {method: 'GET', credentials: "include"})
        .then(response => response.json())
        .then(async json => {
          this.initialData = json;

          if (this.initialData.enrollment_session_conference_name) {
            // this will trigger the watch on isAudioConferenceEnrollment
            this.isAudioConferenceEnrollment = true;
          }

          if (this.initialData.case === undefined) {
            // Not a valid session, return to enrollments page
            this.redirectToEnrollmentCompletePage();
          }
          if (this.initialData.case.premium_mode === undefined) {
            this.premiumMode = getPremiumModeByType("monthly");
          } else {
            this.premiumMode = getPremiumModeByType(this.initialData.case.premium_mode);
          }

          this.empSelectedRemoteSignature = !!this.initialData.case.force_agent_enroll_remote_sig;

          // check if self-enroll and if remote-sig
          if (this.initialData.isSelfEnroll === true) {
            this.isSelfEnroll = true
          }
          if (this.initialData.isRemoteSignature === true) {
            this.isRemoteSignature = true
          }
          if (this.initialData.isCallCenterReview === true) {
            this.isCallCenterReview = true
          }
          if (this.initialData.isAgentEnrollRemoteSignature === true) {
            this.isAgentEnrollRemoteSignature = true
          }

          this.products = new ProductList(this.initialData['products'].map((p) => new Product(p)));

          // Load initial data from session
          let emp = this.applicants.getFirstApplicantByType(ApplicantTypeEmployee);

          // If resuming, first load in resumed data
          if (this.initialData.resumed_enrollment_data) {
            emp.mergeData(this.initialData.resumed_enrollment_data.data.applicants[0]);
          }

          // Load in any updated census data
          // TODO: do not merge in every key here, just the keys that pertain to employee.
          emp.mergeData(this.initialData);

          // Handle city and state separately due to naming conflict with enrollment city state.
          if (this.initialData.applicant_city) {
            emp.mergeData({city: this.initialData.applicant_city});
          }
          if (this.initialData.applicant_state) {
            emp.mergeData({state: this.initialData.applicant_state});
          }

          // If resuming, load in applicants first from saved enrollment data.
          if (this.initialData.resumed_enrollment_data) {
            let addedByType = new Map();
            this.initialData.resumed_enrollment_data.data.applicants.forEach((applicantData) => {
              if (applicantData.type === ApplicantTypeSpouse || applicantData.type === ApplicantTypeChild) {
                let applicant = new Applicant({
                  id: applicantData.id,
                  type: applicantData.type,
                });
                applicant.mergeData(applicantData);
                this.applicants.addApplicant(applicant);
              }
            });

            // Load in coverage data from saved enrollment.
            if (this.initialData.resumed_enrollment_data.data.selectedCoverages) {
              this.initialData.resumed_enrollment_data.data.selectedCoverages.forEach(coverageData => {
                let applicant = this.applicants.getApplicantById(coverageData.applicant);
                if (applicant) {
                  this.selectedCoverages.addCoverageFromSavedData(coverageData, applicant, this.products.getById(coverageData.product.id));
                } else {
                    console.error("Could not find applicant for coverage data", coverageData);
                }

              })
            }

          } else {
            // Not resuming - but include any info from other enrollments (spouse, children)
            if (this.initialData.spouse) {
              let sp = new Applicant({
                type: ApplicantTypeSpouse,
              });
              sp.mergeData(this.initialData.spouse);
              this.applicants.addApplicant(sp);
            }
            if (this.initialData.children) {
              for (let childIndex in this.initialData.children) {
                let ch = new Applicant({
                  type: ApplicantTypeChild,
                });
                ch.mergeData(this.initialData.children[childIndex]);
                this.applicants.addApplicant(ch);
              }
            }
          }


          this.caseName = this.initialData.case.group_name;

          this.fetchRates().then((rates) => {
            this.rates = rates;
          }); 
        }).catch((e) => {
        console.error("error fetching enrollment package", e);
      }).then(() => {
        // Fetch initial step (setCurrentStep always refreshes the step data, so this also populates the initial steps).
        this.setCurrentStep(0).then(() => {

          // is this a split/failed UW decision enrollment?
          // in the DB this is stored on Enrollment.is_uw_failed_split, and copied to the initial_data
          if (this.siteConfig.ffEnableExternalUnderwriting && this.initialData && this.initialData.is_uw_failed_split) {
            console.debug(`retrieveInitialSessionData: this.isUnderwritingSubmitted is falsy`);

            // get the index of the last step in this.steps with a name === 'applicant_signature'
            let lastApplicantSignatureStepIndex = this.steps.map(step => step.name).lastIndexOf('applicant_signature');
            let beneficiariesStepIndex = lastApplicantSignatureStepIndex + 1;
            let splitResumeStepIndex = 0;

            // 1. if this.isUnderwritingSubmitted is falsy, then we should nav to last applicant_sig step
            if (!this.isUnderwritingSubmitted) {
              console.debug(`retrieveInitialSessionData: is_uw_submitted: ${this.isUnderwritingSubmitted} - returning lastApplicantSignatureStepIndex: ${lastApplicantSignatureStepIndex}`);
              splitResumeStepIndex = lastApplicantSignatureStepIndex;
            } else { // UW was submitted, but the decision failed, so we go to the beneficiaries step
              console.debug(`retrieveInitialSessionData: is_uw_submitted: ${this.isUnderwritingSubmitted} - returning beneficiariesStepIndex: ${beneficiariesStepIndex}`);
              splitResumeStepIndex = beneficiariesStepIndex;
            }

            if (splitResumeStepIndex > 0) {
              console.info(`retrieveInitialSessionData: setting step to splitResumeStepIndex: ${splitResumeStepIndex}`);
              this._setStep(splitResumeStepIndex);
            }
          } else {
            // Advance to saved step if resuming enrollment.
            const resumeStepIndex = this.getResumeStepIndexIfAllowed();
            if (resumeStepIndex > 0) {
              console.log(`isUnderwritingSubmitted: ${this.isUnderwritingSubmitted} - setting step to resume step index: ${resumeStepIndex}`);
              this._setStep(resumeStepIndex);
            }
          }


          // Update rates to trigger premium lookup for any previously selected coverages.
          this.handleRatesShouldChange();

          this.isLoading = false;
        })
      }).catch(this.handleFetchError());
    },

    initializeUnderwritingDecisions() {
      this.failedDecisionUnderwritingDecisions = [];
      this.allUnderwritingDecisions = [];
      console.log(`Initialized this.failedDecisionUnderwritingDecisions:`, this.failedDecisionUnderwritingDecisions);
    },

    /**
     * Split the enrollment into a new, paused enrollment for the given coverage.
     * @param coverage
     * @returns {Promise<any>}
     */
    async onSplitAndPauseEnrollmentForCoverage(coverage) {
      let splitEnrollmentData = this.serializeEnrollmentData();

      return this._splitAndPauseEnrollment(coverage, splitEnrollmentData)
        .then((responseJson) => {
          console.debug("split and pause enrollment response:", responseJson);
          if (responseJson) {
            console.log("Enrollment split and paused for coverage:", coverage);
            return responseJson;
          } else {
            console.error("Unexpected response splitting and pausing enrollment:", responseJson);
          }
        })
        .catch(this.handleFetchError());
    },

    updateSteps() {
      return fetch(API_BASE_PATH + "/api/wizard/update-steps",
        {
          method: 'POST',
          credentials: "include",
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(this.serializeEnrollmentData()),
        }).then((resp) => {
        return resp.json();
      }).catch(this.handleFetchError());
    },

    refreshSteps() {
      return this.updateSteps().then((resp) => {
        this.steps = resp.steps;
        if (this.steps && resp.current_step) {
          console.debug('refreshSteps: current step:', resp.current_step);
          this._setStep(resp.current_step);
        }

        if (!!siteConfig.ffEnableExternalUnderwriting) {
          this.forceAllowSubmit = !!resp.force_allow_submit;
          this.forceHideCancelBtn = !!resp.is_uw_submitted;
          this.isUnderwritingSubmitted = !!resp.is_uw_submitted;
          this.isUnderwritingReceived = !!resp.is_uw_received;
        }

        this.updateUIForUnderwritingState()
      });
    },

    updateUIForUnderwritingState() {
      // lock steps if underwriting is submitted, so we can't go back and change anything up to and including
      // the last applicant signature step - EF 2023-03-30
      if (this.isUnderwritingSubmitted) {
        // if we are after the last applicant signature step, lock the steps up to the last applicant signature step.
        if (this.currentStepIndex > this.steps.findLastIndex(step => step.name === 'applicant_signature')) {
          this.lockStepsUpToApplicantSign();
          console.debug('updateUIForUnderwritingState: is_uw_submitted:', this.isUnderwritingSubmitted, " - locking steps up to applicant signature");
        }
      } else {
        this.blockNavBeforeIdx = 0;
      }
    },

    /**
     *
     */
    lockStepsUpToApplicantSign() {
      // get a copy of all steps
      let steps = this.steps.slice(0);
      // find the LAST step index with the name 'applicant_signature'
      let lastApplicantSignStepIndex = steps.findLastIndex(step => step.name === 'applicant_signature');
      if (lastApplicantSignStepIndex > 0) {
        this.blockNavBeforeIdx = lastApplicantSignStepIndex + 1;
        console.debug('lockStepsUpToApplicantSign: setting blockNavBeforeIdx to:', this.blockNavBeforeIdx);
      }
    },

    async setCurrentStep(stepIndex) {
      // clear the blocks on navigation
      this.forceNextStepDisabled = false;
      this.forceStepNavigationDisabled = false;

      await this.refreshSteps(); // this will update this.steps directly

      this.applyNavigationLocks(stepIndex);

      this._setStep(stepIndex);
    },

    applyNavigationLocks(stepIndex) {
      if (!stepIndex && stepIndex !== 0) {
        stepIndex = this.currentStepIndex;
      }
      if (this.blockNavBeforeIdx > 0 && stepIndex < this.blockNavBeforeIdx) {
        console.debug(`setCurrentStep: stepIndex ${stepIndex} is less than blockNavBeforeIdx ${this.blockNavBeforeIdx}' - setting stepIndex to ${this.blockNavBeforeIdx}`);
        stepIndex = this.blockNavBeforeIdx;
      }
    },

    _setStep(stepIndex) {
      let step = this.steps[stepIndex];
      this.currentStepIndex = stepIndex;
      this.currentApplicantContext = new WizardStepData(step).getApplicantContext(this.applicants);
      this.currentCoverageContext = new WizardStepData(step).getCoverageContext(this.selectedCoverages);
      this.stepValidationError = "";

      // emit a bus event that announces setting the step
      bus.$emit('wizard-set-step', {stepIndex: this.currentStepIndex, steps: this.steps});
    },

    async clickStepLink(stepLink) {
      if (this.isCurrentLink(stepLink) || this.isFutureLink(stepLink)) {
        // don't do anything if we click the current or a future step link.
        return;
      }

      let index = 0;
      for (let step of this.steps) {
        if (step.breadcrumb === stepLink.text) {
          await this.setCurrentStep(index);
          break;
        }
        index += 1;
      }
    },
    isCurrentLink(stepLink) {
      if (!this.currentStep) {
        return false;
      }
      return this.currentStep.breadcrumb === stepLink.text;
    },
    isBlockedLink(stepLink) {
      return stepLink._origIdx < this.blockNavBeforeIdx;
    },
    isFutureLink(stepLink) {
      let index = 0;
      for (let step of this.steps) {
        if (step.breadcrumb === stepLink.text)
        {
          // console.debug("matching step:", step.name, "for stepLink:", stepLink.text);
          // console.debug('isFutureLink: Breadcrumb:', step.breadcrumb, 'index:', index, 'currentStepIndex:', this.currentStepIndex);
          // Short circuit and return if we have passed the current link to prevent skipping ahead.
          if (index > this.currentStepIndex) {
            // console.debug("isFutureLink: true because index > this.currentStepIndex");
            return true;
          }
          // We can't link past terminal steps
          if (this.firstTerminalStepIndex > -1 && stepLink._origIdx > this.firstTerminalStepIndex) {
            // console.debug("isFutureLink: true because of terminal step");
            return true;
          }
        }

        index += 1;
      }

      return false;
    },

    goToPreviousStep: debounce(async function () {
      this.isNavigating = true;
      let stepIndex = this.currentStepIndex;
      if (stepIndex >= 1) {
        await this.setCurrentStep(stepIndex - 1);
        this.scrollToTop();
      }
      this.isNavigating = false;
    }, 1000, true),

    /**
     * This method is called when the user clicks the next button.
     */
    handleNextButton: debounce(async function () {

      if (this.forcePauseOnNextStep) {
        console.debug('handleNextButton: forcePauseOnNextStep is true - pausing now');
        this.handleEnrollmentPaused();
        return;
      }

      this.isNavigating = true;

      let stepValidationApiOptions = {save: 1};
      // on these steps, we don't save b/c we have to evaluate the response to determine what to do next
      if (this.isUnderwritingDecisionStep || this.isUnderwritingKickoffStep) {
        stepValidationApiOptions = {save: 0};
      }

      let valid = await this.performStepValidation(stepValidationApiOptions);
      console.debug('handleNextButton: performStepValidation valid:', valid);
      if (valid) {
        // are we using ext underwriting? -- EF 2023-02-15
        if (siteConfig.ffEnableExternalUnderwriting) {
          // is this the kickoff step? if so, kick it off! -- EF 2023-02-15
          if (this.isUnderwritingKickoffStep && !this.isUnderwritingSubmitted) {
            console.log(`Kickoff underwriting for all applicants`);
            await this.handleKickoffUnderwriting();

            if (this.isAllUnderwritingKickoffsSucceeded) {
              // if all underwriting kickoffs succeeded, then we can continue to the next step
              await this.performStepValidation();
              await this.goToNextStep();
            } else if (this.isAllUnderwritingKickoffsFailed || this.kickoffFailedEntirely) {
              console.log(`Failed to kickoff underwriting for all applicants`);
              this.isUnderwritingModalShowing = true;
            } else { // some failed
              console.log(`Failed to kickoff underwriting for some applicants`);
              this.isUnderwritingModalShowing = true;
            }

          } else if (this.isUnderwritingDecisionStep && this.isUnderwritingSubmitted) {

            if (this.isAllUnderwritingDecisionsSucceeded) {
              console.log(`All underwriting decisions succeeded`);
              await this.performStepValidation();
              await this.goToNextStep();
            } else if (this.isAllUnderwritingDecisionsFailed) {
              console.log(`Failed to get underwriting decisions for all applicants`);
              await this.performStepValidation({save: 0});
              this.isUnderwritingModalShowing = true;
            } else { // some failed
              console.log(`Failed to get underwriting decisions for some applicants`);
              await this.performStepValidation({save: 0});
              this.isUnderwritingModalShowing = true;
            }
          } else {
            await this.validateStep();
            await this.goToNextStep();
          }
        } else {
          await this.performStepValidation(); // this does the save - EF 2023-03-25
          await this.goToNextStep();
        }
      }
      this.isNavigating = false;
    }, 1000, true),

    /**
     * this is triggered by the Underwriting Decision Modal (this.isUnderwritingModalShowing true/false)
     */
    handleUnderwritingFailedPauseButton() {
      // @TODO review this logic - EF 2023-02-28
      this.isUnderwritingModalShowing = false;

      // did all fail or just some? if some, we split and pause. -- EF 2023-02-15
      if (this.isAllUnderwritingKickoffsFailed || this.kickoffFailedEntirely || this.isAllUnderwritingDecisionsFailed) {
        console.log(`Pausing ENTIRE enrollment process because all underwriting decisions failed`);
        this.handleEnrollmentPaused(1);
      } else {
        // likely this will be !this.isAllUnderwritingKickoffsSucceeded || !this.isAllUnderwritingDecisionsSucceeded
        // get each failed for ANY reason and split and pause

        console.log(`Splitting and pausing enrollment process because some underwriting decisions failed`);
        // get coverages to split and pause
        let allFailed = [];
        if (this.failedKickoffUnderwritingDecisions) {
          allFailed = allFailed.concat(this.failedKickoffUnderwritingDecisions);
        }
        if (this.failedDecisionUnderwritingDecisions) {
          allFailed = allFailed.concat(this.failedDecisionUnderwritingDecisions);
        }

        let coveragesToSplitAndPause = [];
        for (let i = 0; i < allFailed.length; i++) {
          let thisApplicant = this.applicants.getApplicantById(allFailed[i].enrollment_party.client_applicant_id);
          let thisProduct = this.products.getById(allFailed[i].product_id)
          let thisCoverage = this.selectedCoverages.getSelectedCoverageForApplicantProduct(thisApplicant, thisProduct);
          coveragesToSplitAndPause.push(thisCoverage);
          console.debug("Length of this.selectedCoverages", this.selectedCoverages.getAllSelectedCoverages().length);
        }

        if (coveragesToSplitAndPause.length < 1) {
          console.error(`Splitting and pausing enrollment process: no coverages to split and pause`);
          return;
        }

        // split and pause each coverage in coveragesToSplitAndPause
        let splitPromises = [];
        for (let j = 0; j < coveragesToSplitAndPause.length; j++) {
          console.log(`Splitting and pausing enrollment process for coverage:`, coveragesToSplitAndPause[j]);

          splitPromises.push(this.onSplitAndPauseEnrollmentForCoverage(coveragesToSplitAndPause[j])
            .then((responseJson) => {
              console.info(`Split and paused enrollment process for coverage:`, coveragesToSplitAndPause[j], responseJson);

              console.log('removing coverage from selected coverages', coveragesToSplitAndPause[j], coveragesToSplitAndPause[j].name)
              this.selectedCoverages.removeCoverage(coveragesToSplitAndPause[j]);
              console.info(`Removed coverage from this.selectedCoverages`, this.selectedCoverages.getAllSelectedCoverages())
            })
            .catch((error) => {
              console.error(`Failed to split and pause enrollment process for coverage:`, coveragesToSplitAndPause[j], error);
            })
          );

        }

        Promise.all(splitPromises).then((values) => {
          console.log("All split promises resolved. Moving to next step", values);

          //   1. record the current number of steps we have and the name of the current step
          let currentStepName = this.currentStep.name;
          let currentStepTitle = this.currentStep.title;
          let currentStepCount = this.steps.length;

          // Now we validate and SAVE in the backend. We should have removed the coverages above
          console.debug('handleUnderwritingFailedPauseButton: performing step validation');
          this.performStepValidation({save: 1});

          //   2. updateSteps() to get the new set of steps with the updated session info
          this.updateSteps().then((resp) => {

            let newSteps = resp.steps;
            console.debug("Steps updated after Splits", this.steps, newSteps, resp);

            if (currentStepCount > newSteps.length) {  // we lost steps in the split
              let stepCountChange = this.steps.length - newSteps.length;
              console.debug('handleUnderwritingFailedPauseButton: step count change:', stepCountChange);

              if (resp.current_step) {
                this.setCurrentStep((resp.current_step - stepCountChange) + 1);
              } else {
                this.setCurrentStep((this.currentStepIndex - stepCountChange) + 1);
              }

              this.updateUIForUnderwritingState();
              console.debug('handleUnderwritingFailedPauseButton: current step:', this.currentStep);
            } else if (newSteps && resp.current_step) {
              // here we replace the current steps with the new steps - EF 2023-03-25
              this.steps = newSteps;
              console.debug('Replaced steps. Now:', this.steps);

              if (currentStepCount !== this.steps.length) {
                console.debug('handleUnderwritingFailedPauseButton: change in step count. Current step count:', currentStepCount, 'new step count:', this.steps.length);
                //   4. find the current step in the new set of steps and setStep() to _that_ step's NEXT step
                for (let i = 0; i < this.steps.length; i++) {
                  // we don't have a unique ID for steps. This will get us as close as possible, because .name is
                  // duped between many steps (especially applicant_signature) - EF 2023-03-13
                  console.debug('handleUnderwritingFailedPauseButton: comparing current step name:', currentStepName,
                      'to step name:', this.steps[i].name, 'and current step title:', currentStepTitle, 'to step title:',this.steps[i].title,
                      'index:', i);

                  if (this.steps[i].name === currentStepName && this.steps[i].title === currentStepTitle) {
                    console.debug('handleUnderwritingFailedPauseButton: found current step:', this.steps[i]);
                    this.currentStepIndex = i;
                    console.debug('handleUnderwritingFailedPauseButton: set currentStepIndex to:', this.currentStepIndex)

                    // @TODO should we refresh or go to next step? is this.currentStepIndex the next step already? - EF 2023-03-26
                    if (i + 1 < this.steps.length) {
                      console.debug('handleUnderwritingFailedPauseButton: calling goToNextStep, which will load step', i + 1, this.steps[i + 1]);
                      this.goToNextStep();
                    } else {
                      console.debug(`handleUnderwritingFailedPauseButton: setCurrentStep(${i}) step:`, this.steps[i]);
                      this.setCurrentStep(i);
                    }
                    break;
                  }
                }
              } else {
                let newStepIdx = resp.current_step + 1;
                console.debug('handleUnderwritingFailedPauseButton: no change in step count. Current step count:', currentStepCount, 'new step count:', this.steps.length, 'newStepIdx:', newStepIdx);
                this.setCurrentStep(newStepIdx);
              }

              this.updateUIForUnderwritingState();
            } else {
              console.error('handleUnderwritingFailedPauseButton: no steps or current step returned');
            }
          });

        });

        // this.goToNextStep();
      }
    },

    async handleKickoffUnderwriting() {
      let enrollmentData = this.serializeEnrollmentData();

      if (!enrollmentData) {
        console.error(`UnderwritingKickoffModal: no enrollment data provided`);
        return;
      }

      await Api.createUnderwritingDecisionsForEnrollmentSession(enrollmentData)
        .then(response => {
          console.debug(`underwriting kickoff response`, response);

          // if errors, we should display and not continue
          if (response.errors) {
            console.error(`underwriting kickoff response errors`, response.errors);
            this.underwritingKickoffGeneralErrorText = 'There was an error submitting your underwriting requests.';
            this.kickoffFailedEntirely = true;
            return;
          } else {
            // reset errors so the state does not persist
            this.underwritingKickoffGeneralErrorText = '';
            this.kickoffFailedEntirely = false;
          }

          this.allUnderwritingDecisions = response;

          // collect failed underwriting decisions
          this.failedKickoffUnderwritingDecisions = response.filter(eud => eud.is_failed);

          return response;
        })
        .catch(error => {
          console.error(`underwriting kickoff error caught:`, error);
          this.underwritingKickoffGeneralErrorText = error.toString();
        })
        .finally(() => {
        });
    },

    goToNextStep: debounce(async function () {

      let stepIndex = this.currentStepIndex;
      if (stepIndex < this.steps.length - 1) {

        await this.setCurrentStep(stepIndex + 1);
        this.scrollToTop();
      }
    }, 1000, true),

    reloadCurrentStep: async function () {
      this.isNavigating = true;
      await this.setCurrentStep(this.currentStepIndex);
      this.scrollToTop();
      this.isNavigating = false;
    },

    /**
     * Note that this also stored the serialized enrollment data in a paused
     * enrollment on the server
     * @returns {Promise<any>}
     * @param {{save: number}} options - options object
     */
    async validateStepServerSide(options = {save:1}) {
      let path = "/api/wizard/validate-step";
      if (options && (options.save === 0 || options.save === false)) {
        path = "/api/wizard/validate-step?save=0";
      }

      return fetch(API_BASE_PATH + path, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify({data: this.serializeEnrollmentData(), current_step: this.currentStepIndex}),
        credentials: "include",
      }).then((r) => {
        return r.json()
      }).catch(this.handleFetchError());
    },

    validateStep() {
      this.isValidating = true;
      bus.$emit("validate");
      return this.$refs.form.checkValidity();
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    handleEnrollmentDataChanged({step, name, value}) {
      console.debug("Enrollment data changed", step, name, value);
    },
    handleCoverageDataChanged({coverage, name, value}) {
      console.debug("Coverage data changed", coverage, name, value);
      coverage.updateData(name, value);
    },
    handleApplicantDataChanged({applicant, name, value}) {
      console.debug("Applicant data changed", applicant, name, value);
      applicant.updateData(name, value);
      this.empSelectedRemoteSignature = applicant.getEmpSelectedRemoteSignature();
    },

    handleAddChild() {
      let emp_last_name = this.applicants.getFirstApplicantByType(ApplicantTypeEmployee).getLastName();
      let child = new Applicant({type: ApplicantTypeChild});
      if (emp_last_name) {
        child.updateData("last_name", emp_last_name);
      }
      this.applicants.addApplicant(child);
    },
    handleRemoveChild(child) {
      this.applicants.removeApplicant(child);
      // Ensure we remove any coverages associated with this applicant.
      this.selectedCoverages.removeApplicantCoverages(child);
    },

    handleRatesShouldChange() {

      this.fetchRates().then((rates) => {

        this.rates = rates;


        bus.$emit("updated-rates", rates);
      });
    },
    displayRates(increase){
      this.fetchRates().then((rates) => {
        let rateInfo = {
          data: this.serializeEnrollmentData(),
          updatedRates : rates,
          increase: increase
        }
        bus.$emit('handle-updated-rates', rateInfo)

      });
    },
    coverageSelectionComplete(val) {
      this.selectedCoverage = val.value;
    },
    handleQuestionAnswerChanged(data) {
      this[data.name] = data.value;
    },

    async sendCallCenterReviewEmail() {
      const EMAIL_SEND_COMPLETE_EVENT = "email-send-complete";
      try {
        const resp = await fetch(API_BASE_PATH + "/api/wizard/send-call-center-review-email", {
          method: 'POST',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify(this.serializeEnrollmentData()),
          credentials: "include",
        });
        if (resp.status === 200) {
          let data = await resp.json();
          // Store the token in the enrollment data so the backend can update the existing token rather than
          //  create a new one if the agent re-sends the email.
          this.initialData.call_center_review_token = data.token;
          // Tell the button widget that the send succeeded.
          bus.$emit(EMAIL_SEND_COMPLETE_EVENT, true);
        } else {
          bus.$emit(EMAIL_SEND_COMPLETE_EVENT, false);
        }
      } catch (e) {
        bus.$emit(EMAIL_SEND_COMPLETE_EVENT, false);
      }
    },

    previewEnrollment({summaryOnly}) {

      let req = this.fetchEnrollmentPreview(summaryOnly).then((resp) => {
        if (resp.status !== 200) {
          throw("Invalid PDF Bytes");
        }
        return resp.arrayBuffer();
      }).catch(this.handleFetchError("There was a server problem creating the preview PDF")
      ).then((pdfBytes) => {

        pdfjs.getDocument(pdfBytes).promise.then((pdf) => {
          let canvasContainer = document.getElementById("enrollment-preview");
          let options = {
            scale: 1.0,

          };

          function renderPage(page) {
            let viewport = page.getViewport(options);
            let canvas = document.createElement('canvas');

            let ctx = canvas.getContext('2d');
            let renderContext = {
              canvasContext: ctx,
              viewport: viewport
            };

            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvasContainer.appendChild(canvas);

            return page.render(renderContext);
          }

          for (let i = 1; i <= pdf.numPages; i += 1) {
            pdf.getPage(i).then(renderPage);
          }
          bus.$emit("preview-ready");
        });

      });

    },
    fetchEnrollmentPreview(summaryOnly) {
      let body = '';
      if (this.selectedCoverages.serialize().length > 0) {
        body = JSON.stringify(this.serializeEnrollmentData());
      }

      let url = (summaryOnly) ? "/api/wizard/preview-summary" : "/api/wizard/preview";

      return fetch(API_BASE_PATH + url, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: body,
        credentials: "include",
      }).catch(this.handleFetchError("There was a server problem previewing the enrollment"));
    },

    fetchRates() {
      return fetch(API_BASE_PATH + "/api/wizard/rates", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      }).then((r) => {
        return r.json()
      }).catch(this.handleFetchError('There was a server problem fetching premium information'));
    },

    cancelEnrollment() {
      this.isCancelModalShowing = true;
    },

    pauseEnrollment() {
      this.isPauseModalShowing = true;
    },

    handleCancelModalDismissed(trigger) {
      this.isCancelModalShowing = false;
    },

    /**
     *
     * @param {String} toSay
     */
    sayOnConference(toSay) {
      this.isUpdatingConference = true;
      Api.sayOnCurrentConference(toSay)
        .then((r) => {
          console.log('said', r);
        }).finally(() => {
        this.isUpdatingConference = false;
      });
    },


    /**
     *
     * @param {String} scriptId
     */
    sayScriptOnConference(scriptId) {
      this.isUpdatingConference = true;
      Api.sayScriptOnCurrentConference(scriptId)
        .then((r) => {
          console.log('said script', r);
        }).finally(() => {
        this.isUpdatingConference = false;
      });
    },

    handleEnrollmentCanceled() {
      // first, submit cancel to an endpoint
      fetch(API_BASE_PATH + "/api/wizard/cancel", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      })
        // then, redirect
        .then(r => {
          this.redirectToEnrollmentCompletePage();
        })
    },

    declineEnrollment() {
      this.isDeclineModalShowing = true;
    },

    handleEnrollmentDeclined() {
      // first, submit decline to an endpoint
      fetch(API_BASE_PATH + "/api/wizard/decline", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      })
        // then, redirect
        .then(r => {
          this.redirectToEnrollmentCompletePage();
        })
    },

    /**
     * If the enrollment is being paused due to an underwriting failure, pass 1 as the first argument.
     * @param {Number} isUnderwritingFailure - 1 if the enrollment is being paused due to an underwriting failure. Defaults to 0.
     */
    handleEnrollmentPaused(isUnderwritingFailure = 0) {
      // pause the enrollment then redirect
      this._pauseEnrollment(this.serializeEnrollmentData(), isUnderwritingFailure)
        // then, redirect
        .then(r => {
          this.redirectToEnrollmentCompletePage();
        });
    },


    _pauseEnrollment(serializedEnrollmentData, isUnderwritingFailure = 0) {
      if (!serializedEnrollmentData) {
        serializedEnrollmentData = this.serializeEnrollmentData();
      }
      return fetch(API_BASE_PATH + "/api/wizard/pause?is_uw_failure=" + isUnderwritingFailure, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(serializedEnrollmentData),
        credentials: "include",
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.error("Error pausing enrollment", response);
          }
        })
        .catch(this.handleFetchError())
    },

    /**
     *
     * @param {Coverage} coverage
     * @param {Object} currentEnrollmentData
     * @returns {Promise<Response | boolean>}
     * @private
     */
    _splitAndPauseEnrollment(coverage, currentEnrollmentData) {
      // we need the product_id and the applicant_id
      let applicantId = coverage.applicant.id;
      let productId = coverage.product.id;

      return fetch(API_BASE_PATH + `/api/wizard/split-and-pause/${productId}/${applicantId}`, {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(currentEnrollmentData),
        credentials: "include",
      })
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            console.error("Error splitting and pausing enrollment", response);
          }
        })
        .catch(this.handleFetchError())
    },

    async performStepValidation(options = {save: 1}) {
      // Clear out error before validation
      this.stepValidationError = "";

      // Validate built-in html form conditions.
      if (!this.validateStep()) {
        this.stepValidationError = "Please complete all required information above";
        return Promise.resolve(false);
      }
      this.isValidating = false;

      // Server-side validation
      let validation = await this.validateStepServerSide(options);
      if (!validation.is_valid) {
        let error = validation.errors[0];
        if (error.name === null) {
          this.stepValidationError = error.message;
        } else {
          // TODO: Highlight faulty field identified by name
          this.stepValidationError = error.message;
        }
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    },

    validateAndSubmitApplication: debounce(async function () {
      let valid = await this.performStepValidation();
      if (valid) {
        this.submitApplication()
      }
    }, 3000, true),

    submitApplication() {
      if (siteConfig.ffEnableExternalUnderwriting) {
        if (!this.isUnderwritingSubmitted) {
          alert(`Underwriting is not submitted yet. Cannot submit application.`);
          return;
        } else if (!this.isUnderwritingReceived) {
          alert(`Underwriting is not received yet. Cannot submit application.`);
          return;
        }
      }

      if (this.isSubmissionModalShowing === true) {
        // protect against double click
        return;
      }

      if (this.isCallCenterReview) {
        // Redirect to complete page, there is no submission for this mode.
        this.$router.push({name: 'WizardComplete'});
        return;
      }

      this.isSubmissionModalShowing = true;
      this.submissionProblem = "";

      fetch(API_BASE_PATH + "/api/wizard/submit", {
        method: 'POST',
        headers: {'content-type': 'application/json'},
        body: JSON.stringify(this.serializeEnrollmentData()),
        credentials: "include",
      }).then(r => {
        if (r.status === 200) {
          return r.json()
        } else {
          this.submissionProblem = "There was a problem processing the submission.";
          throw "Submission error";
        }
      }).then(json => {
        if (json.success) {
          window.setTimeout(() => {
            this.redirectToEnrollmentCompletePage();
          }, 3000);
        } else {
          this.submissionProblem = "There was a problem processing the submission."
        }
      }).catch(this.handleFetchError());
    },

    redirectToEnrollmentCompletePage() {
      if (this.isRemoteSignature) {
        this.$router.push({name: 'RemoteWizardComplete'});
      } else {
        this.$router.push({name: 'WizardComplete'});
      }
    },

    updateCoverageList(){
      this.fetchRates().then((rates) => {
        this.rates = rates;
        this.selectedCoverages.updateCoverage(rates)
      });
    },
    /**
     *
     * @returns {{data: {applicants: *, selectedCoverages: *[], paymentInformation: {paymentScheduleType: string, paymentScheduleWeekdayOccurrence: number, paymentMethodId: null, paymentScheduleDayOfMonth: number, paymentScheduleDayOfWeek: number, paymentWithApp: boolean, paymentCustomerId: null, paymentWithAppMade: boolean}}, initial_data: {}, current_step_index: null}}
     */
    serializeEnrollmentData() {
      let enrollmentSessionData = {
        initial_data: this.initialData,
        current_step_index: this.currentStepIndex,
        data: {
          applicants: this.applicants.serialize(),
          selectedCoverages: this.selectedCoverages.serialize(),
          paymentInformation: this.paymentInformation,
        },
      }

      return enrollmentSessionData;
    },

    handleFetchError(msg = "There was an error communicating with the server.") {
      return () => {
        this.stepValidationError = msg;
        // Prevent further promise then handlers from running.
        return false;
      }
    },

    updateSubmitButtonText() {
      if (this.isRemoteSignature) {
        this.submitButtonText = "Sign Application";
        return;
      }

      if (!this.isSelfEnroll && !this.isAgentEnrollRemoteSignature) {
        if (this.empSelectedRemoteSignature) {
          this.submitButtonText = "Finish and Send for Signature"
          return;
        }
      }

      this.submitButtonText = "Finish and Submit Application";
    },

    getResumeStepIndexIfAllowed() {
      // Are we resuming, did we save the step index, and is it less than the current number of steps?
      if ((!this.initialData.resumed_enrollment_data ||
        !this.initialData.resumed_enrollment_data.current_step_index ||
        !(this.initialData.resumed_enrollment_data.current_step_index < this.steps.length))) {
        return 0;
      }

      // If the case has been modified since we paused, do not attempt to resume
      const pausedTime = moment(this.initialData.paused_time);
      if (this.initialData.case.updated_at &&
        moment(this.initialData.case.updated_at).isAfter(pausedTime)) {
        return 0;
      }

      if (this.initialData.start_audio_conference) {
        // When we are using an audio conference, ALWAYS restart at the beginning.
        return 0;
      } else if (this.initialData.previous_start_audio_conference === true) {
        // If we were using an audio conference, but are not now, we need to restart at the beginning.
        return 0;
      }

      // @TODO not sure if this is necessary, but leaving here for future generations - EF 2023-01-05
      // if (this.initialData.previous_enrollment_mode && this.initialData.previous_enrollment_mode !== this.initialData.enrollment_mode) {
      //   // If the enrollment mode has changed, we need to restart at the beginning.
      //   return 0;
      // }

      // if blockNavBeforeIdx is set, then we have received UW and cannot resume enrollment before the beneficiaries step
      if (this.blockNavBeforeIdx && this.blockNavBeforeIdx > 0 && this.initialData.resumed_enrollment_data.current_step_index < this.blockNavBeforeIdx) {
        console.debug("getResumeStepIndexIfAllowed: returning this.blockNavBeforeIdx: ", this.blockNavBeforeIdx)
        return this.blockNavBeforeIdx;
      }

      return this.initialData.resumed_enrollment_data.current_step_index;
    },

    /**
     * Retrieves the conference status, and will continue to re-poll for new statuses
     * as long as `this.isConferenceStarting` == true
     * @return {Promise<void>}
     * @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
     */
    async updateConferenceEventsSubscription(tryUntilConferenceStart = false) {
      console.debug('Getting conference status. this.isConferenceStarting: ', this.isConferenceStarting);
      const pollDelay = EnrollmentConferenceSessions.POLL_DELAY;
      window.__EE_confStatusSubTimeout = null;

      // always get at least one status
      Api.getCurrentConferenceStatus()
        .then(responseJson => {
          if (responseJson && !responseJson.errors) {
            if (!this.isConferenceEnded) { // once the conference is ended, we don't want to update anymore
              console.debug('Updating subscription to conf status events:', responseJson);
              bus.$emit('conference-status-updated', responseJson);
            }

            this.$nextTick(() => {
              if (!this.isConferenceReadyToStart) {  // are we active baaed on the first request?
                window.__EE_confStatusSubTimeout = setTimeout(() => {
                  if (!this.isConferenceReadyToStart) {  // are we still active after waiting pollDelay ms?
                    // if so, request and re-evaluate
                    this.updateConferenceEventsSubscription();
                  }
                }, pollDelay);
              }
            });
          } else if (tryUntilConferenceStart) {
            console.debug('tryUntilConferenceStart is true. this.isConferenceStarting: ', this.isConferenceStarting);
            this.updateConferenceEventsSubscription(tryUntilConferenceStart);
          }
        });
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    stopConferenceEventsSubscription() {
      // this will stop the timeout that keeps grabbing
      clearTimeout(window.__EE_confStatusSubTimeout);
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    cancelConferenceAndResetState() {
      this.stopConferenceEventsSubscription();

      if (this.isAudioConferenceEnrollment && !this.isConferenceReadyToStart) {
        this.isConferenceCancelling = true;
        this.isConferenceCancelled = false;

        bus.$emit('cancel-enrollment-session-conference');

        this._cancelConference()
          .then((r) => {
            this.isConferenceCancelling = false;
            this.isConferenceCancelled = true;
          })
          .catch((reason) => {
            this.isConferenceCancelling = false;
            this.isConferenceCancelled = false;
          });
      }

      this.conferenceStatus = null;
      this.conferenceStatusReason = null;
      this.conferenceEnd = null;
    },

    // @TODO duplicate code from new-enrollment-modal.vue. Merge this into something reusable
    _cancelConference() {
      return Api.cancelCurrentConference()
        .then((r) => {
          console.debug("Maybe do something when current conference is cancelled.", r);
        })
        .catch((reason) => {
          console.warn("Problem cancelling current conference.", reason);
        })
        .finally(() => {
        });
    },

    handleConferenceEndedUnexpectedly() {
      // immediately pause the enrollment via the API
      this._pauseEnrollment()
        .then((r) => {
          console.log('Session should be auto-paused and show user overlay');
        });

    },

    exitToEnrollmentDetails() {
      this.cancelConferenceAndResetState();
      this.redirectToCurrentEnrollmentSessionDetails();
    },
    redirectToCurrentEnrollmentSessionDetails() {
      this.$router.push({name: 'WizardConfFailedAutoPaused'});
    },
  },
  watch: {
    // whenever question changes, this function will run
    empSelectedRemoteSignature: function (newVal, oldVal) {
      this.updateSubmitButtonText();
    },
    async isConferenceEnrollment() {
      // when this changes, we should re-evaluate if we need to subscribe to updates
      await this.updateConferenceEventsSubscription();
    },
    async isConferenceEndedUnexpectedly(newVal, oldVal) {
      // when this changes to true, we need to
      if (newVal !== oldVal) {
        if (newVal) {
          await this.handleConferenceEndedUnexpectedly()
        }
      }
    }

  }
};

export default wizard
</script>


<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}


/* Ordering is important for these two sections. */
/* What to animate to when sliding down, and what to start from when class is removed. */
.slideDown-enter-active, .slideDown-leave-active {
    max-height: 500px;
    overflow-y: hidden;
    overflow-x: hidden;

    transition: max-height .3s ease-in-out;
}

/* Starting (when transition started) and ending states (when slideDown removed has finished) */
.slideDown-enter, .slideDown-leave-to {
    max-height: 0;
}

.totalPremiumSection {
    font-size: 70%;
    margin-right: 2rem;
}

.totalPremiumAmount {
    font-weight: bold;
}
</style>
