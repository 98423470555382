<template>

  <span v-if="valueOnly">
    <span v-show="hasUpdated" class="totalPremiumAmount">{{ formattedTotalPremium }}</span>
    <span v-show="!(hasUpdated) || isLoading" class="totalPremiumAmount"><font-awesome-icon icon="spinner" pulse></font-awesome-icon></span>
  </span>

  <span v-else class="totalPremiumSection">
    Total {{ premiumMode.text }} Premium:
    <span class="totalPremiumAmount">
      {{ formattedTotalPremium }}
    </span>
  </span>

</template>

<script>
import bus from "./messages";

const _defaultFormattedPremium = '$--.--';

export default {
  props: [
    "applicants",
    "selectedCoverages",
    "premiumMode",
    "valueOnly",
  ],
  data: function() {
    return {
      isLoading: false,
      formattedTotalPremium: _defaultFormattedPremium,
    };
  },
  computed: {
    hasUpdated() {
      return !(this.formattedTotalPremium === _defaultFormattedPremium);
    }
  },
  created() {
    bus.$on("update-premium", this.startLoading);
    bus.$on("updated-rates", this.refreshTotalPremium);

    // kickstart the process myself. I may load on a page that never emits 'update-premium' itself.
    bus.$emit("update-premium");
  },
  methods: {
    startLoading() {
      this.isLoading = true;
    },
    refreshTotalPremium(rates) {
      let total = 0;
      for (let productRates of rates) {
        if (productRates.summary && productRates.summary.total_premium) {
          total += parseFloat(productRates.summary.total_premium) * 100;  // multiply them all so we are doing our match by cents
        } else if (productRates.summary && productRates.summary.premium_tiers && productRates.summary.premium_tiers.length > 0) {

          // Assume first tier is best available premium, if we don't have other info.
          let anticipated_premium = productRates.summary.premium_tiers[0].premium * 100;

          // If we have an anticipated_policy_rating in the summary, use that to pick the premium level to display
          if (productRates.summary.anticipated_policy_rating) {
            for (let x = 0; x < productRates.summary.premium_tiers.length; x++) {
              // Matching on lowercase label here is a little brittle (what if the label is tweaked?),
              // but works as long as the "label" of the rating is just the value in titlecase.
              if (productRates.summary.premium_tiers[x].label.toLowerCase() === productRates.summary.anticipated_policy_rating) {
                anticipated_premium = productRates.summary.premium_tiers[x];
                break;
              }
            }
          }

          total += anticipated_premium;
        }
      }
      total = Math.round(total) / 100  // this turns the resulting integer into a float with max two decimal places. (cents into dollars)
      this.formattedTotalPremium = `$${total.toFixed(2)}`;
      this.isLoading = false;
    }
  },

}
</script>

<style scoped>

</style>
