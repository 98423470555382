import manageUsers from './manage-users.html'
import Api from './api.js'
import sorting from './models/sorting'
import rowsPerPageOptions from './models/rows-per-page-options'
import formats from './models/formats'
import manageUserForm from './manage-user-form'
import manageTagsSection from './manage-tags'

import {every, some} from "lodash"
import ShowNavigation from "./nav/ShowNavigation";

export default {
  template: manageUsers,
  components: {
    sorting,
    manageUserForm,
    manageTagsSection,
    ShowNavigation,
  },
  props: {
    userPermissions: {type: Object},
  },
  data() {
    return {
      fields: [{
        key: 'select',
        label: '',
        class: 'select-column',
      }, {
        key: 'first_name',
        label: 'First',
        sortable: true
      }, {
        key: 'last_name',
        label: 'Last',
        sortable: true
      }, {
        key: 'email',
        label: 'Email',
        class: 'd-none d-sm-table-cell',
        sortable: true
      }, {
        key: 'code',
        label: 'Code/ID',
        class: 'd-none d-md-table-cell'
      }, {
        key: 'tags',
        label: 'Tags',
        class: 'd-none d-md-table-cell',
        formatter: (value) => {
          let tags = value.map(v => {
            return v.name
          });
          tags = tags.sort();
          return tags.join(', ');
        }
      }, {
        key: 'last_login',
        label: 'Last Sign In',
        class: 'd-none d-lg-table-cell',
        sortable: true,
        formatter: (dateString) => {
          return formats.utcToDateTime(dateString);
        }
      }, {
        key: 'status',
        label: 'Status',
        class: 'd-none d-sm-table-cell',
        sortable: true
      } //,
        // {
        //     key:'is_api_user',
        //     label:'API User',
        //     class:'d-none d-sm-table-cell',
        //     sortable: true,
        //     formatter: (tf) => {
        //       return (tf ? 'Yes' : 'No');
        //     }
        // }
      ],
      tags: [],
      users: [],
      userErrors: [],
      rowsPerPageOptions: rowsPerPageOptions,
      usersFilter: '',
      showUserModal: false,
      showTagsModal: false,
      userForm: {},
      rowsPerPage: 25,
      currentPage: 1,
      totalCount: null,
      includeDeactivated: false,
      selectedTags: [],
      tagValue: '',
      newTagName: '',
      sortBy: 'status',
      sortDesc: true,
      homeOfficeAdmin: false,
      caseAdmin: false,
      userAdmin: false,
      reportsAdmin: false,
      userIsHomeOfficeAdmin: false,
      showNewUserSection: false,
      tableIsLoading: true,
      showManageTags: false,
    }
  },
  methods: {
    searchUsers() {
      this.users = [];
      this.tableIsLoading = true;
      setTimeout(() => {
        let params = {
          search_text: this.usersFilter,
          include_deactivated: this.includeDeactivated,
          sort_column: this.sortBy,
          sort_direction: this.sortDesc ? 'DESC' : 'ASC',
          page: this.currentPage,
          rows_per_page: this.rowsPerPage
        };
        Api.searchUsers(params)
          .then(response => {
            if (response.errors && response.errors.length > 0) {
              this.errors = response.errors;
            } else {
              response.rows.forEach(r => {
                r.select = false;
              });
              this.totalCount = response.total_count;
              this.users = response.rows;
              this.tableIsLoading = false;
            }
          });
      }, 50);
    },
    openNewUser() {
      this.userForm = {};
      this.showNewUserSection = true;
      this.selectedTags = [];
    },
    openEditUser(user) {
      this.$router.push('/user-details/' + user.id);
    },
    finishCreateUser() {
      this.searchUsers();
      this.userErrors = [];
      this.showNewUserSection = false;
    },
    updateUser(user) {
      delete user.select;
      delete user._rowVariant;
      return Api.updateUser(user);
    },
    addRolesToUser(userId) {
      let roles = [];
      if (this.homeOfficeAdmin) {
        roles = ['HomeOfficeAdmin', 'CaseAdmin', 'UserAdmin', 'ReportAdmin'];
      } else {
        if (this.caseAdmin) {
          roles.push('CaseAdmin');
        }
        if (this.userAdmin) {
          roles.push('UserAdmin');
        }
        if (this.reportsAdmin) {
          roles.push('ReportAdmin');
        }
      }
      return Api.addRolesToUser(userId, {roles: roles});
    },
    toggleUserActivation(isActive) {
      let selectedUserCopy = Object.assign({}, this.userForm);
      selectedUserCopy.status = isActive ? 'active' : 'deactivated';
      this.updateUser(selectedUserCopy)
        .then(() => {
          this.userForm.status = isActive ? 'active' : 'deactivated';
        });
    },
    toggleUsersActivation(isActive) {
      let selectedUsers = this.users.filter(u => {
        return u.select
      });
      selectedUsers.forEach(u => {
        u.status = isActive ? 'active' : 'deactivated';
        this.updateUser(Object.assign({}, u));
      });
    },
    selectUser(record) {
      let action = !record.select;
      record.select = action;
      record._rowVariant = record.select ? 'selected' : '';
    },
    userClick(record, index, event) {
      if (event.target.localName === 'td') {
        let recordCopy = Object.assign({}, record);
        this.openEditUser(recordCopy);
      }
    },
    headerClick(key) {
      if (key === 'select') {
        this.handleSelectClick();
      }
    },
    handleSelectClick() {
      let selectedUsers = this.users.filter(u => {
        return u.select
      });
      this.users.forEach(u => {
        u.select = selectedUsers.length === 0;
        u._rowVariant = selectedUsers.length === 0 ? 'selected' : '';
      });
    },
    userIsSelected() {
      let disabled = true;
      this.users.forEach(u => {
        if (u.select) {
          disabled = false;
        }
      });
      return disabled;
    },
    openTagModal() {
      let selectedUsers = this.users.filter(u => {
        return u.select
      });
      this.selectedTags = [];

      if (selectedUsers.length === 1) {
        this.selectedTags = this.mapTags(selectedUsers[0].tags);
      }

      this.showTagsModal = true;
    },
    getAllTags() {
      return Api.searchTags()
        .then(response => {
          this.tags = formats.alphebetize(this.mapTags(response), 'text');
        });
    },
    mapTags(response) {
      return response.map(tag => {
        return {value: tag.id, text: tag.name}
      });
    },
    createTag(name) {
      Api.createTag({name: name})
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.tags.push(this.mapTags([response])[0]);
            this.newTagName = '';
          }
        });
    },
    addTagsToUser(userId) {
      let tagIds = this.selectedTags.map(t => {
        return t.value
      });
      let data = {tag_ids: tagIds};
      return Api.addTagsToUser(userId, data);
    },
    addTagsToSelectedUser() {
      let selectedUser = this.users.filter(u => {
        return u.select
      })[0];
      this.addTagsToUser(selectedUser.id)
        .then(() => {
          this.searchUsers();
          this.showTagsModal = false;
        });
    },
    addTagsToGroupOfUsers() {
      let selectedUsers = this.users.filter(u => {
        return u.select
      });
      let userIds = selectedUsers.map(u => {
        return u.id
      });
      let tagIds = this.selectedTags.map(t => {
        return t.value
      });

      let promises = [];
      tagIds.forEach(tagId => {
        promises.push(this.addTagToGroupOfUsers(tagId, userIds));
      });

      Promise.all(promises)
        .then(() => {
          this.searchUsers();
          this.showTagsModal = false;
        });
    },
    addTagToGroupOfUsers(tagId, userIds) {
      let data = {user_ids: userIds};
      return Api.addTagToGroupOfUsers(tagId, data);
    },
    singleUserSelected() {
      return this.users.filter(u => {
        return u.select
      }).length === 1;
    },
    resetPassword(user) {
      Api.forgotPassword({
        username: user.username
      });
    },
    resendInvitation(userId) {

    },
    sortChanged(header) {
      if (header.sortBy) {
        this.sortBy = header.sortBy;
        this.sortDesc = header.sortDesc;
        this.searchUsers();
      }
    },
    rowsPerPageChange() {
      setTimeout(() => {
        localStorage.rowsPerPage = this.rowsPerPage;
      });
      this.searchUsers();
    },
    toggleManageTagsSection() {
      this.showManageTags = !this.showManageTags;
    },
    handleTagCreated(tag) {
      this.getAllTags();
    },
    handleTagUpdated(tag) {
      this.searchUsers();
      this.getAllTags();
    },
    handleTagDeleted() {
      this.searchUsers();
      this.getAllTags();
    }
  },
  computed: {
    userCanCreateUser() {
      return this.userPermissions.canCreateUser();
    },
    userCanCreateTag() {
      return this.userPermissions.canCreateTag();
    },
    userCanChangeUser() {
      return this.userPermissions.canChangeUser();
    },
    areAllUsersSelected() {
      return every(this.users, (u) => u.select);
    },
    areSomeUsersSelected() {
      return !this.areAllUsersSelected && some(this.users, (u) => u.select);
    },
    areNoUsersSelected() {
      return !some(this.users, (u) => u.select);
    }
  },
  watch: {
    homeOfficeAdmin: function () {
      this.caseAdmin = this.homeOfficeAdmin;
      this.userAdmin = this.homeOfficeAdmin;
      this.reportsAdmin = this.homeOfficeAdmin;
    },
  },
  created() {
    let rowsPerPage = parseInt(localStorage.rowsPerPage);
    this.rowsPerPage = rowsPerPage && this.rowsPerPageOptions.includes(rowsPerPage) ? rowsPerPage : this.rowsPerPage;

    this.searchUsers();
    this.getAllTags();
  }
}
