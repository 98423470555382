
let next_applicant_id = 0;


class Applicant {
  constructor(options = {}) {
    if (options.id) {
      this.id = options.id;
      next_applicant_id = this.id + 1;
    } else {
      this.id = next_applicant_id;
      next_applicant_id += 1;
    }

    this.type = options.type;
    this.data = options.data || {};
  }

  getFirstName() {
    let first = this.data['first_name'];
    if (first !== null && first !== undefined) {
      return first;
    } else {
      return this.type;
    }
  }

  getLastName() {
    let last = this.data['last_name'];
    if (last !== null && last !== undefined) {
      return last;
    } else {
      return "";
    }
  }

  getMiddleName() {
    let middle = this.data['middle_name'];
    if (middle !== null && middle !== undefined) {
      return middle;
    } else {
      return "";
    }
  }

  getPhone() {
    return this._getItem('phone');
  }

  getAddress() {
    return this._getItem('address');
  }

  getAddress2() {
    return this._getItem('address_2');
  }

  getApplicantCity() {
    return this._getItem('applicant_city');
  }

  getApplicantState() {
    return this._getItem('state');
  }

  getApplicantZip() {
    return this._getItem('zip');
  }

  _getItem(key, fallback="") {
    let value = this.data[key]
    if (value !== null && value !== undefined) {
      return value;
    } else {
      return fallback;
    }
  }

  getEmpSelectedRemoteSignature() {
    return !!this.data['emp_selected_remote_signature'];
  }

  isEmployee() {
    return this.type === ApplicantTypeEmployee;
  }
  isSpouse() {
    return this.type === ApplicantTypeSpouse;
  }
  isChild() {
    return this.type === ApplicantTypeChild;
  }

  getDefaultRelationshipTo(otherApplicant) {
    if (otherApplicant.id === this.id) {
      return "Self";
    }
    if ((otherApplicant.isEmployee() && this.isSpouse()) || (otherApplicant.isSpouse() && this.isEmployee())) {
      return "Spouse";
    } else if (this.isChild() && !otherApplicant.isChild()) {
      // TODO check grandparent
      return "Parent";
    } else if (otherApplicant.isChild() && !this.isChild()) {
      // TODO check grandchild
      return "Child";
    } else if (this.isChild() && otherApplicant.isChild()) {
      return "Sibling";
    }

    return "";
  }

  getName() {
    return this.getFirstName() + " " + this.getLastName();
  }

  getFullName() {
    return this.getFirstName() + (" " + this.getMiddleName()).trimEnd() + (" " + this.getLastName()).trimEnd();
  }

  updateData(name, value) {
    this.data[name] = value;
  }

  mergeData(data) {
    this.data = Object.assign(this.data, data);
  }

  serialize() {
    let output = {...this.data};
    output.id = this.id;
    output.type = this.type;
    return output;
  }
}


class ApplicantList {
  constructor(applicants=[]) {
    this._applicants = applicants;
  }
  addApplicant(applicant) {
    this._applicants.push(applicant);
  }
  removeApplicant(applicant) {
    this._applicants = this._applicants.filter((a) => a.id !== applicant.id);
  }
  serialize() {
    return this._applicants.map((a) => a.serialize());
  }
  getApplicantsByType(applicantType) {
    return this._applicants.filter((a) => a.type === applicantType);
  }
  getFirstApplicantByType(applicantType) {
    return this._applicants.find((a) => a.type === applicantType);
  }
  getApplicantById(applicantId) {
    return this._applicants.find((a) => a.id === applicantId);
  }
}


class Coverage {
  constructor(name, product, applicant, dataFields) {
    this.name = name;
    this.product = product;
    this.applicant = applicant;
    this.data = dataFields;
  }

  updateData(name, value) {
    this.data[name] = value;
  }

  getValue(name) {
    if (name in this.data) {
      return this.data[name];
    } else {
      return null;
    }
  }

  serialize() {
    return {
      name: this.name,
      product: this.product,
      applicant: this.applicant.id,
      data: this.data
    }
  }
}


/**
 * Track coverages outside the CoverageList class to prevent prop from changing when we modify it.
 * @type {Coverage[]}
 */
let coverages = [];

class CoverageList {
  constructor() {
  }

  serialize() {
    return coverages.map((c) => c.serialize());
  }

  /**
   * Returns all selected coverages.
   * @returns {Coverage[]}
   */
  getAllSelectedCoverages() {
    return coverages;
  }

  getSelectedCoveragesByApplicant(applicant) {
    return coverages.filter((c) => c.applicant.id === applicant.id);
  }

  getSelectedCoveragesByProduct(product) {
    return coverages.filter((c) => c.product.id === product.id);
  }

  getSelectedCoverageForApplicantProduct(applicant, product) {
    return coverages.find((c) => c.product.id === product.id && c.applicant.id === applicant.id);
  }

  getOrCreateCoverageForApplicantProduct(applicant, product) {
    let coverage = this.getSelectedCoverageForApplicantProduct(applicant, product);
    if (coverage === undefined) {
      let c = new Coverage(product.name, product, applicant, {});
      coverages.push(c);
      return c;
    } else {
      return coverage;
    }
  }

  addCoverageFromSavedData(coverageData, applicant, product) {
    const coverage = new Coverage(product.name, product, applicant, coverageData.data);
    coverages.push(coverage);
  }

  removeApplicantCoverages(applicant) {
    coverages = coverages.filter(c => c.applicant.id !== applicant.id);
  }

  removeCoverage(coverage) {
    coverages = coverages.filter(c => c !== coverage);
  }

  updateCoverage(rates){
    try{
      for(let coverage of coverages){
        for(let rate of rates){
          if(coverage.applicant.id === rate.applicant_id){
            let anticipatedMainCoverage = rate.main_coverage;
            coverages[parseInt(rate.applicant_id)].data.final_expense_coverage.premium = anticipatedMainCoverage;
            break;
          }
        }
      }
    }
    catch(e){
      console.log("The error is", e)
    }
  }
}


class FaceValueCoverageOption {
  constructor(faceValue, premium) {
    this.faceValue = faceValue;
    this.premium = premium;
  }

  serialize() {
    return {
      faceValue: this.faceValue,
      premium: this.premium,
    }
  }
}

class CoverageSelection {
  constructor(applicant, coverage, fieldValues) {
    this.applicant = applicant;
    this.coverage = coverage;
    this.fieldValues = fieldValues;
  }
}

class CoverageSelectionEvent {
  constructor(applicant, coverage, selection) {
    this.applicant = applicant;
    this.coverage = coverage;
    this.selection = selection;
  }
}


class PremiumMode {
  constructor(modeInt, text) {
    this.modeInt = modeInt;
    this.text = text;
  }
}


const PremiumModeAnnually = new PremiumMode(1, "Annually");
const PremiumModeMonthly = new PremiumMode(12, "Monthly");
const PremiumModeSemiMonthly = new PremiumMode(24, "Semi-Monthly");
const PremiumModeBiWeekly = new PremiumMode(26, "Bi-Weekly");
const PremiumModeWeekly = new PremiumMode(52, "Weekly");


let modesByType = {
  annual: PremiumModeAnnually,
  monthly: PremiumModeMonthly,
  semimonthly: PremiumModeSemiMonthly,
  biweekly: PremiumModeBiWeekly,
  weekly: PremiumModeWeekly,
};

function getPremiumModeByType(modeType) {
  return modesByType[modeType];
}


class Product {

  constructor({id, name, display_name}) {
    this.id = id;
    this.name = name;
    this.displayName = display_name;
  }

}

class ProductList {
  constructor(products=[]) {
    this.products = products;
  }

  getById(id) {
    return this.products.find((p) => p.id === id);
  }

  getByName(name) {
    return this.products.find((p) => p.name === name);
  }
}

class BeneficiaryList {
  constructor() {
    this.beneficiaries = [];
  }
}

class Beneficiary {
  constructor() {

  }
}


const ApplicantTypeEmployee = "employee";
const ApplicantTypeSpouse = "spouse";
const ApplicantTypeChild = "child";

export {
  ApplicantTypeEmployee,
  ApplicantTypeSpouse,
  ApplicantTypeChild,
  Applicant,
  ApplicantList,
  Coverage,
  CoverageList,
  CoverageSelectionEvent,
  Product,
  ProductList,
  FaceValueCoverageOption,
  getPremiumModeByType,
  PremiumModeAnnually,
  PremiumModeSemiMonthly,
  PremiumModeBiWeekly,
  PremiumModeMonthly,
  PremiumModeWeekly,
  PremiumMode,
}
