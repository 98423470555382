
<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    productStats: {type: Object, required: true},
  },
  mounted () {
    let chartData = {

      labels: [],
      datasets: [
        {
          label: "Count",
          backgroundColor: [
            "#3e95cd",
            "#8e5ea2",
            "#3cba9f",
            "#c45850",
            "#e8c3b9",
            "#6A6C6E",
          ],
          data: []
        }
      ]

    };

    this.productStats.products.forEach((productStats) => {
        chartData.datasets[0].data.push(productStats.count)
        chartData.labels.push(productStats.product)
    });

    // If last product is 'Other Products', make it medium grey...
    const numProducts = this.productStats.products.length;
    const lastProductIndex = this.productStats.products.length - 1;
    const numColors = chartData.datasets[0].backgroundColor.length;
    const lastColorIndex = chartData.datasets[0].backgroundColor.length - 1;
    if (numProducts > 0 &&
        numColors >= numProducts &&
        this.productStats.products[lastProductIndex].product === 'Other Products') {
      chartData.datasets[0].backgroundColor[lastProductIndex] = '#606060';
    }

    this.renderChart(chartData, {
      responsive: true,
      title: {display: true, text: 'Count', fontColor: '#212529'},
      legend: {display: false, position: 'bottom'},

    })
  }
}
</script>
