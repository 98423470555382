import formats from "./formats";

export default [
  {
    key: 'employee_name',
    label: 'Employee',
    sortable: false
  },
  {
    key: 'case_name',
    label: 'Case',
    class:'d-none d-sm-table-cell',
    sortable: false
  },
  {
    key:'agent_name',
    label: 'Agent',
    class:'d-none d-lg-table-cell',
    sortable: false
  },
  {
    key:'created_at',
    label: 'Date',
    formatter: (value) => {
      return formats.utcToLocalDateTime(value);
    },
    sortable: true
  },
  {
    key:'status',
    label: 'Status',
    class:'d-none d-lg-table-cell',
    formatter: (value) => {
      return formats.snakeToTitleCase(value);
    },
    sortable: false
  },
  'sign',
]
