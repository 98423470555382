import {onUnmounted, reactive, ref} from "@vue/composition-api";
import {debounce} from "../utils";

import API from "../api";

async function fetchCases(caseSearchParams) {
    try {
        return await API.searchCasesWithFilters(caseSearchParams);
    } catch (e) {
        console.error(e)
        return [];
    }
}

export default function useCaseSearch() {
    const isLoadingCases = ref(true);

    const caseSearchParams = reactive({
        searchText: "",
        searchProperty: 'all',
        statusFilter: 'active',
        orderBy: "name",
        orderDirection: "asc",
        currentPage: 1,
        rowsPerPage: 25,
        includeInactive: false,
    });

    const cases = ref([]);
    // totalResultsCount is the number of unpaginated results.
    const totalResultsCount = ref(null);

    const refreshCases = async () => {
        isLoadingCases.value = true;
        cases.value = [];
        const results = await fetchCases(caseSearchParams);
        for (let i = 0; i < results.cases.length; i++) {
            cases.value.push(results.cases[i]);
        }
        totalResultsCount.value = results.totalResultsCount;
        isLoadingCases.value = false;
    }
    const debouncedRefreshCases = debounce(refreshCases, 300);
    onUnmounted(() => debouncedRefreshCases.clear());

    return {
        refreshCases: debouncedRefreshCases,
        cases,
        totalResultsCount,
        caseSearchParams,
        isLoadingCases,
    }
}
