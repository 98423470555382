<template>
  <div>
    <b-row>
      <b-col cols="12" lg="6" offset="0" offset-lg="6">
        <b-form-group
          class="mb0 float-right"
          :state="validationState"
          :invalid-feedback="invalidFeedback"
        >
          <template slot="description">
            <div class="float-right">{{ description }}</div>
          </template>
          <template slot="invalid-feedback">
            <div class="float-right text-danger">{{ invalidFeedback }}</div>
          </template>
          <!-- The checkbox should be hidden using class "d-none" from bootstrap v4 -->
          <b-form-checkbox
            class="d-none"
            v-model="played"
            :value="true"
            :unchecked-value="false"
            :variant="validationStateVariant"
            :state="validationState"
            :required="!optional"
            name="audio-announcement-played"
          ></b-form-checkbox>

          <b-button ref="input"
                    pill
                    size="md"
                    :variant="validationStateVariant"
                    :disabled="playButtonDisabled"
                    :pressed="played"
                    @click="sayScriptOnConference(audio_script_id)">
              <span v-if="playButtonDisabled">
                <font-awesome-icon icon="spinner" pulse></font-awesome-icon>
                Please wait while playback continues...
              </span>
            <span v-else-if="played === true">
                <font-awesome-icon icon="check"></font-awesome-icon>
                Re-play {{ display_name }}
              </span>
            <span v-else>
                <font-awesome-icon icon="play-circle"></font-awesome-icon>
                {{ display_name }}
              </span>
          </b-button>
          <b-button v-if="playButtonDisabled" ref="cancel-button"
                    variant="outline-danger"
                    class="ml-1"
                    pill
                    size="md"
                    @click="sayScriptOnConference(cancelScriptId)">
            <font-awesome-icon icon="times"></font-awesome-icon>
            Cancel
          </b-button>
          <div class="mt-1 pl-2 pr-2">
            <b-progress  v-if="playButtonDisabled" label="Playback Progress" height="5px" :value="progressValue" :max="100" :variant="validationStateVariant"></b-progress>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import bus from "../bus";

const CANCEL_ID = "__cancel__";
const CANCEL_ID_DELAY_MS = 0;
const PROGRESS_UPDATE_INTERVAL_MS = 250;

export default {
  name: "AudioAnnouncementFormInput",
  props: {
    name: {type: String, required: true},
    display_name: {
      type: String,
      required: false
    },
    initial_value: {
      type: Boolean,
      required: false,
      default: false
    },
    optional: {type: Boolean, required: false},
    audio_script_id: {
      type: String,
      required: true
    },
    delay_time_ms: {
      type: Number,
      required: false,
      default: 5000
    },
    description: {
      type: String,
      required: false
    },
  },
  created() {
    this.emitValue();
    bus.$on("validate", this.handleValidate);
    bus.$on("play-audio-announcement", this.handlePlayAudioAnnouncement);
  },
  destroyed() {
    this.stopProgressTimer();
    clearTimeout(this._setTimeoutId);
  },
  watch: {
    played() {
      this.emitValue();
      this.changed = true;
    }
  },
  computed: {
    validationStateVariant() {
      if (this.validationState === false) {
        return "danger";
      } else if (this.validationState === true) {
        return "success";
      }

      return "primary";
    },
    validationStateOutlineVariant() {
      return `outline-${this.validationStateVariant}`;
    },
    validationStateTextVariant() {
      return `text-${this.validationStateVariant}`;
    },
    validationStateBgVariant() {
      return `bg-${this.validationStateVariant}`;
    },
    footerText() {
      if (this.validationState === false) {
        return "";
      } else if (this.validationState === true) {
        return "Script playback initiated.";
      }

      return '';
    },
    validationState() {
      // Always touch this.value for reactivity
      let currentVal = this.played;

      if (!this.optional && this.played === true) {
        return true;
      }
      if (!this.optional && this.validated && this.played === false) {
        return false;
      }
      return null;
    },
    invalidFeedback() {
      if (!this.optional && this.played !== true) {
        return "This audio announcement must be played to the applicant.";
      }
      return "";
    },
    progressValue() {
      let delay_time_ms = this.delay_time_ms;
      if (this.currentScriptId === CANCEL_ID) {
        delay_time_ms = 1000;
      }

      let progressValue = (this.playbackCurrentTime - this.playbackStartTimeMs) / delay_time_ms * 100;
      if (progressValue > 100) {
        progressValue = 100;
      }
      if (progressValue < 0) {
        progressValue = 0;
      }
      return progressValue;
    },
  },
  data: function () {
    return {
      played: !!this.initial_value,
      changed: false,
      validated: false,
      playButtonDisabled: false,
      currentScriptId: this.audio_script_id,

      cancelScriptId: CANCEL_ID,

      _setTimeoutId: null,

      _progressTimerInterval: null,

      playbackStartTimeMs: null,
      playbackEndTimeMs: null,
      playbackCurrentTime: null,
    }
  },
  methods: {
    /**
     * This just raises a bus event 'play-audio-announcement' that the WizardComponent handles
     * @param scriptId  string
     */
    sayScriptOnConference(scriptId) {
      bus.$emit("play-audio-announcement", {scriptId: scriptId});
      this.currentScriptId = scriptId;

      let delay_time_ms = this.delay_time_ms;
      if (this.currentScriptId === CANCEL_ID) {
        delay_time_ms = CANCEL_ID_DELAY_MS;
      }

      this.playbackStartTimeMs = new Date().getTime();
      this.playbackEndTimeMs = this.playbackStartTimeMs + this.delay_time_ms;
      console.debug(`playbackStartTime: ${this.playbackStartTimeMs}, playbackEndTime: ${this.playbackEndTimeMs}, diff: ${this.playbackEndTimeMs - this.playbackStartTimeMs}`);

      this.startProgressTimer();  // this will start updating this.playbackCurrentTime

      this.playButtonDisabled = true;

      // clear existing timeout
      if (this._setTimeoutId !== null) {
        clearTimeout(this._setTimeoutId);
        this._setTimeoutId = null;
      }

      this._setTimeoutId = setTimeout(() => {
        console.debug("Updating this.value to true for audio announcement", this.audio_script_id);
        if (scriptId !== CANCEL_ID) {  // cancelling should not change the value.
          this.played = true;
        }
        this.stopProgressTimer();
        this.playButtonDisabled = false;
      }, delay_time_ms);
    },
    emitValue() {
      this.$emit("value-change", {name: this.name, value: this.played});
    },
    handleValidate() {
      console.debug("AudioAnnouncementFormInput.handleValidate");
      this.validated = true;
    },
    updateCurrentTime() {
      this.playbackCurrentTime = Date.now();
    },
    startProgressTimer() {
      this._progressTimerInterval = setInterval(this.updateCurrentTime, PROGRESS_UPDATE_INTERVAL_MS);
    },
    stopProgressTimer() {
      clearInterval(this._progressTimerInterval);
    },
    handlePlayAudioAnnouncement(eventArgs) {
      if (eventArgs.scriptId !== this.audio_script_id) {
        // stop playing if this is NOT my script
        clearTimeout(this._setTimeoutId);
        this.stopProgressTimer();
        this.playButtonDisabled = false;
      }
    }
  }
}
</script>

<style scoped>

</style>
