<template>
  <div mt="3">
    <h6>{{ display_name }}</h6>

    <b-table
      :fields="['min_age', 'max_age', 'premium', 'remove']"
      :items="ageBandRates"
    >
      <template v-slot:cell(min_age)="data">
        <b-form-input
          type="number"
          @change="emitValue"
          v-model="data.item.min_age"></b-form-input>
      </template>
      <template v-slot:cell(max_age)="data">
        <b-form-input
          type="number"
          @change="emitValue"
          v-model="data.item.max_age"></b-form-input>
      </template>

      <template v-slot:cell(premium)="data">
        <enroll-currency-input
          @value-change="updateRateBand(data.index, 'premium', $event)"
          :initial_value="data.item.premium"
          step="0.0001"
          :precision="precision || 4"
        ></enroll-currency-input>
      </template>

      <template v-slot:cell(remove)="data">
        <b-button
          @click="removeAgeBand(data.item)"
          variant="danger" size="sm"><i class="fa fa-times"> </i></b-button>
      </template>
    </b-table>
    <b-row align-h="end">
      <b-col cols="4">
        <b-button
          variant="success"
          size="sm"
          @click="addRateBand">
          <font-awesome-icon icon="plus"></font-awesome-icon>
          Add Age Band
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {currencyInput} from "../enrollment/FormInput";


let AgeBandedRateTableEditor = {
  name: "AgeBandedRateTableEditor",
  props: [
    "display_name",
    "name",
    "initial_value",
    "metadata",
    "optional",
    "precision",
  ],
  components: {
    'enroll-currency-input': currencyInput,
  },
  created() {
    if (this.initial_value !== undefined && this.initial_value.ageBandRates !== undefined) {
      this.ageBandRates = this.initial_value.ageBandRates;
    }
  },
  data: function () {
    return {
      ageBandRates: []
    }
  },
  methods: {
    addRateBand() {
      this.ageBandRates.push({min_age: null, max_age: null, premium: null});
      this.emitValue();
    },
    removeAgeBand(ageBand) {
      this.ageBandRates.splice(this.ageBandRates.indexOf(ageBand), 1);
      this.emitValue();
    },
    updateRateBand(index, name, e) {
      this.ageBandRates[index][name] = e.value;
      this.emitValue();
    },
    emitValue() {

      // Strip out dollar signs from formatted premiums
      let ageBandRatesStripped = [];

      for (let i in this.ageBandRates) {
        let band = Object.assign({}, this.ageBandRates[i]);
        if (typeof (band.premium) === 'string' && band.premium[0] === '$') {
          // remove the dollar sign
          band.premium = band.premium.substring(1);
        }
        ageBandRatesStripped.push(band);
      }

      const val = {ageBandRates: ageBandRatesStripped, metadata: this.metadata};

      this.$emit("value-change", {name: this.name, value: val});
    },
  }
};

export default AgeBandedRateTableEditor;
</script>

<style></style>
