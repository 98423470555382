import bus from "../bus";
import {siteConfig} from "../app"
import settingsTemplate from "./general_settings.html"
import caseDivisions from "./case-divisions.vue";

let GeneralCaseSettings = {
  name: "GeneralCaseSettings",
  props: [
    "caseObject",
    "premiumModeOptions",
    "statesOptions",
    "selectedProducts"
  ],
  components: {
    caseDivisions
  },
  template: settingsTemplate,
  data: function() {
    return {
      caseData: {
        name: this.caseObject.group_name,
        number: this.caseObject.group_number,
        caseDivisions: this.caseObject.case_divisions,
        usesGroupNumber: this.caseObject.uses_group_number,  // this is "read-only"
        caseDivisionsEnabled: this.caseObject.case_divisions_enabled,  // this is "read-only"
        defaultCity: this.caseObject.situs_city,
        defaultState: this.caseObject.situs_state,
        premiumMode: this.caseObject.premium_mode,
      },

      // this is the checkbox control model
      showCaseDivisionsComponent: false,

      // this determines if we should show the list of Case Divisions. It
      // `.sync`s with case-divisions.vue
      showCaseDivisionsList: false,
    }
  },
  computed: {
    showGroupNumberInput() {
      return !!this.caseData.usesGroupNumber;
    },
    showCaseDivisions() {
      // must be enabled, and either they manually enabled, or there already were divisions for this case
      return this.caseData.caseDivisionsEnabled;
    },
    shouldShowCity() {
      return siteConfig.enableCaseSetupCityState;
    },
    shouldShowState() {
      return siteConfig.enableCaseSetupCityState;
    },
    enablePremiumSelection() {
      return siteConfig.enableCaseSetupPremiumSelection;
    }
  },
  watch: {
    caseData: {
      handler: function (val, oldVal) {
        bus.$emit("case-data-changed", {
          group_name: val.name,
          group_number: val.number,
          case_divisions: val.caseDivisions,
          situs_city: val.defaultCity,
          situs_state: val.defaultState,
          premium_mode: val.premiumMode,
        });
        console.log("case data changed in general_settings with case_divisions: ", {caseDivisions: val.caseDivisions});
      },
      deep: true
    }
  },
};

export default GeneralCaseSettings;
