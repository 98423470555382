<template>
  <div>
    <b-card v-if="!isLoading">
      <b-card-title>
        Product Options for {{ product.text }}
      </b-card-title>
      <b-card-body>
        <b-form-row align-v="start">

          <b-col v-for="optionInput in optionComponents"
                 :key="optionInput.name"
                 :cols="optionInput.cols || 12"
                 :offset="optionInput.offset || 0"
          >
            <component
              v-bind="optionInput"
              v-show="isComponentVisible(optionInput)"
              :disabled="isComponentDisabled(optionInput)"
              :is="getComponentType(optionInput.type)"
              :name="optionInput.name"
              :display_name="optionInput.display_name"
              :options="optionInput.options || []"
              :initial_value="(getInitialValue(optionInput.name) !== null && getInitialValue(optionInput.name) !== undefined) ? getInitialValue(optionInput.name) : optionInput.default"
              @value-change="handleDataChanged"
            ></component>
          </b-col>

        </b-form-row>

        <div v-if="optionComponents.length === 0">
          <p>There are no {{ $root.terminology.formatCase({}) }} options for {{ product.text }}.</p>
        </div>

      </b-card-body>
    </b-card>
    <b-card v-else>
      Loading...
    </b-card>
  </div>

</template>

<script>
import {
  checkboxInput, multiCheckboxInput,
  multiSelectInput, selectInput, integerInput, enrollTextInput,
  radioInput, staticContent, sectionLabel, fieldGroup, currencyInput,
} from "../enrollment/FormInput";
import bus from '../bus'
import Api from '../api'
import AgeBandedRateTableEditor from "./rate_table_editor";

let keyID = 1;

export default {
  name: "Product-Options",
  props: {
    product: {type: Object},
    productOptions: {type: Map},
  },

  created() {
    this.refreshOptions();
  },

  components: {
    'checkbox-input': checkboxInput,
    'multi-checkbox-input': multiCheckboxInput,
    'multi-select-input': multiSelectInput,
    'select-input': selectInput,
    'integer-input': integerInput,
    'text-input': enrollTextInput,
    'radio-input': radioInput,
    'static-content': staticContent,
    'section-label': sectionLabel,
    'field-group': fieldGroup,
    'rate-table-editor': AgeBandedRateTableEditor,
    'currency': currencyInput,
  },

  data: function () {

    // Copy the current case options so we can potentially cancel changes.
    let initialOptions = this.productOptions.get(this.product.value);
    console.log("initialOptions", initialOptions);

    return {
      optionValues: initialOptions,
      optionComponents: [],
      isLoading: true,
    }
  },

  methods: {
    handleDataChanged({name, value}) {
      this.optionValues[name] = value;

      bus.$emit('case-product-options-changed', {product: this.product, options: this.optionValues});
      this.$forceUpdate();
    },
    getComponentType(typeString) {
      // Translate server type strings to our component names
      if (typeString === "checkbox") {
        return 'checkbox-input';
      } else if (typeString === "multi-checkbox") {
        return 'multi-checkbox-input';
      } else if (typeString === "select") {
        return 'select-input';
      } else if (typeString === "multi-select") {
        return 'multi-select-input';
      } else if (typeString === "integer") {
        return 'integer-input';
      } else if (typeString === "text") {
        return 'text-input';
      } else if (typeString === "radioInput") {
        return "radio-input"
      } else {
        // Assume it matches exactly the component name
        return typeString;
      }
    },
    getInitialValue(name) {
      return this.optionValues[name];
    },
    getComponentKey(component) {
      // Just return a new int each time so Vue doesn't cache the option components
      keyID += 1;
      return keyID;
    },

    isComponentVisible(component) {
      if (!component.show_if_options_match) {
        return true;
      }

      // Need to evaluate each option rule
      for (let ruleIndex in component.show_if_options_match) {
        let rule = component.show_if_options_match[ruleIndex];
        let currentValue = this.optionValues[rule.name];

        if (currentValue !== undefined && currentValue !== null && currentValue.length !== undefined) {
          if (currentValue.find(x => x === rule.value) === undefined) {
            return false;
          }
        } else {
          if (currentValue !== rule.value) {
            return false;
          }
        }

      }

      return true;
    },

    isComponentDisabled(component) {
      if (!component.disabled_if_options_match) {
        return false;
      }

      // Need to evaluate each option rule
      for (let ruleIndex in component.disabled_if_options_match) {
        let rule = component.disabled_if_options_match[ruleIndex];
        let currentValue = this.optionValues[rule.name];

        if (currentValue !== undefined && currentValue !== null && currentValue.length !== undefined) {
          if (currentValue.find(x => x === rule.value) === undefined) {
            return false;
          }
        } else {
          if (currentValue !== rule.value) {
            return false;
          }
        }

      }

      return true;
    },

    refreshOptions() {
      // Fetch product option fields from the API
      this.isLoading = true;
      this.optionComponents = [];
      this.optionValues = {};

      let productId = this.product.value;
      Api.getOptionFieldsForProduct(productId).then((components) => {

        //this.optionValues = {...this.product.json};
        this.optionValues = this.productOptions.get(this.product.value) || {};//{...this.product.json};
        this.optionComponents = components;
        this.isLoading = false;
      });

    }

  },
  watch: {
    product() {
      this.refreshOptions();
    }
  }
}
</script>
