import userDetails from './user-details.html'
import Api from './api.js'
import formats from './models/formats'
import roles from './models/roles'
import manageUserForm from './manage-user-form'

export default {
    template: userDetails,
    components: {
      manageUserForm
    },
    props: {
      userPermissions: { type: Object },
    },
    data () {
      return {
        tags: [],
        users: [],
        userErrors: [],
        userForm: {},
        tagValue: '',
        newTagName: '',
        homeOfficeAdmin: false,
        caseAdmin: false,
        userAdmin: false,
        reportsAdmin: false,
        userIsHomeOfficeAdmin: false,
        showUserForm: false
      }
    },
    methods: {
      getUser(userId) {
        Api.getUserByUserId(userId)
        .then(response => {
          this.userForm = response;
          this.showUserForm = true;
        });
      },
      finishEditUser() {
        // User is saved message
      },
      getAllTags() {
        return Api.searchTags()
        .then(response => {
          this.tags = formats.alphebetize(this.mapTags(response), 'text');
        });
      },
      mapTags(response) {
        return response.map(tag => {return {value:tag.id, text:tag.name}});
      }
    },
    watch: {
      homeOfficeAdmin: function() {
        this.caseAdmin = this.homeOfficeAdmin;
        this.userAdmin = this.homeOfficeAdmin;
        this.reportsAdmin = this.homeOfficeAdmin;
      },
    },
    created() {
      if(this.$route.params.id) {
        this.getUser(this.$route.params.id);
      }

      this.getAllTags();
    }
}