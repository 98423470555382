import bus from "../bus";
import settingsTemplate from "./user_access_settings.html";
import Api from "../api";

let UserAccessSettings = {
  name: "UserAccessSettings",

  props: [
    "caseObject",
    "users",
    "userPermissions",
    "tags",
    "caseTags",
    "caseUsers",
  ],
  template: settingsTemplate,
  data: function() {
    return {
      selectedTags: [...this.caseTags],
      selectedNonAPIUsers: null,
      selectedAPIUsers: null,
      nonApiUsers: null,
    }
  },
  created() {
    this.nonApiUsers = [...this.users].filter(user => !user.isApiUser);
    this._processCaseUsers();
  },
  methods: {
    _processCaseUsers() {
      this.selectedNonAPIUsers = [...this.caseUsers].filter(user => !user.isApiUser);
      this.selectedAPIUsers = [...this.caseUsers].filter(user => user.isApiUser);
    },
    userCanSetCaseTags() {
      return this.userPermissions.canSetUserTags();
    },
    userCanAssignUserToCase() {
      return this.userPermissions.canManageCaseUsers();
    },
    userCanViewTaggedUsers() {
      return this.userPermissions.canViewTaggedUsers();
    },

    updateCase() {
      bus.$emit('case-data-changed', {
        tagged_restricted_to_own_enrollments: this.caseObject.tagged_restricted_to_own_enrollments
      })
    },
    caseTagsChanged() {
      bus.$emit('case-tags-changed', this.selectedTags);
    },
    caseUsersChanged() {
      bus.$emit('case-users-changed', this.selectedUsers);
    },

    handleRestrictClick() {
      let restrictedUsers = this.selectedUsers.filter(u => {return u.restrict});
      this.selectedUsers.forEach(u => {
        u.restrict = restrictedUsers.length === 0;
      });
    },


    removeUser(user) {
      user.restrict = false;
      this.selectedNonAPIUsers = this.selectedNonAPIUsers.filter(u => u.value !== user.value);
      this.caseUsersChanged();
    },
    restrictUser(user) {
      user.restrict = !user.restrict;
      this.caseUsersChanged();
    },


  },
  computed: {
    selectedUsers() {
      return this.selectedNonAPIUsers.concat(this.selectedAPIUsers);
    },
    userCheckStatus() {
      let restrictedUsers = this.selectedUsers.filter(u => {return u.restrict});
      if(restrictedUsers.length===0) {
        return 'empty';
      } else if(this.selectedUsers.length === restrictedUsers.length) {
        return 'check';
      } else {
        return 'half';
      }
    },
  },
  watch: {
    caseTags() {
      this.selectedTags = [...this.caseTags];
    },
    caseUsers(val) {
      this._processCaseUsers();
    }
  },
};

export default UserAccessSettings;
