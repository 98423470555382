<template>
  <div>
    <b-card no-body>
      <b-list-group flush>
        <b-list-group-item>
          <b-form-checkbox v-model="caseDivisionsSwitchOn" switch> Enable Case Divisions</b-form-checkbox>
        </b-list-group-item>
        <template v-if="showCaseDivisionsList">
          <b-list-group-item v-for="(caseDivision, idx) in localCaseDivisions" class="flex-column align-items-start"
                             :key="caseDivision.id">
            <div class="d-flex w-100 justify-content-between">
              <div>
                <div>{{ caseDivision.division_name }}</div>
                <div><small class="text-muted">{{ caseDivision.default_city }}, {{ caseDivision.default_state }}</small>
                </div>
              </div>
              <span><b-button @click="editDivision(caseDivision, idx)" size="sm" variant="outline-secondary"><font-awesome-icon
                icon="edit"></font-awesome-icon> Edit</b-button></span>
            </div>
          </b-list-group-item>
          <b-list-group-item button variant="outline-primary" @click="addNewDivision">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            Add new Division
          </b-list-group-item>
        </template>
      </b-list-group>
    </b-card>


    <b-modal
      :title="modalTitle"
      v-model="showNewDivisionForm"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">
      <b-form :validated="validateModalForm">
        <b-form-group
          label="Division Name"
          label-for="division-name"
          label-cols-sm="4"
          label-align-sm="right"
          :state="validateLocalCaseDivisionName"
          invalid-feedback="This field is required."
        >
          <b-form-input id="division-name"
                        v-model="localCaseDivisionName"
                        required>
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Default City"
          label-for="default-city"
          label-cols-sm="4"
          label-align-sm="right"
          :state="validateLocalCaseDivisionDefaultCity"
          invalid-feedback="This field is required."
        >


          <b-form-input id="default-city"
                        v-model="localCaseDivisionDefaultCity"
                        :state="validateLocalCaseDivisionDefaultCity"
                        required>
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Default State"
          label-for="default-state"
          label-cols-sm="4"
          label-align-sm="right"
          :state="validateLocalCaseDivisionDefaultState"
          invalid-feedback="This field is required."
        >
          <b-form-select
            v-model="localCaseDivisionDefaultState"
            :options="statesOptions">
          </b-form-select>
        </b-form-group>
      </b-form>
      <div slot="modal-footer" class="row align-self-end">
        <b-btn
          v-if="localCaseDivisionId"
          size="md"
          class="mr-3"
          variant="outline-danger"
          @click="removeDivision">
          <font-awesome-icon icon="trash"></font-awesome-icon>
          Delete
        </b-btn>
        <b-btn
          size="md"
          class="mr-3"
          variant="outline-secondary"
          @click="cancelModal">
          Cancel
        </b-btn>
        <b-btn
          size="md"
          class="mr-3"
          variant="outline-primary"
          @click="saveCaseDivisions">
          Save
        </b-btn>
      </div>

    </b-modal>


  </div>
</template>

<script>
import bus from "../bus";

export default {
  name: "case-divisions",

  props: [
    "caseObject",
    "caseData",
    "premiumModeOptions",
    "statesOptions",
    "selectedProducts",
    "showCaseDivisionsList"
  ],

  created() {
    this.localCaseDivisions = this.caseData.caseDivisions;
    // if we have case divisions for this Case, this enables showing the list automatically
    this.caseDivisionsSwitchOn = !!this.localCaseDivisions && this.localCaseDivisions.length > 0;
  },

  methods: {
    addNewDivision() {
      this._resetModalForm();
      this.showNewDivisionForm = true;
    },
    validateForm() {
      return this.validateLocalCaseDivisionName && this.validateLocalCaseDivisionDefaultCity && this.validateLocalCaseDivisionDefaultState;
    },
    removeDivision() {
      // @TODO replace with BootstrapVue confirmation modal below when LL is on new codebase - EF 2022-05-27
      if (window.confirm(`Are you sure you want to delete ${this.localCaseDivisionName}?`)) {
        this.localCaseDivisions.splice(this.localCaseDivisionListIndex, 1);

        this._resetModalForm();
        this.showNewDivisionForm = false;

        // this emits the change as an event
        this.emitValue();

        this.showLoadingIndicator = false;
      }

      // this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${this.localCaseDivisionName}?`)
      //   .then(value => {
      //       if (value === true) {
      //         this.localCaseDivisions = this.localCaseDivisions.filter(cd => {
      //           // cd.id !==
      //         });
      //       }
      //     }
      //   )
    },
    editDivision(caseDivision, idx) {
      this._resetModalForm();

      this.localCaseDivisionListIndex = idx;
      this.localCaseDivisionId = caseDivision.id;
      this.localCaseDivisionName = caseDivision.division_name;
      this.localCaseDivisionDefaultCity = caseDivision.default_city;
      this.localCaseDivisionDefaultState = caseDivision.default_state;
      this.showNewDivisionForm = true;
    },
    cancelModal() {
      this._resetModalForm();
      this.showNewDivisionForm = false;
    },

    getRandomNewCaseDivisionId() {
      // Note that we give 'id' a value because it helps with Vue list rendering, BUT we make it
      // a negative value, so that the backend knows to toss it out -- it's a new item. - EF 2022-05-31
      return Math.floor(Math.random() * 1000000000) * -1;
    },

    saveCaseDivisions() {
      this.validateModalForm = true;

      if (!this.validateForm()) {
        return;
      }

      this.showNewDivisionForm = false;
      this.showLoadingIndicator = true;

      let thisCaseDivision = {
        'id': this.localCaseDivisionId || this.getRandomNewCaseDivisionId(),
        'case_id': this.caseObject.id,
        'division_name': this.localCaseDivisionName,
        'default_city': this.localCaseDivisionDefaultCity,
        'default_state': this.localCaseDivisionDefaultState,
      };

      if (thisCaseDivision.id < 0) {  // this is a new ID, because the real ones are always positive ints
        this.localCaseDivisions.push(thisCaseDivision);  // adding new
      } else {
        // update in place in the array of case divisions
        if (this.localCaseDivisionListIndex && this.localCaseDivisionId !== this.localCaseDivisions[this.localCaseDivisionListIndex].id) {
          console.error('This should not happen! this.localCaseDivisionId !== this.localCaseDivisions[this.localCaseDivisionListIndex].id', this.localCaseDivisionId, this.localCaseDivisionListIndex, this.localCaseDivisions);
        }
        this.localCaseDivisions[this.localCaseDivisionListIndex] = thisCaseDivision;
      }

      // this emits the change as an event
      this.emitValue();

      this.showLoadingIndicator = false;
    },

    deleteAllCaseDivisions() {
      // if we turned it off, delete existing local case divisions and update parent components
      this.localCaseDivisions = [];
      this.emitValue();
    },

    _resetModalForm() {
      this.validateModalForm = null;

      this.localCaseDivisionListIndex = null;
      this.localCaseDivisionId = this.getRandomNewCaseDivisionId();
      this.localCaseDivisionName = null;
      this.localCaseDivisionDefaultCity = null;
      this.localCaseDivisionDefaultState = null;
    },

    emitValue() {
      console.log("Changing case divisions", this.localCaseDivisions);
      bus.$emit("case-data-changed", {
        group_name: this.caseObject.group_name,
        group_number: this.caseObject.group_number,
        case_divisions: this.localCaseDivisions,
        situs_city: this.caseObject.situs_city,
        situs_state: this.caseObject.situs_state,
        premium_mode: this.caseObject.premium_mode,
      });
    }
  },

  computed: {
    modalTitle() {
      if (this.localCaseDivisionId) {
        return 'Edit Division';
      }
      return 'New Division';
    },
    validateLocalCaseDivisionDefaultCity() {
      return this.validateModalForm ? !!this.localCaseDivisionDefaultCity : null;
    },
    validateLocalCaseDivisionDefaultState() {
      return this.validateModalForm ? !!this.localCaseDivisionDefaultState : null;
    },
    validateLocalCaseDivisionName() {
      return this.validateModalForm ? !!this.localCaseDivisionName : null;
    },
  },

  watch: {
    caseDivisionsSwitchOn(newVal, oldVal) {
      // This watches the state of the checkbox model and emits and update to the parent component.
      // This update will set the property `showCaseDivisionsList` to the boolean !!newVal
      // - EF 2022-06-01
      console.log(`this.$emit('update:showCaseDivisionsList', ${!!newVal});`)
      this.$emit('update:showCaseDivisionsList', !!newVal);

      if (!!newVal === false) {
        this.deleteAllCaseDivisions();
      }
    }
  },

  data() {
    return {
      localCaseDivisions: [],

      localCaseDivisionId: null,
      localCaseDivisionName: null,
      localCaseDivisionDefaultCity: null,
      localCaseDivisionDefaultState: null,

      caseDivisionsSwitchOn: false,

      showNewDivisionForm: false,
      showLoadingIndicator: false,

      validateModalForm: false,
    }
  }
}
</script>

<style scoped>

</style>
