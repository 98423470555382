import enrollmentsTable from './enrollments-table.html'
import sorting from './models/sorting'

import rowsPerPageOptions from "./models/rows-per-page-options";
import getEnrollmentTableFields from "./models/enrollments-table-fields";
import PdfModal from "./PdfModal";

const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;

export default {
  template: enrollmentsTable,
  props: {
    enrollments: {type: Array},
    tableProps: {type: Object}
  },
  components: {
    sorting,
    PdfModal
  },
  data: function () {
    return {
      fields: getEnrollmentTableFields(),
      rowsPerPageOptions: rowsPerPageOptions,

      showPDFModal: false,
      viewingEnrollmentId: null,
    }
  },
  methods: {
    viewPDF(enrollmentId) {
      this.viewingEnrollmentId = enrollmentId;
      this.showPDFModal = true;
    },
    enrollmentClick(record) {
      this.$router.push({name: 'enrollmentDetails', params: {id: record.id}});
    },
    sortChanged(header) {
      if (header.sortBy) {
        this.tableProps.sortBy = header.sortBy;
        this.tableProps.sortDesc = header.sortDesc;
        this.$emit('search-enrollments-listener');
      }
    },
    pageChange(newValue) {
      this.tableProps.currentPage = newValue;
      this.$emit('search-enrollments-listener');
    },
    rowChange(newValue) {
      this.tableProps.rowsPerPage = newValue;
      this.$emit('search-enrollments-listener');
    }
  }
}
