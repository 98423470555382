import profile from './profile.html'
import Api from './api.js'
import colorDefaults from './models/color-defaults'
import bus from "./bus";
import ShowNavigation from "./nav/ShowNavigation";

export default {
  template: profile,
  props: {
    userPermissions: { type: Object },
  },
  components: {ShowNavigation},
  data () {
    return {
      errors: [],
      passwordErrors: [],
      userForm: {},
      currentPassword: null,
      retypePassword: null,
      newPassword: null,
      profileUpdateSuccess: false,
      passwordUpdateSuccess: false,
      colorDefaults: colorDefaults
    }
  },
  methods: {
    updateUser() {
      this.passwordUpdateSuccess = false;
      Api.updateMe(this.userForm)
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.errors = response.errors;
        } else {
          this.errors = [];
          Object.assign(this.userForm, response);
          this.profileUpdateSuccess = true;
        }
      });
    },
    updatePassword() {
      this.passwordUpdateSuccess = false;
      Api.updatePassword({
        current_password: this.currentPassword,
        password: this.newPassword
      })
      .then(response => {
         if (response.errors && response.errors.length > 0) {
          this.passwordErrors = response.errors;
        } else {
          this.passwordErrors = [];
          this.passwordUpdateSuccess = true;
        }
      });
    }
  },
  computed: {
    userCanChangeMe() {
      return this.userPermissions.canChangeMe();
    },
    userCanChangeMyPassword() {
      return this.userPermissions.canChangeMyPassword();
    }
  },
  created() {
    Api.me()
    .then(response => {
      if (!response.errors) {
        this.userForm = response;
      } else {
        this.$router.push('/');
      }
    });
  }
}