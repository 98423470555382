import {terminology} from "../app";

function getFilterTypes() {

  const filterTypes = [
    {
      name: 'filter_case_name',
      displayName: terminology.formatCase({isTitle: true})+' Name',
      type: 'text'
    },
    {
    name: 'filter_case_division_name',
    displayName: 'Division Name',
    type: 'text'
  },
  {
      name: 'filter_applicant_name',
      displayName: 'Applicant Name',
      type: 'text'
    },
    {
      name: 'filter_agent_name',
      displayName: 'Agent Name',
      type: 'text'
    },
    {
      name: 'filter_product_name',
      displayName: 'Product Name',
      type: 'text'
    },
    {
      name: 'filter_case_id',
      displayName: terminology.formatCase({isTitle: true}),
      type: 'id'
    },
    {
      name: 'filter_date',
      displayName: 'Date',
      type: 'date'
    },
    {
      name: 'filter_last_days',
      displayName: 'Last Days',
      type: 'number'
    },
    {
      name: 'filter_status',
      displayName: 'Status',
      type: 'text'
  },
  {
    name: 'filter_transmitted',
    displayName: 'Transmitted',
    type: 'boolean'
    }
  ];
return filterTypes;
}



function getFilterTypesByName() {
  return getFilterTypes().reduce((r, ft) => {
    r[ft.name] = ft;
    return r;
  }, {});
}



class Filter {
  constructor(type, value1, value2) {
    this.type = type;
    this.value = value1;
    this.value2 = value2;
  }

  isValid() {
    return true;
  }
  getValidationError() {
    return "";
  }

  serialize() {
    return {
      type: this.type.name,
      value: this.value,
      value2: this.value2 || undefined
    }
  }

}

class Report {
  constructor({id, name, filters_json, user_id}) {
    this.id = id;
    this.name = name;
    this.user_id = user_id;
    this.filters = this.parseFilters(filters_json || []);
  }

  copy() {
    return new Report({
      id: this.id,
      name: this.name,
      filters_json: this.serializeFilters(),
      user_id: this.user_id,
    })
  }

  parseFilters(json) {
    let filters = [];

    for (let filter of json) {
      if (filter.type === "group") {
        filters = filters.concat(this.parseFilters(filter.children));
      } else {
        filters.push(this.parseFilter(filter));
      }
    }

    return filters;
  }

  parseFilter(json) {
    return new Filter(getFilterTypesByName()[json.type], json.value, json.value2 || undefined);
  }

  serialize() {
    return {
      id: this.id,
      user_id: this.user_id,
      name: this.name,
      filters_json: this.serializeFilters()
    }
  }

  serializeFilters() {
    // Outer "AND" node for all the groups of filters.
    return [{
        type: "group",
        value: "and",
        children: groupFiltersByType(this.filters).map(group => {
          return {
                type: "group",
                value: "or",
                // Each filter in the group is ORed together
                children: group.filters.map(f => f.serialize())
              }
        })
    }];
  }
}


function groupFiltersByType(filters) {
  // Group by filter type.
  let groups = new Map();
  for (let filter of filters) {
    let filterName = filter.type.name;
    if (groups.get(filterName) === undefined) {
      groups.set(filterName, [])
    }
    groups.get(filterName).push(filter);
  }
  // Order by filter type order.
  let orderedGroupedFilters = [];
  for (let ft of getFilterTypes()) {
    if (groups.get(ft.name)) {
      orderedGroupedFilters.push({type: ft.name, filters: groups.get(ft.name)});
    }
  }
  return orderedGroupedFilters;
}


export {
  getFilterTypes,
  getFilterTypesByName,
  groupFiltersByType,
  Report,
  Filter,
};
