export default {
  name: "LoaderComponent",
  props: ['size', 'useWholePage'],
  template: `
<div class="text-center" :class="{
      'row': useWholePage,
      'justify-content-center': useWholePage,
       'align-items-center': useWholePage
       }" :style="wrapperStyle">
  <font-awesome-icon icon="spinner" pulse :size="faSize"></font-awesome-icon>
</div>
  `,
  data: function() {
    let spinnerSize = "";
    if (this.size === "large" || this.useWholePage) {
      spinnerSize = "5x";
    } else {
      spinnerSize = "2x";
    }
    return {
      faSize: spinnerSize
    }
  },
  computed: {
    wrapperStyle() {
      if (this.useWholePage) {
        return {height: '500px'};
      } else {
        return {};
      }
    }
  }
}