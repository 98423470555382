<template>
  <div>
    <div v-for="child in children" :key="child.id">
      <b-row>
        <b-col cols="11">
          <enrollment-field-set
            :applicantContext="child"
            :premiumMode="premiumMode"
            :fields="fields"
            :applicants="applicants"
            :selectedCoverages="selectedCoverages"
            :site-config="siteConfig"
          ></enrollment-field-set>
        </b-col>
        <b-col align-self="center">
            <b-button size="sm" v-show="children.length > 1" @click="removeChild(child)"> <font-awesome-icon icon="times"></font-awesome-icon></b-button>

        </b-col>
      </b-row>

    </div>
    <b-button variant="success" @click="addChild"> <font-awesome-icon icon="plus"></font-awesome-icon> Add Child/Grandchild</b-button>
  </div>
</template>

<script>
import bus from "./messages";
import {ApplicantTypeChild} from "./models";

let childrenEditor = {
  name: "ChildrenEditor",
  props: [
    "applicants",
    "selectedCoverages",
    "fields",
    "premiumMode",
    "siteConfig",
  ],
  data: function() {
    return {}
  },
  created() {
    if (this.children.length === 0) {
      this.addChild();
    }
  },
  methods: {
    addChild() {
      bus.$emit("add-child");
    },
    removeChild(child) {
      bus.$emit("remove-child", child);
    }
  },
  computed: {
    children() {
      return this.applicants.getApplicantsByType(ApplicantTypeChild);
    }
  }
};

export default childrenEditor;
</script>
