<template>
  <b-overlay :show="isLoading">
    <div id="start" v-if="showStart">
      <!-- @TODO TENANT TERMINOLOGY use terminology in Python to set terminology "Enrollment" -->
      <h5>Welcome to your Benefit Enrollment</h5>

      <div class='ml-3'>
        <p>You are being offered valuable insurance benefits through Leaders Life Insurance Company, an A (Excellent)
          A.M. Best Insurer. This brief online interview will present you with eligible options for coverage and, once
          you have selected benefits and provided some basic information, will give you a chance to review your
          submission prior to signing your application(s) here electronically. Any premiums associated with your
          coverage will be displayed in the upper right of your screen and addressed through your employer's prearranged
          payment method.</p>

        <p>If you have any questions, please contact us, your Leaders Life Customer Service Staff, at (800)
          725-5433.</p>

        <p>In order to get started, please enter the city and state <em><u>from which you are currently completing this
          application:</u></em></p>

        <div class="row">
          <div class="col-md-6 col-xs-12">
            <form id="self-enroll-start-form" @submit="onSubmitStart">
              <label>
                <!-- @TODO TENANT TERMINOLOGY use terminology in Python to set terminology "Enrollment" -->
                Enrollment City:
                <input type="text" name="enroll_city" v-model="enroll_city" class="form-control" required/>
              </label>
              <br>
              <label>
                <!-- @TODO TENANT TERMINOLOGY use terminology in Python to set terminology "Enrollment" -->
                Enrollment State:
                <b-select v-model="enroll_state" :options="us_states_options" required></b-select>
              </label>
              <br>
              <!-- @TODO TENANT TERMINOLOGY use terminology in Python to set terminology "Enrollment" -->
              <button class="btn btn-primary" type="submit">Begin Enrollment</button>
            </form>
          </div>
        </div>
      </div>

    </div>
    <Wizard v-if="showWizard"></Wizard>

    <b-alert v-else-if="showError" variant="danger" show>
      Unable to load remote signature wizard. Token may be invalid.
    </b-alert>
  </b-overlay>
</template>

<script>

import Wizard from './WizardComponent';
import Api from "../api";

export default {
  name: 'SelfEnroll',
  components: {
    Wizard
  },
  props: {
    caseSlug: {
      type: String,
      required: false
    },
    tokenUUID: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      showStart: true,
      showWizard: false,
      showError: false,
      errorMessage: '',
      enroll_city: null,
      enroll_state: null,
      default_city: null,
      default_state: null,
      us_states_options: {}
    }
  },
  async created() {
    console.log('SelfEnroll created');

    this.showStart = true;
    this.isLoading = true;

    const response = await Api.validateSelfEnrollToken(
      this.tokenUUID,
      this.caseSlug
    );

    if (response && !response.errors) {
      this.default_city = response.default_city;
      this.default_state = response.default_state;
      this.enroll_city = response.default_city;
      this.enroll_state = response.default_state;
      this.us_states_options = response.us_states_options;
      this.showError = false;
      this.showStart = true;
    } else {
      this.showError = true;
    }

    this.isLoading = false;
  },
  methods: {
    onSubmitStart(event) {
      this.isLoading = true;

      event.preventDefault();

      const selfEnrollData = {
        enroll_city: this.enroll_city,
        enroll_state: this.enroll_state,
        token_uuid: this.tokenUUID,
        case_slug: this.caseSlug
      };

      // POST these to /pages/enroll/self-enrollment-begin
      Api.postSelfEnrollmentSession(selfEnrollData)
        .then(response => {
          if (response && !response.errors) {
            console.debug(response);
            // route to Wizard
            this.showWizard = true;
            this.showStart = false;
          } else {
            this.showStart = true;
            this.showError = true;
            this.errorMessage = response.errors;
          }
          this.isLoading = false;
        })

      console.log('onSubmitStart', selfEnrollData);
    }
  }
}

</script>


<style>

</style>
