<template>
  <div>
    <b-card no-body
            :bg-variant="colorDefaults.card.bgColor"
            :header-text-variant="colorDefaults.card.headerTextColor"
            :header-bg-variant="colorDefaults.card.headerBgColor">

      <h5 slot="header" class="mb-0">
        {{ "case" | terminology({isTitle: true}) }} Setup
      </h5>

      <div v-if="isFinishedLoading">
        <b-tabs pills card vertical>
          <b-tab v-for="tab in case_setup_tabs" :key="tab.title">
            <template slot="title">
              <b-badge v-if="tab.errors.length === 0" variant="success"><i class="fa fa-check"></i></b-badge>
              <b-badge v-if="tab.errors.length !== 0" variant="warning"><i class="fa fa-exclamation-triangle"></i>
              </b-badge>
              &nbsp;<span class="align-middle">{{ tab.title }}</span>
            </template>
            <component
              :is="tab.component"
              :caseObject="caseCopy"
              :premiumModeOptions="premiumModeOptions"
              :statesOptions="statesOptions"
              :selectedProducts="selectedProducts"
              :availableProducts="products"
              :productOptions="productOptions"
              :users="users"
              :userPermissions="userPermissions"
              :tags="tags"
              :caseTags="selectedTags"
              :caseUsers="selectedUsers"
            ></component>
          </b-tab>
        </b-tabs>

      </div>
      <div v-else style="padding: 2em; text-align: center;">
        <loading-spinner></loading-spinner>
      </div>


      <div slot="footer">

        <p v-if="caseErrors.length>0">
          <b>Please correct the following error<span v-if="caseErrors.length>1">s</span>:</b>
        <ul>
          <li class="text-danger" v-for="error in caseErrors">{{ error.message }}</li>
        </ul>
        </p>
        <div class="row justify-content-end">
          <b-btn
            v-if="userCanDeleteCase && caseCopy.deleted === false"
            :disabled="!isFinishedLoading || isCaseSaving"
            size="md"
            class="mr-3"
            variant="outline-danger"
            @click="areYouSure(caseCopy.id)">
            <font-awesome-icon icon="trash"></font-awesome-icon>
            Delete
          </b-btn>
          <b-btn
            v-if="userCanDeleteCase && caseCopy.deleted === true"
            :disabled="!isFinishedLoading || isCaseSaving"
            size="md"
            class="mr-3"
            variant="outline-secondary"
            @click="reactivateCase(caseCopy.id)">
            <font-awesome-icon icon="recycle"></font-awesome-icon>
            Reactivate
          </b-btn>
          <b-btn
            size="md"
            class="mr-3"
            variant="outline-secondary"
            :disabled="!isFinishedLoading || isCaseSaving"
            v-if="shouldShowCloseBtn"
            @click="closeSection">
            <font-awesome-icon icon="times"></font-awesome-icon>
            Close
          </b-btn>
          <b-btn
            v-if="userCanChangeCase"
            size="md"
            class="mr-3"
            variant="success"
            :disabled="!isFinishedLoading || isCaseSaving"
            @click="saveCase()">
            Save
          </b-btn>
        </div>
      </div>
      <b-modal
        :title="'Are you sure you want to delete this '+$root.terminology.formatCase({})+'?'"
        v-model="showAreYouSureModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">

        <b-container>
          Deleting this {{ "case" | terminology({}) }} will delete all {{
            "enrollment" | terminology({isPlural: true})
          }} associated with this {{ "case" | terminology({}) }}.
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn
            size="md"
            class="mr-3"
            variant="outline-secondary"
            @click="showAreYouSureModal=false">
            <i class="fa fa-times"></i> No, return to {{ "enrollment" | terminology({isPlural: true}) }}
          </b-btn>
          <b-btn
            v-if="userCanDeleteCase"
            size="md"
            class="mr-3"
            variant="danger"
            @click="deleteCase(areYouSureCaseId)">
            Yes, delete this {{ "case" | terminology({}) }}
          </b-btn>
        </div>

      </b-modal>


      <b-modal
        title="You have unsaved changes"
        v-model="showUnsavedChangesModal"
        tabindex="-1"
        role="dialog"
        aria-hidden="true">

        <b-container>
          Do you want to save your changes before leaving this page?
        </b-container>

        <div slot="modal-footer" class="row align-self-end">
          <b-btn
            size="md"
            class="mr-3"
            variant="outline-secondary"
            @click="showUnsavedChangesModal=false">
            <i class="fa fa-times"></i> Yes, continue editing
          </b-btn>
          <b-btn
            size="md"
            class="mr-3"
            variant="danger"
            @click="navigateAway">
            No, do not save changes
          </b-btn>
        </div>

      </b-modal>

    </b-card>
  </div>

</template>

<script>
import states from '../models/states'
import Api from "../api";
import colorDefaults from '../models/color-defaults'
import lodash, {debounce, cloneDeep} from 'lodash'
import inputFormattersMixin from '../input-formatters'
import GeneralCaseSettings from "./general_settings";
import ProductCaseSettings from "./product_settings";
import ProductOptionsCaseSettings from "./product_options_case_settings";
import SelfEnrollmentSettings from "./SelfEnrollmentSettings"
import EnrollmentPeriodSettings from "./enrollment_period_settings"
import DeliverySettings from "./delivery_settings"
import AgentSplits from "./agent_splits"
import APISettings from "./api_settings";
import bus from "../bus";
import UserAccessSettings from "./user_access_settings";
import {siteConfig} from "../app";

export default {
  mixins: [inputFormattersMixin],
  props: {
    Case: {type: Object},
    products: {type: Array},
    tags: {type: Array},
    users: {type: Array},
    userPermissions: {type: Object},
    shouldShowCloseBtn: {type: Boolean, default: true}
  },
  components: {
    'general-case-settings': GeneralCaseSettings,
    'product-case-settings': ProductCaseSettings,
    'product-options-case-settings': ProductOptionsCaseSettings,
    'self-enrollment-settings': SelfEnrollmentSettings,
    'enrollment-period-settings': EnrollmentPeriodSettings,
    'user-access-settings': UserAccessSettings,
    'delivery-settings': DeliverySettings,
    'agent-splits': AgentSplits,
    'api-settings': APISettings,
  },
  data: function () {

    const case_setup_tabs = [
      {
        title: 'General',
        is_valid: true,
        errors: [],
        component: 'general-case-settings',
      },
    ];


    if (siteConfig.enableCaseSetupProductSelection) {
      case_setup_tabs.push(
        {
          title: 'Products',
          is_valid: true,
          errors: [],
          component: 'product-case-settings',
        }
      );
    }

    if (siteConfig.enableCaseSetupProductOptions) {
      case_setup_tabs.push(
        {
          title: 'Product Options',
          is_valid: true,
          errors: [],
          component: 'product-options-case-settings',
        }
      );
    }
    if (siteConfig.enableCaseSetupEnrollmentModes) {
      case_setup_tabs.push(
        {
          title: 'Enrollment Modes',
          is_valid: true,
          errors: [],
          component: 'self-enrollment-settings',
        }
      );
    }
    if (siteConfig.enableCaseSetupEnrollmentPeriods) {
      case_setup_tabs.push({
        title: 'Enrollment Period',
        is_valid: true,
        errors: [],
        component: 'enrollment-period-settings',
      });
    }

    case_setup_tabs.push({
      title: 'Access',
      is_valid: true,
      errors: [],
      component: 'user-access-settings',
    });
    case_setup_tabs.push({
      title: 'Delivery',
      is_valid: true,
      errors: [],
      component: 'delivery-settings',
    });
    if (siteConfig.enableCaseSetupAgentSplits) {
      case_setup_tabs.push({
        title: 'Agent Splits',
        is_valid: true,
        errors: [],
        component: 'agent-splits',
      });
    }
    // {
    //   title: 'API Settings',
    //   is_valid: true,
    //   errors: [],
    //   component: 'api-settings',
    // })

    return {
      caseCopy: {},

      next: null,
      colorDefaults: colorDefaults,
      statesOptions: states,
      premiumModeOptions: [
        {value: 'monthly', text: 'Monthly'},
        {value: 'semimonthly', text: 'Semi-monthly'},
        {value: 'biweekly', text: 'Bi-weekly'},
        {value: 'weekly', text: 'Weekly'},
      ],
      caseErrors: [],

      caseGeneralErrors: [],
      caseProductErrors: [],

      isFinishedLoading: false,
      isCaseSaving: false,
      showAreYouSureModal: false,
      showUnsavedChangesModal: false,
      showSavingModal: false,
      areYouSureCaseId: '',
      selectedProducts: [],
      productsCopy: [],
      productOptions: new Map(),
      productOptionsCopy: new Map(),
      showSavingProducts: false,

      selectedTags: [],
      tagsCopy: [],
      selectedUsers: [],
      usersCopy: [],

      case_setup_tabs: case_setup_tabs
    }
  },
  methods: {
    clearErrors() {
      for (let tab of this.case_setup_tabs) {
        tab.errors = [];
      }
      this.caseErrors = [];
    },
    addErrors(errors) {
      for (let err of errors) {
        if (err.panel_name) {
          let tab = this.case_setup_tabs.find(t => t.title === err.panel_name);
          if (tab) {
            tab.errors.push({message: err.message, field_name: err.field_name});
          }
        }
      }
      this.caseErrors = this.caseErrors.concat(errors);
    },

    saveCase() {
      if (this.isCaseSaving) {
        return;
      }

      // Show saving modal while we save the case
      bus.$emit('case-saving-started');
      this.isCaseSaving = true;
      this.updateCase().finally(() => {
        bus.$emit('case-saving-ended');
        this.isCaseSaving = false;
      })
    },

    updateCase() {
      this.clearErrors();

      return Api.updateCase(this.caseCopy)
        .then(response => {
          let isValid = this.handleValidationResponse(response);
          if (isValid) {
            Object.assign(this.Case, response);
            this.caseCopy = Object.assign({}, this.Case);
            this.$emit('update-case-listener');

            let tagPromise = this.updateCaseTags(this.Case.id, this.selectedTags.map(t => t.value));
            let productPromise = this.addProductsToCase();
            let userPromise = this.addUsersToCase();

            return Promise.all([tagPromise, productPromise, userPromise])
              .then(responses => {
                if (responses) {
                  responses.forEach(r => {
                    if (r && r.errors && r.errors.length > 0) {
                      this.addErrors(r.errors);
                    }
                  });
                  if (this.caseErrors.length === 0) {
                    this.closeSection();
                  }
                }
              });

          }
        });
    },
    validate: debounce(function () {

      this.clearErrors();
      if (this.userPermissions.canChangeCase()) {
        Api.validateCaseSettings(this.caseCopy.id, this.caseCopy).then(r => {
          this.handleValidationResponse(r);
        });
      }
      if (this.userPermissions.canManageCaseProducts()) {
        Api.validateProductsForCase(this.caseCopy.id, {products: this.buildCaseProductsSubmission()}).then(r => {
          this.handleValidationResponse(r);
        })
      }
    }, 1000, {trailing: true}),

    handleValidationResponse(response) {

      if (response.errors && response.errors.length > 0) {
        this.addErrors(response.errors);
        return false;
      }
      return true;
    },
    deleteCase(caseId) {
      Api.deleteCase(caseId)
        .then(response => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
          } else {
            this.$emit('delete-case-listener', caseId);
            this.showAreYouSureModal = false;
          }
        });
    },
    async reactivateCase(caseId) {
      await Api.reactivateCase(caseId);
      this.$emit('delete-case-listener', caseId);
    },
    updateCaseTags(caseId, tags) {
      if (this.userPermissions.canUpdateCaseTags()) {
        let data = {tag_ids: tags};
        return Api.updateCaseTags(caseId, data)
          .then(response => {
            // update this so we don't ask to save what we just saved - EF 2022-08-23
            this.tagsCopy = [...this.selectedTags];
            return response;
          });
      }
    },
    addProductsToCase() {
      if (this.userPermissions.canManageCaseProducts()) {
        return Api.addProductsToCase(this.Case.id, {products: this.buildCaseProductsSubmission()})
          .then(response => {
            return this._updateProductOptionsForCase(response);
          });
      }
    },
    buildCaseProductsSubmission() {
      let products = [];
      this.selectedProducts.forEach((p, i) => {
        const productId = p.value;
        products.push({
          id: productId,
          display_sort: i,
          json: this.productOptions.get(productId)
        });
      });
      return products;
    },
    addUsersToCase() {
      let users = [];
      this.selectedUsers.forEach(p => {
        users.push({
          id: p.value,
          restricted_to_own_enrollments: p.restrict
        });
      });
      return Api.addUsersToCase(this.Case.id, {users: users})
        .then(response => {
          // update this so we don't ask to save what we just saved - EF 2022-08-23
          this.usersCopy = [...this.selectedUsers];
          return response;
        });
    },
    mapMultiSelect(response) {
      return response.map(r => {
        return {value: r.id, text: r.name}
      });
    },

    areYouSure(caseId) {
      this.showAreYouSureModal = true;
      this.areYouSureCaseId = caseId;
    },
    getCaseTags() {
      return Api.getTagsByCaseId(this.Case.id)
        .then(response => {
          this.selectedTags = this.mapMultiSelect(response);
          this.tagsCopy = [...this.selectedTags];
        });
    },
    getProductsForCase() {
      return Api.getProductsForCase(this.Case.id)
        .then(response => {
          return this._updateProductOptionsForCase(response);
        })
    },
    _updateProductOptionsForCase(response) {
      this.selectedProducts = response.map(r => {
        return {
          value: r.id,
          text: r.name,
          sort: r.display_sort,
          json: r.json || {},
        }
      });
      this.selectedProducts.sort((a, b) => a.display_sort - b.display_sort);
      this.productsCopy = cloneDeep(this.selectedProducts);

      // Also update the productOptions to track changes separately
      this.productOptions.clear();
      this.selectedProducts.forEach(p => {
        this.productOptions.set(p.value, p.json);
      });

      // Create a fresh copy of product options to track changes.
      this.productOptionsCopy = new Map();
      this.selectedProducts.forEach(p => {
        // Must deep clone the json object so we get distinct js values in the copy for comparison.
        this.productOptionsCopy.set(p.value, cloneDeep(p.json));
      })
      return response;
    },
    getUsersForCase() {
      return Api.getUsersForCase(this.Case.id)
        .then(response => {
          this.selectedUsers = response.map(r => {
            return {
              value: r.id,
              text: (r.first_name + ' ' + r.last_name),
              restrict: r.restricted_to_own_enrollments,
              isApiUser: r.is_api_user,
            }
          });
          this.usersCopy = [...this.selectedUsers];
        });
    },

    resetAttributes() {
      // caseCopy is the object bound to for changes
      this.caseCopy = Object.assign({}, this.Case);

      // These are opposite of caseCopy: the copies are just for resetting on cancel.
      this.selectedProducts = [...this.productsCopy];
      this.selectedTags = [...this.tagsCopy];
      this.selectedUsers = [...this.usersCopy];
      this.productOptionsCopy = new Map();
      this.selectedProducts.forEach(p => {
        // Must deep clone the json object so we get distinct js values in the copy for comparison.
        this.productOptionsCopy.set(p.value, cloneDeep(p.json));
      })
    },
    closeSection() {
      if (this.checkUserChanges()) {
        this.showUnsavedChangesModal = true;
        this.next = this._close_section;
      } else {
        this._close_section();
      }
    },
    _close_section() {
      this.resetAttributes();
      this.$emit('close-section-listener');
    },
    navigateAway() {
      this.resetAttributes();
      this.next();
    },
    checkUserChanges() {
      // Check Case-level differences.
      let differences = objectDiff(this.caseCopy, this.Case);

      // Check list of products differences.
      let productsEqual = isArrayEqual(this.productsCopy.map(p => p.value), this.selectedProducts.map(p => p.value));

      // Check product options differences.
      let productOptionsEqual = true;
      this.selectedProducts.forEach(p => {
        let oldProductOptions = this.productOptionsCopy.get(p.value);
        let newProductOptions = cloneDeep(this.productOptions.get(p.value)); // cloned to remove reactivity on properties
        // If the product was just added or removed, it might not have an options object.
        if (oldProductOptions !== undefined && newProductOptions !== undefined) {
          let diffs = objectDiff(oldProductOptions, newProductOptions);
          if (diffs.length > 0) {
            productOptionsEqual = false;
          }
        } else if (oldProductOptions === undefined || newProductOptions === undefined) {
          productOptionsEqual = false;
        }
      });

      // Check tags and users differences.
      let tagsEqual = isArrayEqual(this.tagsCopy.map(t => t.value), this.selectedTags.map(t => t.value));
      let usersEqual = isArrayEqual(this.usersCopy.map(u => u.value), this.selectedUsers.map(u => u.value));

      //console.debug("CASE DIFFERENCES: ", differences, "PRODUCTS: ", productsEqual, "TAGS", tagsEqual, "USERS", usersEqual, "PRODUCT OPTIONS", productOptionsEqual);
      return differences.length > 0 || !productsEqual || !tagsEqual || !usersEqual || !productOptionsEqual;
    },
    initCaseSection() {
      this.caseErrors = [];

      this.caseCopy = Object.assign({}, this.Case);

      let tagPromise = this.getCaseTags();
      let productPromise = this.getProductsForCase();
      let userPromise = this.getUsersForCase();

      Promise.all([tagPromise, productPromise, userPromise])
        .then(() => {
          this.validate();
        }).finally(() => {
        this.isFinishedLoading = true;
      });
    },

    userCanAssignUserToCase() {
      return this.userPermissions.canManageCaseUsers();
    },

    userCanCreateTag() {
      return this.userPermissions.canCreateTag();
    },
    userCanDeleteCase() {
      return this.userPermissions.canDeleteCase();
    },

    userCanSetCaseTags() {
      return this.userPermissions.canSetCaseTags();
    },
    userCanChangeCase() {
      return this.userPermissions.canChangeCase();
    },
    userCanManageProductsForCase() {
      return this.userPermissions.canManageCaseProducts();
    }
  },
  computed: {},
  watch: {},
  beforeRouteLeave(to, from, next) {

  },
  destroyed() {
    // Make sure to remove DOM and Vue listeners when the component goes away.
    window.removeEventListener('beforeunload', this.handleLeaveWindow);
    this.unregisterRouterHook();
  },
  created() {
    this.isFinishedLoading = false;

    this.initCaseSection();

    this.unregisterRouterHook = this.$router.beforeEach((to, from, next) => {

      try {
        let isThereUnsavedChanges = this.checkUserChanges();

        if (isThereUnsavedChanges) {
          this.showUnsavedChangesModal = false;

          this.$nextTick(() => {
            this.next = next;
            this.showUnsavedChangesModal = true;
          })
          next(false);
        } else {
          this.showUnsavedChangesModal = false;
          next();
        }
      } catch (e) {
        console.error(e);
        next();
      }
    });

    this.handleLeaveWindow = (e) => {

      let isThereUnsavedChanges = this.checkUserChanges();

      if (isThereUnsavedChanges) {
        let confirmationMessage = 'Non-empty string to show default message';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
      }
    }
    window.addEventListener('beforeunload', this.handleLeaveWindow);

    // Respond to change events from sub-components.
    bus.$on("case-data-changed", data => {
      Object.assign(this.caseCopy, data);
      this.validate();
    });
    bus.$on("case-products-changed", products => {
      this.selectedProducts = products;
      this.validate();
    });
    bus.$on("case-product-options-changed", data => {
      this.productOptions.set(data.product.value, data.options);
      this.validate();
    });
    bus.$on("case-tags-changed", tags => {
      this.selectedTags = tags;
    });
    bus.$on("case-users-changed", users => {
      this.selectedUsers = users;
    })
  }
}

function isArrayEqual(oldArray, newArray) {
  if (oldArray.length !== newArray.length) {
    return false;
  }

  return oldArray.every(function (value) {
    return newArray.indexOf(value) > -1;
  });
}

function objectDiff(oldObj, newObj, specificCheck, isOnlyChange) {
  let diff = [];
  let hasChanged = false;
  let isOnlyChangeCheck = false;

  for (let p in newObj) {
    if (!lodash.isEqual(newObj[p], oldObj[p])) diff.push(p);
  }

  hasChanged = (diff.indexOf(specificCheck) > -1);
  isOnlyChangeCheck = (hasChanged && diff.length === 1);

  if (specificCheck) {
    diff = hasChanged;
    if (isOnlyChange) {
      diff = isOnlyChangeCheck;
    }
  }

  return diff;
}

</script>

<style scoped>

</style>
