import {Applicant, ApplicantTypeEmployee} from "./models";

class WizardStepData {
  constructor(data) {
    this.data = data;
  }

  getApplicantContext(applicants) {
    if (this.data.contextType === 'applicant' && this.data.applicantContextType) {
      let app = applicants.getFirstApplicantByType(this.data.applicantContextType);
      if (app === undefined) {
        app = new Applicant({type: this.data.applicantContextType});

        // Default new applicant last name to Employee last name
        let emp_last_name = applicants.getFirstApplicantByType(ApplicantTypeEmployee).getLastName();
        if (emp_last_name) {
          app.updateData("last_name", emp_last_name);
        }
        applicants.addApplicant(app);
      }
      return app;

    } else if (this.data.contextType === 'applicant' && this.data.applicantId >= 0) {
      return applicants.getApplicantById(this.data.applicantId);
    }
    return null
  }

  getCoverageContext(coverages) {
    if (this.data.contextType === 'coverage') {
      // FIXME zach 2/4/22 This invocation is wrong, it clearly never gets here
      //  there have not been any steps with a single coverage context so far, usually the context is
      //  given directly in the data or it is an applicant context step.
      return coverages.getOrCreateCoverageForApplicantProduct(this.data.coverageProduct);
    }
  }
}

export default WizardStepData;
