<template>
  <div>
    <transition name="slideDown" mode="out-in">
      <enrollment-field-set
            :class="(!isShowing) ? 'mb-0' : 'mb-3'"
            v-if="isShowing"
            :applicantContext="applicantContext"
            :coverageContext="coverageContext"
            :coverageOptions="coverageOptions"
            :fields="fields"
            :applicants="applicants"
            :products="products"
            :selectedCoverages="selectedCoverages"
            :premiumMode="premiumMode"
            :globalBeneficiaries="globalBeneficiaries"
            :suppressMargin="suppressMargin"
            :site-config="siteConfig"
          ></enrollment-field-set>
      </transition>
  </div>
</template>

<script>
import bus from "./messages";

let ConditionalFieldSet = {
  name: "ConditionalFieldSet",
  props: [
    // Take all the FieldSet props and pass them along
    "applicants",
    "products",
    "selectedCoverages",
    "applicantContext",
    "coverageContext",
    "coverageOptions",
    "premiumMode",
    // Conditional name, or list of {name: x, value:x} pairs for AND condition.
    "field_name",
    // Option to only show when false
    "show_if_false",
    // Option to show only when equal to a specific value
    "show_if_value",
    // Sub-fields to show when condition is met.
    "fields",
    "globalBeneficiaries",
    "suppressMargin",
    "siteConfig"
  ],
  data: function() {
    return {
      isShowing: false
    }
  },
  created() {
    // Listen on the bus for changes to our conditional data.
    if (this.applicantContext) {
      this.subscribeToEvent("applicant-data-changed", this.applicantDataChanged)
      this.refresh(this.applicantContext.data)
    } else if (this.coverageContext) {
      this.subscribeToEvent("coverage-data-changed", this.coverageDataChanged)
      this.refresh(this.coverageContext.data)
    }
  },
  methods: {
    subscribeToEvent(eventName, listener) {
      bus.$on(eventName, listener);
      // Stop listening before component destruction.
      this.$once("hook:beforeDestroy", () => {
         bus.$off(eventName, listener);
      });
    },

    applicantDataChanged({applicant, name, value}) {
      if (this.applicantContext === applicant && this.isConditionalOnFieldName(name)) {
        this.refresh(this.applicantContext.data);
      }
    },
    coverageDataChanged({coverage, name, value}) {

      if (this.coverageContext === coverage && this.isConditionalOnFieldName(name)) {
          this.refresh(this.coverageContext.data);
      }
    },

    refresh(contextData) {
      if (this.isConditionalOnMultipleFields()) {
        this.isShowing = this.field_name.every(({name, value}) => {
          return this.getCleanValue(contextData[name]) === value;
        });
      } else {
        this.isShowing = this.shouldShowForValue(contextData[this.field_name]);
      }
    },

    isConditionalOnMultipleFields() {
      return Array.isArray(this.field_name);
    },

    isConditionalOnFieldName(name) {
      if (this.isConditionalOnMultipleFields()) {
        return this.field_name.filter(n => n['name'] === name).length > 0;
      } else {
        return this.field_name === name;
      }
    },

    shouldShowForValue(v) {
      const value = this.getCleanValue(v);

      if (this.show_if_value) {
        return (value === this.show_if_value);
      } else if (this.isTruthy(value) && !this.show_if_false) {
        return true;
      } else if (this.isFalsey(value) && this.show_if_false) {
        return true;
      } else {
        return false;
      }
    },

    getCleanValue(v) {
      // Check for nested EofI answer object
      if (v && v.answer !== undefined) {
        return v.answer
      } else {
        return v;
      }
    },

    isTruthy(value) {
      return value === true || value === "yes";
    },
    isFalsey(value) {
      return value ===false || value === "no";
    }
  },
  computed: {

  }
};

export default ConditionalFieldSet;
</script>

<style>

</style>
