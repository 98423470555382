const POLL_DELAY = 3000;

const STATUS_INITIALIZING = 'initializing';
const STATUS_CALLING_AGENT = 'calling_agent';
const STATUS_WAITING_AGENT = 'waiting_agent';
const STATUS_CALLING_APPLICANT = 'calling_applicant';
const STATUS_WAITING_APPLICANT = 'waiting_applicant';
const STATUS_IN_PROGRESS = 'in_progress';
const STATUS_ERROR = 'error';
const STATUS_ENROLLMENT_SESSION_COMPLETED = 'enrollment_session_completed';
const STATUS_ENDED = 'ended';
const STATUS_ENDED_UNEXPECTEDLY = 'ended_unexpectedly';

// This maps server-reported statuses to user-friendly labels, and
// the bootstrap color variant that should apply to this label.
let conferenceStatusMap = {};
conferenceStatusMap[STATUS_INITIALIZING] = {'label': 'Starting up', 'variant': 'info'};
conferenceStatusMap[STATUS_CALLING_AGENT] = {'label': 'Calling Agent', 'variant': 'info'};
conferenceStatusMap[STATUS_WAITING_AGENT] = {'label': 'Waiting for Agent to join', 'variant': 'info'};
conferenceStatusMap[STATUS_CALLING_APPLICANT] = {'label': 'Calling Applicant', 'variant': 'info'};
conferenceStatusMap[STATUS_WAITING_APPLICANT] = {'label': 'Waiting for Applicant to join', 'variant': 'info'};
conferenceStatusMap[STATUS_IN_PROGRESS] = {'label': 'Conference in-progress', 'variant': 'primary'};
conferenceStatusMap[STATUS_ERROR] = {'label': 'Conference completed.', 'variant': 'success'};
conferenceStatusMap[STATUS_ENROLLMENT_SESSION_COMPLETED] = {'label': 'Conference has ended unexpectedly', 'variant': 'danger'};
conferenceStatusMap[STATUS_ENDED] = {'label': 'Conference has ended.', 'variant': 'success'};


const helpers = {

  label(status) {
    return conferenceStatusMap[status] ? conferenceStatusMap[status].label : null;
  },

  variant(status) {
    return conferenceStatusMap[status] ? conferenceStatusMap[status].variant : null;
  },

  isConferenceReadyToStart(status) {
    return !this.isConferenceStarting(status) && !this.isConferenceInProgress(status);
  },

  isConferenceStarting(status) {
    return Boolean(status) &&
      status !== STATUS_INITIALIZING &&
      status !== STATUS_IN_PROGRESS &&
      status !== STATUS_ENDED &&
      status !== STATUS_ENDED_UNEXPECTEDLY;
  },

  isConferenceInitializing(status) {
    return Boolean(status) &&
      status === STATUS_INITIALIZING;
  },

  isConferenceInProgress(status) {
    return Boolean(status) &&
      status === STATUS_IN_PROGRESS;
  },

  isConferenceEnded(status) {
    return Boolean(status) &&
      (status === STATUS_ENDED || status === STATUS_ENDED_UNEXPECTEDLY);
  },

  isConferenceEndedUnexpectedly(status) {
    return Boolean(status) &&
      status === STATUS_ENDED_UNEXPECTEDLY;
  }

}


export default {
  STATUS_INITIALIZING,
  STATUS_CALLING_AGENT,
  STATUS_WAITING_AGENT,
  STATUS_CALLING_APPLICANT,
  STATUS_WAITING_APPLICANT,
  STATUS_IN_PROGRESS,
  STATUS_ERROR,
  STATUS_ENROLLMENT_SESSION_COMPLETED,
  STATUS_ENDED,
  STATUS_ENDED_UNEXPECTEDLY,
  conferenceStatusMap,
  POLL_DELAY,
  helpers
};
