import { render, staticRenderFns } from "./case-filters.vue?vue&type=template&id=298ad672&scoped=true&"
import script from "./case-filters.vue?vue&type=script&lang=js&"
export * from "./case-filters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298ad672",
  null
  
)

export default component.exports