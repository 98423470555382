<template>
    <div>
        <b-card
            :bg-variant="colorDefaults.card.bgColor"
            :header-text-variant="colorDefaults.card.headerTextColor"
            :header-bg-variant="colorDefaults.card.headerBgColor"
        >
            <h5 slot="header" class="mb-0">
                Assign Tags to {{ 'case' | terminology({isTitle: true, isPlural: true}) }}
            </h5>
            <b-input-group class="mb-4">
                <multiselect
                    :disabled="isLoading"
                    v-model="selectedTags"
                    :options="systemTags"
                    label="name"
                    track-by="id"
                    :multiple="true"
                    :hide-selected="true"
                    :taggable="false"
                    :close-on-select="true"
                    :show-labels="false"
                    :limit="3"
                    placeholder="Search and select tags to assign">
                </multiselect>
            </b-input-group>

            <div slot="footer">

                <b-collapse class="col-12 mt-4" :visible="showErrors" id="collapseManageTagErrors">
                <b>Please correct the following error<span v-if="errors.length>1">s</span>:</b>
                <ul>
                    <li class="text-danger" v-for="error in errors">{{ error.message }}</li>
                </ul>
            </b-collapse>

                <div class="row justify-content-end">
                    <b-button
                        size="md"
                        class="mr-3"
                        variant="outline-secondary"
                        :disabled="isLoading"
                        @click="closeCasesAssignTags">
                        <font-awesome-icon icon="times"></font-awesome-icon>
                        Close
                    </b-button>
                    <b-button class="mr-3" :disabled="isLoading" variant="danger" @click="handleClickRemoveTags()">
                        <b-spinner v-show="isLoading" small label="Loading..."></b-spinner>
                        Remove Tags from Selected {{ 'case' | terminology({isTitle: true, isPlural: true}) }}
                    </b-button>
                    <b-button :disabled="isLoading" variant="success" @click="handleClickAddTags()">
                        <b-spinner v-show="isLoading" small label="Loading..."></b-spinner>
                        Add Tags to Selected {{ 'case' | terminology({isTitle: true, isPlural: true}) }}
                    </b-button>

                </div>
            </div>

        </b-card>
    </div>

</template>

<script>
import colorDefaults from "../models/color-defaults";
import Api from "../api";
import bus from "../bus";
import {terminology} from "../app"
import emit from 'vue'

export default {
    name: 'cases-assign-tags',
    props: {
      selectedCaseIds: {type: Array, required: true}
    },
    data() {
        return {
            systemTags: [],
            selectedTags: [],
            allCasesSelected: false,
            allCasesIndeterminate: false,
            colorDefaults: colorDefaults,
            systemCases: [],
            errors: [],
            isLoading: false,
        }
    },
    methods: {
        getTags() {
            Api.searchTags()
                .then(response => {
                    // sort the tags by name
                    response.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this.systemTags = response;
                })
                .catch(err => {
                    alert('There was a problem retrieving Tags from the server.');
                });
        },
        handleClickAddTags() {
            this.clearErrors();

            if (this.selectedTags.length < 1) {
                this.errors.push({message: "You must select one or more tags."});
            }
            if (this.selectedCaseIds.length < 1) {
                this.errors.push({message: "You must select one or more "+ terminology.formatCase({isPlural: true})+"."});
            }

            if (this.errors.length > 0) {
                return;
            }

            this.showLoading(true);

            let caseTagPromises = [];
            for (const selectedCaseId of this.selectedCaseIds) {
                let p = Api.updateCaseTags(selectedCaseId, {
                  tag_ids: this.selectedTags.map(t => t.id),
                  overwrite: false
                });
                caseTagPromises.push(p);
            }

            Promise.all(caseTagPromises)
                .then(responses => {
                    responses.forEach(response => {
                        if (response.errors && response.errors.length > 0) {
                            this.errors = this.errors.concat(response.errors);
                        }
                    });

                    if (this.errors.length === 0) {
                        this.getTags();
                        this.endEditing();
                        this.clearErrors();
                        this.closeCasesAssignTags();
                        this.$emit('cases-tags-updated');
                    }
                }).finally(() => {
                    this.showLoading(false);
                });
        },
        handleClickRemoveTags() {
            this.clearErrors();

            if (this.selectedTags.length < 1) {
                this.errors.push({message: "You must select one or more tags."});
            }
            if (this.selectedCaseIds.length < 1) {
                this.errors.push({message: "You must select one or more "+terminology.formatCase({isPlural: true})+"."});
            }

            if (this.errors.length > 0) {
                return;
            }

            this.showLoading(true);

            let caseTagPromises = [];
            for (const selectedCaseId of this.selectedCaseIds) {
                let p = Api.removeCaseTags(selectedCaseId, {
                  tag_ids: this.selectedTags.map(t => t.id),
                });
                caseTagPromises.push(p);
            }

            Promise.all(caseTagPromises)
                .then(responses => {
                    responses.forEach(response => {
                        if (response.errors && response.errors.length > 0) {
                            this.errors = this.errors.concat(response.errors);
                        }
                    });

                    if (this.errors.length === 0) {
                        this.getTags();
                        this.endEditing();
                        this.clearErrors();
                        this.closeCasesAssignTags();
                        this.$emit('cases-tags-updated');
                    }
                }).finally(() => {
                    this.showLoading(false);
                });
        },
        showLoading: function (state) {
            this.$emit('update:showLoading', state);
            this.isLoading = state;
        },
        endEditing() {
            this.selectedTags = [];
            bus.$emit('deselect-all-cases');  // this is listened for by cases-table.vue
            this.$emit('selected-cases-changed', []);  // tell the parent
        },
        clearErrors() {
            this.errors = [];
        },
        closeCasesAssignTags() {
            this.$emit("close-cases-assign-tags");
        },
    },
    computed: {
        showErrors: function () {
            return this.errors && this.errors.length > 0;
        },
    },
    created() {
        this.getTags();
    }
}
</script>

<style scoped>

</style>
