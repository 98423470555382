import moment from "../vendor/moment-timezone-with-data-1970-2030.js";
import {terminology} from "../app";

const numberWithCommas = (x, minDecimals=2, maxDecimals=2) => {
  if (!x) {
    return '';
  }
  // multiply by 100, do rounding, then divide by 100 to avoid float errors with currency.
  x = ((parseFloat(x) * 100) / 100).toLocaleString(undefined, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  });

  return x
};


const currency = (x, minDecimals=2, maxDecimals=2) => {
  if (!x) {
    return '';
  }
  // multiply by 100, do rounding, then divide by 100 to avoid float errors for currency display
  // we pass `undefined` for locales because we just want to fall back to the browser locale
  x = (Math.round(parseFloat(x) * 100) / 100).toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals
  });

  return x
};


const durationFromSeconds = (totalSeconds=0) => {
  totalSeconds = parseInt(totalSeconds, 10);
  const minutes = Math.floor(totalSeconds / 60)
  const seconds =  totalSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} min`;
}

const browserTimezone = () => {
  try {
    return new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2];
  } catch (e) {
    return '';
  }
}

// @TODO maybe get this from the server? - EF 2022-11-18
const serverTimezone = () => {
  return process.env.VUE_APP_TZ || 'NOT/SET';
}

export default {
  boolToYesNo(bool_val, true_str='yes', false_str='no') {
    if (!!bool_val) {
      return true_str;
    }
    return false_str
  },
  dateToMoment(dateString) {
    if(dateString) {
      return moment(dateString);
    }
  },
  snakeToTitleCase(snake_str) {
    return snakeToTitleCase(snake_str);
  },
  utcToDateTime(dateString, if_falsy = '') {
    if(dateString) {
      return moment.tz(dateString, serverTimezone()).format('YYYY-MM-DD HH:mm z');
    } else {
      return if_falsy;
    }
  },
  localToDateTime(dateString, if_falsy = '') {
    if(dateString) {
      return moment(dateString).format('YYYY-MM-DD HH:mm');
    } else {
      return if_falsy;
    }
  },
  utcToLocalDateTime(dateString, if_falsy = '') {
    if(dateString) {
      return moment.utc(dateString)
          .tz(serverTimezone())  // convert to local time
          .format("YYYY-MM-DD HH:mm z");
    } else {
      return if_falsy;
    }
  },
  utcToIso(dateString) {
    if (dateString) {
      return moment(dateString).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      return '';
    }
  },
  dateToUtc(date) {
    if(date) {
      return moment(date).format('YYYY-MM-DD');
    }
  },
  coverage(amount) {
    if(amount) {
      if(amount.indexOf('.') > -1) {
        amount = amount.substring(0, amount.indexOf('.'));
      }
      amount = currency(amount, 0, 2);
    }
    return amount;
  },
  premium(amount) {
    if(amount) {
      amount = currency(amount);
    }
    return amount;
  },
  percent(fraction) {
    return (100.0 * fraction).toFixed(0) + '%';
  },
  enrollmentStatus(status) {
    if(status) {
      // Terminology translation
      status = terminology.formatEnrollmentStatus(status);
    }

    if(status) {
      status = snakeToTitleCase(status);
    }

    return status;
  },
  alphebetize(array, prop) {
    return array.sort((a, b) => a[prop].localeCompare(b[prop]));
  },
  numberWithCommas,
  currency,
  durationFromSeconds,
  browserTimezone,
  serverTimezone,
}

const capitalize = (x) => {
  return `${x[0].toUpperCase()}${x.slice(1)}`;
};

const snakeToTitleCase = (snake_str) => {
  if (!snake_str) {
    return '';
  }

  return snake_str.split('_').map((str_piece) => {
    return str_piece.charAt(0).toUpperCase() + str_piece.substring(1);
  }).join(" ")
};
