<template>
  <div>

    <div v-if="selectedProducts.length === 0">
      No products added to case yet.
    </div>
    <div v-else>
      <b-form-select
        v-model="selectedProductId"
        :options="selectedProducts"
        text-field="text"
      ></b-form-select>

      <div v-if="selectedProduct !== null && selectedProduct !== undefined">
        <product-options
          class="mt-3"
          v-if="selectedProduct !== null"
          :product="selectedProduct"
          :productOptions="productOptions"
        ></product-options>
      </div>
    </div>


  </div>
</template>

<script>
import bus from "../bus";
import ProductOptions from "./product-options";

let ProductOptionsCaseSettings = {
  name: "ProductOptionsCaseSettings",
  components: {
    'product-options': ProductOptions,
  },
  props: [
    "caseObject",
    "selectedProducts",
    "productOptions",
  ],
  data: function () {
    return {
      selectedProductId: (this.selectedProducts.length > 0) ? this.selectedProducts[0].value : null
    }
  },
  methods: {},
  computed: {
    selectedProduct() {
      if (this.selectedProductId === null) {
        return null;
      } else {
        return this.selectedProducts.find(p => p.value === this.selectedProductId);
      }
    }
  },
  watch: {
    // React to prop change
    selectedProducts(val) {
      if (this.selectedProductId === null && val.length > 0) {
        this.selectedProductId = val[0].value;
      }
      if (val.find(p => p.value === this.selectedProductId) === undefined && val.length > 0) {
        this.selectedProductId = val[0].value;
      }
    }
  },
};

export default ProductOptionsCaseSettings;


</script>

<style scoped>

</style>
