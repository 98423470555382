import census_template from './manage-census.html'
import colorDefaults from '../models/color-defaults'
import Api from "../api";
import bus from "../bus";

export default {
  name: 'ManageCensus',
  template: census_template,
  props: [
    'Case',
    'userPermissions'
  ],
  data: function() {
    return {
      isUploading: false,
      isDeleting: false,
      showUploadingModal: false,
      colorDefaults: colorDefaults,
      censusUploadErrors: [],
      censusErrorFile: null,
      showAreYouSureModal: false,
      didClickUpload: false,
      file: null,
      deleteErrors: [],
    }
  },
  created() {
    
  },
  methods: {
    async uploadCensusFile() {
      this.didClickUpload = true;

      // Validate that a file was selected first
      if (this.file === null) {
        return;
      }

      this.showUploadingModal = true;
      this.isUploading = true;
      this.censusUploadErrors = [];
      this.censusErrorFile = null;

      try {
        const resp = await Api.uploadCensusFile(this.Case.id, this.file);
        if (resp.errors && resp.errors.length > 0) {
          if (resp.error_file && resp.error_file.length > 0) {
            this.censusErrorFile = resp.error_file;
          } else {
            this.censusUploadErrors = resp.errors;
          }
        } else {
          this.statusMessage = "Census file upload was successful";
          bus.$emit('new-census-uploaded');
        }
      } catch (err_resp) {
        console.error(err_resp);
        this.censusUploadErrors.push({message: "The server had an unexpected error"});
      } finally {
        this.isUploading = false;
      }

    },
    closeSuccess() {
      this.showUploadingModal = false;
      this.$emit('close-manage-census');
    },
    handleCensusRemove() {
      this.showAreYouSureModal = true;
    },
    async deleteCensus() {
      try {
        this.isDeleting = true;
        this.deleteErrors = [];

        let resp = await Api.deleteCensus(this.Case.id);
        if (resp.errors && resp.errors.length > 0) {
          this.deleteErrors = resp.errors;
        } else {
          this.showAreYouSureModal = false;
          // Reload the enrollments table
          bus.$emit('new-census-uploaded');
        }
      } catch (err) {
        console.error(err);
        this.deleteErrors.push({message: "The server had an unexpected error"});
      } finally {
        this.isDeleting = false;
      }
    },
    closeSection() {
      this.$emit("close-manage-census");
    },
    userCanDeleteCensus() {
      return this.userPermissions.canManageCensus();
    }
  },
  computed: {
    fileState() {
      if (!this.didClickUpload) {
        return null;
      } else if (Boolean(this.file)) {
        return true;
      } else {
        return false;
      }
    },
    errorFileHref() {
      if (this.censusErrorFile === null) {
        return '';
      }
      return 'data:text/csv;base64,'+this.censusErrorFile;
    },
    censusErrorFileName() {
      if (!this.file) {
        return '';
      }
      let filename = this.file.name.replace(/\.csv/, '');
      return `${filename}_errors.csv`;
    }
  }
}