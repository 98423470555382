export default class UserPermissions {
  constructor() {
    this.user = null;
    // Keep the code below simple by managing these two lists
    this.permissions = [];
    this.roles = [];
  }

  setUser(user) {
    this.user = user;
    this.permissions = user.permissions;
    this.roles = user.roles;
  }

  getUserId() {
    return (this.user) ? this.user.id : null;
  }

  clearUser() {
    this.user = null;
    this.permissions = [];
    this.roles = [];
  }

  getFirstName() {
    return (this.user !== null) ? this.user.first_name : "";
  }

  getLastName() {
    return (this.user !== null) ? this.user.last_name : "";
  }

  canEnroll() {
    return this.permissions.includes('create_enrollment');
  }

  canCreateUser() {
    return this.permissions.includes('create_user');
  }

  canChangeUser() {
    return this.permissions.includes('change_user');
  }

  canSendUserInvite() {
    return this.permissions.includes('send_user_invitation_email');
  }
  canSendUserResetPasswordEmail() {
    return this.permissions.includes('send_user_reset_password_email');
  }

  canCreateCase() {
    return this.permissions.includes('create_case');
  }

  canChangeCase() {
    return this.permissions.includes('change_case');
  }

  canUpdateCaseTags() {
    return this.permissions.includes('set_case_tags');
  }

  canManageCaseProducts() {
    return this.permissions.includes('manage_products_for_case');
  }

  canManageCaseUsers() {
    return this.permissions.includes('assign_user_to_case');
  }

  canViewTaggedUsers() {
    return this.permissions.includes('view_tagged_users');
  }

  canCreateTag() {
    return this.permissions.includes('create_tag');
  }

  canSetCaseTags() {
    return this.permissions.includes('set_case_tags');
  }

  canDeleteCase() {
    return this.permissions.includes('delete_case');
  }

  canManageCensus() {
    return this.permissions.includes('manage_census');
  }

  canManageUsersAndProducts() {
    return (
      this.canManageCaseProducts()
      && this.canManageCaseUsers()
    );
  }

  canAccessManageUsers() {
    return this.permissions.includes('access_manage_users_page');
  }

  canAccessEnrollments() {
    return this.permissions.includes('access_enrollments_page');
  }

  canAccessCases() {
    return this.permissions.includes('access_cases_page');
  }

  canDeleteEnrollment() {
    return this.permissions.includes('delete_enrollment');
  }

  canAccessProfile() {
    return this.permissions.includes('access_profile_page');
  };

  canAccessReports() {
    return this.permissions.includes('access_reports_page');
  };

  canAccessDeliveries() {
    return this.permissions.includes('view_enrollment_deliveries');
  };

  canRequestRedelivery() {
    return this.permissions.includes('add_enrollment_deliveries');
  };

  canSetUserRole() {
    return this.permissions.includes('set_user_role');
  }
  canSetUserTags() {
    return this.permissions.includes('set_user_tags');
  }

  canSetUserRoleToHomeOffice() {
    return this.permissions.includes('set_user_role_to_homeofficeadmin');
  }

  canChangeMe() {
    return this.permissions.includes('change_me');
  }

  canChangeMyPassword() {
    return this.permissions.includes('change_my_password');
  }

  canViewDeclinedWaivedEnrollments() {
    return this.permissions.includes('view_declined_waived_enrollments');
  }

  // Use role queries sparingly
  isHomeOfficeAdmin() {
    return this.roles.find(r => r.name === 'HomeOfficeAdmin');
  }


}
