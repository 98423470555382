import forgotPassword from './forgot-password.html'
import Api from "./api.js";

export default {
    template: forgotPassword,
    data () {
        return {
            errors:[],
            emailValue:'',
            emailSentSuccess: false
        }
    },
    methods: {
        doForgotPassword(e) {
          e.preventDefault();
          Api.forgotPassword({
            username: this.emailValue
          })
          .then(response => {
            if (response.errors && response.errors.length > 0) {
              this.errors = response.errors;
            } else {
              this.emailSentSuccess = true;
            }
          });
        }
    }
}