import Api from "../api";
import {sortBy} from "lodash"

export async function getProductOptions() {
  const productsResponse = await Api.searchProducts()
  // sort by name and map for multiselect.
  return sortBy(productsResponse, ['name']).map(r => {
    return {value: r.id, text: r.name}
  });
}

export async function getTagOptions() {
  const tagsResponse = await Api.searchTags()
  // map for multiselect.
  return tagsResponse.map(r => {return {value:r.id, text:r.name}});
}

export async function getUserOptions() {
  const usersResponse = (await Api.searchUsers()).rows;
  return usersResponse.map((u) => {
    return {
      text: (u.first_name+' '+u.last_name),
      value: u.id,
      restrict: false,
      isApiUser: u.is_api_user,
    }
  })
}
