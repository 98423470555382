
import InputMask from 'inputmask-core';

let inputFormattersMixin = {
  methods: {
    formatSSN(val, e) {
      console.log(val, e, {
        start: e.srcElement.selectionStart,
        end: e.srcElement.selectionEnd,
      });
      this.mask = new InputMask({pattern: "111-11-1111", value: val});
      this.mask.selection = {
        start: e.srcElement.selectionStart,
        end: e.srcElement.selectionEnd,
      };
      this.mask.input(e.data);
      let formattedValue = this.mask.getValue();
      if (formattedValue.length > 11) {
        console.log("formatted value too big")
        formattedValue = formattedValue.substring(0, 11);
      }
      return formattedValue;
    }
  }
};

export default inputFormattersMixin;