import {getFilterTypes, getFilterTypesByName, groupFiltersByType, Filter} from "./report-models";
import addFilterTemplate from "./add-new-filter.html"
import reportFiltersTemplate from "./report-filters.html"

let AddNewFilter = {
  name: "AddNewFilter",
  template: addFilterTemplate,
  props: [
    "cases",
  ],
  data: function() {
    return {
      newFilterType: getFilterTypesByName()["filter_case_name"],
      newFilterValue: null,
      newFilterValue2: null,
      validationError: "",
    }
  },
  methods: {
    addFilter() {
      let new_filter = new Filter(this.newFilterType, this.newFilterValue, this.newFilterValue2);

      // Validate
      this.validationError = "";
      if (!new_filter.isValid()) {
        this.validationError = new_filter.getValidationError();
        return;
      }

      // Emit new filter added
      this.$emit("filter-added", new_filter);

      this.reset();
    },
    reset() {
      this.newFilterTypeName = "filter_case_name";
      this.newFilterValue = null;
      this.newFilterValue2 = null;
    }
  },
  computed: {
    filterTypeOptions() {
      return getFilterTypes().map(ft => {return {text: ft.displayName, value: ft}});
    },

    casesOptions() {
      return this.cases.map(c => {
        return {text: c.group_name, value: c.id}
      });
    },
    yesNoOptions() {
      return [
          { text: 'Yes', value: true},
          { text: 'No', value: false},
        ]
    },
  }
};


let ReportFiltersComponent = {
  name: "ReportFiltersComponent",
  props: [
    "report",
    "cases",
  ],
  template: reportFiltersTemplate,
  data: function() {
    return {
      name: this.report.name,
      // Copy the report filters array from the report.
      filters: this.report.filters.map(f => new Filter(f.type, f.value, f.value2)),
      reportErrors: [],
      updateReportSuccess: null,
    }
  },
  watch: {
    // React to prop changing so we can update filters and name.
    report() {
      this.name = this.report.name;
      this.filters = this.report.filters.map(f => new Filter(f.type, f.value, f.value2));
    }
  },
  components: {
    'add-filter': AddNewFilter
  },

  methods: {
    handleFilterAdded(f) {
      this.filters.push(f);
    },
    removeFilter(f) {
      this.filters.splice(this.filters.indexOf(f), 1);
    },
    deleteReport() {
      this.$emit("delete-report");
    },
    applyAndSaveFilters() {
      this.$emit("apply-and-save", this.copyNewReport());
    },
    applyFilters() {
      this.$emit("apply", this.copyNewReport());
    },
    copyNewReport() {
      let newReport = this.report.copy();
      newReport.name = this.name;
      newReport.filters = this.filters;
      return newReport;
    }
  },
  computed: {
    groupedReportFilters() {
      return groupFiltersByType(this.filters);
    },
    filtersExist() {
      return this.filters.length > 0;
    },
    casesOptions() {
      return this.cases.map(c => {
        return {text: c.group_name, value: c.id}
      });
    },
    yesNoOptions() {
      return [
          { text: 'Yes', value: true},
          { text: 'No', value: false},
        ]
    },
  }
};



export default ReportFiltersComponent;
