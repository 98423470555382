<!-- This is a "fork" of FormInputjs `radioYesNo`/EnrollYesNoInput -->

<template>
  <b-form-group :label="display_name" :state="validationState"
                :invalid-feedback="invalidFeedback">
    <template v-slot:label v-html="display_html" v-if="display_html"></template>

    <b-form-radio-group
      buttons
      button-variant="outline-success"
      :name="name"
      :state="validationState"
      :validated="validated"
      :required="!optional"
      v-model="value"
      :options="[{'text': 'Yes', 'value': 'yes'}, {'text': 'No', 'value': 'no'}]"
    >
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
import bus from "./messages";
import {baseCheckValidationState, baseInvalidFeedback} from "./FormInput";

/**
 * These are used to copy data from the primary applicant to the spouse
 * @type {string[]}
 */
const contactInfoFields = [
  "email",
  "phone",
  "business_phone",
  "address",
  "address2",
  "city",
  "state",
  "zip",
]

export default {
  name: "UsePrimaryContactInfoYesNo",
  props: [
    "display_name",
    "display_html",
    "name",
    "initial_value",
    "options",
    "optional",
    "affects_premium",
    "disabled",
    "coverageContext",
    "applicantContext",
    "isStackedDisplay",
    "primaryApplicantContext"
  ],
  data: function () {
    return {
      value: (this.initial_value !== null && this.initial_value !== undefined) ? this.initial_value : null,
      validated: false,
    }
  },
  created() {
    // Always broadcast our initial value
    this.emitValue();
    if (this.affects_premium) {
      this.emitUpdatePremium();
    }
    bus.$on("validate", this.handleValidate);
  },
  watch: {
    value(newVal, oldVal) {
      this.emitValue();


      if (newVal !== oldVal) {
        this.updateContactInfoFields();
      }

      if (this.affects_premium) {
        this.emitUpdatePremium();
      }
    },
  },
  methods: {
    updateContactInfoFields() {
      if (this.value !== 'yes') {
        return;
      }

      contactInfoFields.forEach((fieldName) => {
        if (this.primaryApplicantContext[fieldName]) {
          let args = {
            applicant: this.applicantContext,
            name: fieldName,
            value: this.primaryApplicantContext[fieldName]
          };

          console.debug("emitting applicant-data-changed", args)
          bus.$emit("applicant-data-changed", args);
        }
      })
    },
    emitValue() {
      this.$emit("value-change", {name: this.name, value: this.value});
    },
    emitUpdatePremium() {
      bus.$emit("update-premium");
    },
    handleValidate() {
      this.validated = true;
    },
  },
  computed: {
    validationState: baseCheckValidationState,
    invalidFeedback: baseInvalidFeedback,
    localFormName() {
      if (!name) {
        return null;
      }
      let local = '' + name;
      let formId = 0;

      if (this.applicantContext && this.applicantContext.id) {
        formId = this.applicantContext.id;
      } else if (this.coverageContext && this.coverageContext.applicant && this.coverageContext.applicant.id) {
        formId = this.coverageContext.applicant.id;
      } else if (this.coverageContext && this.coverageContext.product && this.coverageContext.product.id) {
        formId = this.coverageContext.product.id;
      }

      local += `_${formId}`;
      return local;
    },
  }
}

</script>

<style scoped>

</style>
