<template>
  <b-modal
      :title="'New ' +terminology.formatCase({isTitle: true})"
      v-model="isShowingModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true">

      <b-form @submit.prevent="handleCreateCase">
        <b-container>
          <div class="column">
            <div class="form-group col-6 p-0">
              <label>Name:</label>
              <b-form-input
                class="col"
                v-model="groupName"
                type="text"
                placeholder="">
              </b-form-input>
            </div>
          </div>

          <div v-if="errors.length > 0">
            <b>Please correct the following error<span v-if="errors.length>1">s</span>:</b>
            <ul>
              <li class="text-danger" v-for="error in errors">{{ error.message }}</li>
            </ul>
          </div>
        </b-container>
      </b-form>

      <div slot="modal-footer" class="row align-self-end">
        <b-btn
          @click="isShowingModal = false"
          size="md"
          class="col-0 mr-3"
          variant="outline-secondary">
          <i class="fa fa-times"></i> Close
        </b-btn>
        <b-btn
          @click="handleCreateCase()"
          type="submit"
          size="md"
          class="col-0 mr-3"
          variant="primary">
          Save
        </b-btn>
      </div>
    </b-modal>
</template>

<script>
import Api from "../api";
import {ref, toRef, watch} from "@vue/composition-api";
import {terminology} from "../app";

export default {
  name: "create-case",
  props: {
    shouldShowModal: {type: Boolean, required: true}
  },
  emits: ['case-created', 'update:shouldShowModal'],
  setup(props, {emit, root}) {
    const isShowingModal = ref(props.shouldShowModal);
    const errors = ref([]);
    const groupName = ref("");

    watch(() => props.shouldShowModal, (val) => {
      isShowingModal.value = props.shouldShowModal;
    })
    watch(isShowingModal, (val) => {
      emit('update:shouldShowModal', val);
    })

    const handleCreateCase = async () => {
      errors.value = [];

      const response = await Api.createCase({
        group_name: groupName.value,
        enrollment_open: true,
        premium_mode: 'monthly'
      });

      if (response.errors && response.errors.length > 0) {
        errors.value = response.errors;
        return;
      }

      // TODO: I feel this should be done as part of create case on the back end.
      if (!root.userPermissions.isHomeOfficeAdmin()) {
        let users = [{
          id: root.userPermissions.getUserId(),
          restricted_to_own_enrollments: false
        }];
        await Api.addUsersToCase(response.id, {users: users});
      }

      emit('case-created', response);
      isShowingModal.value = false;
    }

    return {
      isShowingModal,
      groupName,
      errors,
      handleCreateCase,
      terminology
    }
  }
}
</script>

<style scoped>

</style>