import bus from './messages'
import {isEqual} from 'lodash';

const EMAIL_STATUS_NONE = "notsent";
const EMAIL_STATUS_SENT = "sent";
const EMAIL_STATUS_ERROR = "error";

export default {
  name: "SendCallCenterReviewEmailButton",
  template: `
<b-row>
  <b-col lg="5">
    <b-button block @click="handleButtonClicked" :disabled="isSending" variant="secondary" size="lg"><i class="fa fa-envelope"></i>&nbsp;
      <span>Send application for review</span>
    </b-button>  
  </b-col>
  <b-col class="text-left">
    <b-spinner v-if="isSending" class="h5 mt-1"></b-spinner>
    <div v-if="isSuccess" class="text-success h4 mt-2">
      <i class="fa fa-check"></i> Sent
    </div>
    <div v-if="isError" class="text-danger h4 mt-2">
      <i class="fa fa-close"></i> There was a problem sending to that email address.
    </div>
  </b-col>
</b-row>
  `,
  props: [
    'name',
    'initial_value',
  ],
  data: function() {

    const isError = (this.initial_value && this.initial_value === EMAIL_STATUS_ERROR);
    return {
      isSending: false,
      isSuccess: false,
      isError: isError,
    }
  },
  created() {
    // Ensure we broadcast a "not sent" state when this step loads, requiring sending the email.
    this.clearStatus();

    bus.$on("email-send-complete", this.handleEmailSendFinished);
    // If there are any controls on this page that change data, clear status when they do. (ie, first bill date)
    bus.$on("applicant-data-changed", ({name}) => {
      // avoid infinite loop since we emit in clearStatus()...
      if (name !== this.name) {
        this.clearStatus();
      }
    });
  },
  destroyed() {
    bus.$off("email-send-complete");
    bus.$off("wizard-enrollment-data-changed");
  },
  methods: {
    handleButtonClicked() {

      this.clearStatus();
      this.isSending = true;

      bus.$emit("send-call-center-review-email");
    },
    handleEmailSendFinished(didSend) {
      if (didSend) {
        this.setSuccess();
      } else {
        this.setError();
      }
      this.isSending = false;
    },
    clearStatus() {
      this.isSuccess = false;
      this.isError = false;
      this.$emit("value-change", {name: this.name, value: EMAIL_STATUS_NONE});
    },
    setSuccess() {
      this.isSuccess = true;
      this.$emit("value-change", {name: this.name, value: EMAIL_STATUS_SENT});
    },
    setError() {
      this.isError = true;
      this.$emit("value-change", {name: this.name, value: EMAIL_STATUS_ERROR});
    },
  }
};
