<template>
<div class="cases-wrap">
  <div class="case-actions p-3 mx-0">
    <b-row>
      <b-col>
        <h3>{{ "case" | terminology({isPlural: true, isTitle: true}) }}</h3>
      </b-col>
      <b-col cols="12" md="9">
        <case-filters-interface
          :searchText="caseSearchParams.searchText"
          @update:searchText="caseSearchParams.searchText = $event"

          :search-property="caseSearchParams.searchProperty"
          @update:searchProperty="caseSearchParams.searchProperty = $event"

          :status-filter="caseSearchParams.statusFilter"
          @update:statusFilter="caseSearchParams.statusFilter = $event"
        ></case-filters-interface>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4" class="d-none d-md-flex">
        <div v-if="userCanCreateCase" class="custom-button mr-3">
          <b-button
            type="button"
            variant="success"
            @click="handleCreateCaseClicked">
            <font-awesome-icon icon="plus" size="2x"></font-awesome-icon>
          </b-button>
          <label>New {{ "case" |terminology({isTitle: true}) }}</label>
        </div>
        <div class="custom-button mr-3" v-show="!isGeneratingCaseDownload">
          <b-button
            type="button"
            variant="outline-primary"
            @click="handleDownloadCSV()">
              <font-awesome-icon icon="download" size="2x"></font-awesome-icon>
          </b-button>
          <label>Download</label>
        </div>
        <div class="custom-button mr-3" v-show="isGeneratingCaseDownload">
          <b-button
            type="button"
            variant="outline-secondary"
          >
            <i class="fa fa-spinner fa-spin fa-2x"></i>
          </b-button>
          <label>Download</label>
        </div>
        <div class="custom-button mr-3">
          <b-button
            type="button"
            variant="outline-primary"
            @click="toggleCasesAssignTags()">
              <font-awesome-icon icon="tags" size="2x"></font-awesome-icon>
          </b-button>
          <label>Assign Tags</label>
        </div>
      </b-col>
      <b-col class="m-md-2 d-md-none">
        <b-dropdown id="ddown1" :text="$root.terminology.formatCase({isTitle: true}) + ' Actions'">
          <b-dropdown-item
            v-if="userCanCreateCase"
            @click="handleCreateCaseClicked">
            <font-awesome-icon icon="plus" size="2x"></font-awesome-icon> New {{ 'case' | terminology({isTitle: true}) }}
          </b-dropdown-item>
          <b-dropdown-item
            @click="handleDownloadCSV">
            <font-awesome-icon icon="download" size="2x"></font-awesome-icon> Download
          </b-dropdown-item>
          <b-dropdown-item
            @click="toggleCasesAssignTags">
            <font-awesome-icon icon="tags" size="2x"></font-awesome-icon> Assign Tags
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-collapse
        v-model="showCasesAssignTags"
        id="collapseCasesAssignTags"
        class="mt-3 mb-4 w-100">
        <div class="card-body">
            <cases-assign-tags
                :selected-case-ids="selectedCaseIds"
                v-show="showCasesAssignTags"
                @selected-cases-changed="handleSelectedCasesChanged"
                @cases-tags-updated="handleCasesTagsChanged"
                @close-cases-assign-tags="toggleCasesAssignTags"
            >
            </cases-assign-tags>
        </div>
    </b-collapse>
  </div>

  <case-table-interface
      :cases="cases"
      :total-results-count="totalResultsCount"
      :is-loading="isLoading"
      :selected-case-ids="selectedCaseIds"

      @sort-changed="handleSortChanged"
      @page-changed="handlePageChanged"
      @case-clicked="handleCaseClicked"
      @selected-cases-changed="handleSelectedCasesChanged"
  ></case-table-interface>

  <create-case @case-created="handleCaseCreated"
               :should-show-modal="shouldShowCreateCaseModal"
               @update:shouldShowModal="shouldShowCreateCaseModal = $event"
  ></create-case>
</div>
</template>

<script>
import caseFiltersInterface from './case-filters.vue'
import caseTableInterface from './cases-table.vue'
import createCase from './create-case.vue'
import useCaseSearch from './useCaseSearch'
import casesAssignTags from './cases-assign-tags.vue'
import {router} from '../app'
import Api from "../api";

import {ref, onMounted, watch, computed} from "@vue/composition-api"

export default {
    name: "Cases",
    components: {
        caseFiltersInterface,
        caseTableInterface,
        createCase,
        casesAssignTags,
    },
    props: {

    },
    setup(props, ctx) {

        const {cases, totalResultsCount, refreshCases, caseSearchParams, isLoadingCases} = useCaseSearch();

        const selectedCaseIds = ref([]);
        function handleSelectedCasesChanged(tableSelectedCasesId) {
            selectedCaseIds.value = [...tableSelectedCasesId];
        }

        // this refreshes cases, but also resets the selected case IDs
        function refreshAndReset() {
            selectedCaseIds.value = [];
            refreshCases();
        }


        onMounted(refreshAndReset)
        watch(caseSearchParams, refreshAndReset)

        function handleSortChanged(sort) {
            caseSearchParams.orderBy = sort.sortBy;
            caseSearchParams.orderDirection = sort.sortDirection;
        }

        function handlePageChanged(page) {
            caseSearchParams.currentPage = page.currentPage;
            caseSearchParams.rowsPerPage = page.rowsPerPage;
        }

        function handleCaseClicked(caseObj) {
            router.push({name: 'case', params: {id: caseObj.id}})
        }


        function handleCasesTagsChanged() {
          refreshAndReset();
        }

        const isGeneratingCaseDownload = ref(false);
        async function handleDownloadCSV() {
            isGeneratingCaseDownload.value = true;

            Api.startExportingCases(caseSearchParams)
                .then(response => {
                    if (response.errors && response.errors.length > 0) {
                        console.error(response.errors);
                        alert("Unexpected error starting export.");
                    } else {
                        let msg = 'Download generating. You will receive an email shortly.'
                        if (response && response.msg) {
                          msg = response.msg
                        }
                        alert(msg);
                        isGeneratingCaseDownload.value = false;
                    }
                }).finally(() => {
                    isGeneratingCaseDownload.value = false;
                });
        }

        const shouldShowCreateCaseModal = ref(false);
        function handleCreateCaseClicked() {
          shouldShowCreateCaseModal.value = true;
        }
        const handleCaseCreated = function() {
          refreshAndReset();
        }

        const userCanCreateCase = computed(() => {
            return ctx.root.userPermissions.canCreateCase();
        })

        let showCasesAssignTags = false;

        const toggleCasesAssignTags = function() {
          this.showCasesAssignTags = !this.showCasesAssignTags;
        }

        return {
            isLoading: isLoadingCases,
            isGeneratingCaseDownload,
            cases,
            caseSearchParams,
            totalResultsCount,
            handleSortChanged,
            handlePageChanged,
            handleCaseClicked,
            handleDownloadCSV,
            handleCreateCaseClicked,
            handleSelectedCasesChanged,
            handleCasesTagsChanged,
            selectedCaseIds,
            userCanCreateCase,
            shouldShowCreateCaseModal,
            handleCaseCreated,
            showCasesAssignTags,
            toggleCasesAssignTags,
        }
    }
}
</script>

<style scoped>
.case-actions {
    background-color: #f0efff;
    border-bottom-color: #f0efff;
}

.cases-wrap {
    border: 1px solid #dee2e6;
}
</style>
