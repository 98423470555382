<template>
  <b-overlay :show="isLoading">
    <Wizard v-if="showWizard"></Wizard>
    <b-alert v-else-if="showError" variant="danger" show>
      Unable to load call center review wizard. Token may be invalid.
    </b-alert>
  </b-overlay>
</template>

<script>

import Wizard from './WizardComponent';
import Api from "../api";

export default {
  name: 'CallCenterReview',
  components: {
    Wizard
  },
  props: {
    caseSlug: {
      type: String,
      required: false
    },
    tokenUUID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      showWizard: false,
      showError: false,
      errorMessage: ''
    }
  },
  async created() {
    console.log('CallCenterReview created');

    this.isLoading = true;

    const response = await Api.prepCallCenterReviewSession(
      this.caseSlug,
      this.tokenUUID
    );

    if (response && response.success === true) {
      this.showWizard = true;
      this.showError = false;
    } else {
      this.showWizard = false;
      this.showError = true;
    }

    this.isLoading = false;
  }
}

</script>


<style>

</style>
