
<script>
import bus from "../bus";

export default {
  name: "ShowNavigation",
  render() {
    return "";
  },
  created() {
    bus.$emit('show-navigation');
  }
}
</script>