import manageTagsTemplate from './manage-tags.html'
import Api from './api'
import colorDefaults from './models/color-defaults'

export default {
  template: manageTagsTemplate,
  data() {
    return {
      isLoading: false,
      systemTags: [],
      colorDefaults: colorDefaults,
      activeEditingTag: {
        name: '',
        id: null
      },
      activeDeletingTag: {
        name: '',
        id: null
      },
      newTagName: '',

      errors: []
    }
  },
  methods: {
    getTags() {
      Api.searchTags()
        .then(response => {
          // sort the tags by name
          response.sort(function(a, b) {
            return a.name.localeCompare(b.name);
          });
          this.systemTags = response;
        })
        .catch(err => {
          alert('There was a problem retrieving Tags from the server');
        });
    },
    renameTag(tag) {
      this.activeEditingTag = this.systemTags.find(thisTag => thisTag.id === tag.id);
    },
    showDeleteTagModal(tag) {
      this.endEditing();
      this.activeDeletingTag = tag;
      this.$refs['delete-tag-confirm-modal'].show();
    },
    hideDeleteTagModal() {
      this.endEditing();
      this.$refs['delete-tag-confirm-modal'].hide();
    },
    deleteTag() {
      let tag = this.activeDeletingTag;
      this.isLoading = true;
      let promise = Api.deleteTag(tag.id);
      promise.then(response => {
        this.hideDeleteTagModal();
        this.isLoading = false;
        this.$emit('tag-deleted');
        this.getTags();
      }).catch(err => {
        alert('There was a problem deleting the Tag from the server');
        this.isLoading = false;
      });
    },
    createTag() {
      let name = this.newTagName;
      this.isLoading = true;
      Api.createTag({name:name})
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.errors = response.errors;
        } else {
          this.isLoading = false;
          this.$emit('tag-created');
          this.clearErrors();
          this.endEditing();
          this.getTags();
        }
      }).catch(errResponse => {
        if (errResponse.errors) {
          this.isLoading = false;
          this.errors = errResponse.errors;
        }
      });
    },
    saveTag(tag) {
      this.isLoading = true;
      let promise = Api.updateTag(tag.id, tag)
      promise.then(result => {
        this.endEditing();
        this.getTags();
        this.$emit('tag-updated', tag);
        this.isLoading = false;
      })
    },
    endEditing() {
      this.activeEditingTag = {
        name: '',
        id: null
      };
      this.activeDeletingTag = {
        name: '',
        id: null
      };
      this.newTagName = '';
    },
    clearErrors() {
      this.errors = [];
    },
    isActiveEditingRow(tagId) {
      // !!tagId casts it to a boolean value
      return !!tagId && tagId === this.activeEditingTag.id;
    }
  },
  computed: {
    showErrors: function() {
      return this.errors && this.errors.length > 0;
    }
  },
  created() {
    this.getTags();
  }
}
