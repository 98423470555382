export default {
  card: {
    headerBgColor: 'secondary',
    headerTextColor: 'white',
    bgColor: 'light',
    footerBgColor: 'secondary',
  },
  recordingPlaybackCard: {
    headerBgColor: 'secondary',
    headerTextColor: 'white',
    bgColor: 'white',
    footerBgColor: 'white',
  }
};
