<template>
    <b-card
        :bg-variant="colorDefaults.card.bgColor"
        :header-text-variant="colorDefaults.card.headerTextColor"
        :header-bg-variant="colorDefaults.card.headerBgColor">

        <h5 slot="header" class="mb-0">Maintenance Mode</h5>
        

            <div class="column">
                <div class="form-group col-sm-6 col-md-6 pl-0">
                    <label>Start Date & Time:</label>
                    <div class="input-group">
                        <input type="date" :min="minDate" class="form-control" v-model="startDate" :disabled=maintenanceMode>
                        <div class="input-group-append">
                            <input type="time" class="form-control" v-model="startTime" :disabled=maintenanceMode>
                        </div>
                    </div>
                </div>

                <div class="form-group col-sm-6 col-md-6 pl-0">
                    <label>End Date & Time:</label>
                    <div class="input-group">
                        <input type="date" :min="minDate" class="form-control" v-model="endDate" :disabled=maintenanceMode>
                        <div class="input-group-append">
                            <input type="time" class="form-control" v-model="endTime" :disabled=maintenanceMode>
                        </div>
                    </div>
                </div>

                <div class="form-group col-sm-6 col-md-6 pl-0">
                    <label>Custom Maintenance message:</label>
                    <textarea class="form-control" v-model="maintenanceMsg" rows="4" style="resize:none" placeholder="Predefined message: We're sorry for any inconvenience, but the CELIC eApplication System is offline for maintenance.  We will resume shortly.  Thank you." :disabled=maintenanceMode></textarea>
                </div>

                <div class="form-group col-sm-6 col-md-6 pl-0">
                    <label>Access this Page while Offline:</label>
                    <multiselect
                      v-model="users"
                      :options="userData"
                      label="name" 
                      track-by="name"
                      :multiple="true"
                      :hide-selected="true"
                      :taggable="false"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="Search and select user"
                      open-direction="bottom"
                      :disabled=maintenanceMode>
                    </multiselect>
                </div>
                
                <div v-if="activateMaintenanceSuccess" class="p-3">
                  <b-alert variant="success" dismissible show>{{ this.activateMaintenanceSuccess }}</b-alert>
                </div>

                <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul>
                    <li class="text-danger" v-for="error in errors">{{ error.message }}</li>
                  </ul>
                </p>
                <div class="mt-4" v-if="maintenanceMode">
                  <b-button type="submit" variant="info" @click="turnOffMaintenance">Turn off & Resume Access</b-button>
                </div>
                <div class="mt-4" v-else="maintenanceMode">
                    <b-button type="submit" variant="primary" @click="updateMaintenance">Activate Maintenance Mode</b-button>
                </div>
            </div>
        <!-- </b-form> -->
    </b-card>
</template>

<script>

import Api from '../api.js'
import moment from '../vendor/moment-timezone-with-data-1970-2030'
import colorDefaults from '../models/color-defaults'
import formats from '../models/formats'
import { siteConfig, maintenanceDetail } from '../app'
// import bus from "../bus";

export default {
  props: {
    userPermissions: { type: Object },
  },
  data () {
    return {
      errors: [],
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      users: [],
      userData: [],
      maintenanceMsg: null,
      colorDefaults: colorDefaults,
      loader: false,
      errors: [],
      activateMaintenanceSuccess: null,
      maintenanceMode: siteConfig.maintenance_mode || maintenanceDetail,
      minDate: null,
    }
  },
  methods: {
    updateMaintenance() {
      this.loader = true;
      Api.activateMaintenance({
        start_date: (this.startDate && this.startTime) && moment(this.startDate +" "+ this.startTime).tz("America/Chicago").format(), //formats.utcToLocalDateTime(this.startDate +" "+this.startTime),
        end_date: (this.endDate && this.endTime) && moment(this.endDate +" "+ this.endTime).tz("America/Chicago").format(), //formats.utcToLocalDateTime(this.endDate +" "+this.endTime), //
        users_list: this.users.map(item=>item.id),
        maintenance_msg: this.maintenanceMsg
      })
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          this.errors = response.errors;
        } else {
          this.activateMaintenanceSuccess = response.message
          this.errors = [];
          this.maintenanceMode = true
        }
      });
    },
    turnOffMaintenance() {
      this.loader = true;
      Api.deactivateMaintenance()
      .then(response => {
        this.maintenanceMode = false,
        this.startDate = null,
        this.endDate = null,
        this.startTime = null,
        this.endTime = null,
        this.maintenanceMsg = null,
        this.users = []
      });
    },
    loadInitialDateTime(userData){
      if(maintenanceDetail){
        this.startDate = moment(maintenanceDetail.start_date).format('YYYY-MM-DD')
        this.startTime = moment(maintenanceDetail.start_date).format('hh:mm')
        this.endDate = moment(maintenanceDetail.end_date).format('YYYY-MM-DD')
        this.endTime = moment(maintenanceDetail.end_date).format('hh:mm')
        this.maintenanceMsg = maintenanceDetail.maintenance_msg
        this.users = maintenanceDetail.users_list.map((item) => ({
          name: item.first_name + " " + item.last_name,
          id: item.id
        }))
        console.log(this.users, this.userData);
      }
      this.minDate = moment(new Date()).format('YYYY-MM-DD')
    },
  },
  created() {
    Api.getUsersList()
    .then(response => {
      if (!response.errors) {
        // this.userForm = response;
        this.userData = response.users.map((item) => ({
          name: item.first_name + " " + item.last_name,
          id: item.id
        }))
      }
    });
    this.loadInitialDateTime(this.userData)
  }
}

</script>