<template>
  <b-modal
    :title="titleText"
    v-model="showModal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    @close="closeModal"
    @hide="onModalHide"
  >

    <b-overlay :show="loading" no-fade opacity="1" variant="light">
      <b-container>
        <div v-if="caseId > 0 && !isCaseActive">
          This {{ "enrollment" | terminology({}) }} is currently unavailable.
        </div>

        <div v-else-if="caseId > 0 && !isCaseValid">
          Please correct all validation errors in the setup before beginning.
        </div>

        <div v-else-if="noAvailableStateOptions">
          <b-alert v-if="noAvailableStateOptions" variant="warning" show>
            You are not licensed in any of the offered states.

            <table>
              <tr>
                <th>Offered states:</th>
                <td>
                  <span v-for="(state, index) in caseAllowedStates" :key="index">
                    {{ state }}<span v-if="index < caseAllowedStates.length - 1">, </span>
                  </span>
                </td>
              </tr>
              <tr>
                <th>Licensed states:</th>
                <td v-if="userLicensedStates && userLicensedStates.length > 0">
                  <span v-for="(state, index) in userLicensedStates" :key="index">
                    {{ state }}<span v-if="index < userLicensedStates.length - 1">, </span>
                  </span>
                </td>
                <td v-else>
                  <em>None</em>
                </td>
              </tr>
            </table>

            Please contact your administrator if this is an error.
          </b-alert>
        </div>

        <div v-else>
          <b-form v-show="matchingApplicant === null" :validated="validate">

            <b-card text-variant="primary" bg-variant="light" v-if="isAudioConferenceEnabledForCase" class="mb-4">
              <b-form-checkbox v-model="isAudioConferenceEnrollment" :disabled="isAudioConferenceForcedForCase || !isConferenceReadyToStart" switch size="md"
                               name="isAudioConferenceEnrollment">
                <!-- because "Voice Enrollment" is a feature term, and not the term for "an enrollment",
                 I think this hard-coded "Enrollment" is okay. - EF 2022-06-24 -->
                Use Voice {{ "enrollment" | terminology({isTitle: true}) }}
                <font-awesome-icon icon="phone"></font-awesome-icon>
              </b-form-checkbox>
            </b-card>

            <div v-if="isAudioConferenceEnabledForCase && isAudioConferenceEnrollment">

              <b-overlay
                :show="isConferenceCancelling"
                no-fade
                opacity="0.75" variant="light"
              >

                <div v-if="isConferenceReadyToStart">

                  <b-form-group
                    :state="!!(validateAgentNumber)"
                    :invalid-feedback="agentNumberInvalidFeedback"
                    class="col-6 px-0">
                    <label>Agent Phone Number</label>
                    <input
                      ref="agentNumberInput"
                      class="col form-control"
                      :class="{'is-invalid': invalidAgentNumber, 'is-valid': isValidAgentNumber}"
                      v-model="agentNumber"
                      v-mask="'000-000-0000'"
                      type="text"
                      placeholder="000-000-0000"
                      :disabled="!isConferenceReadyToStart"
                    >
                  </b-form-group>

                  <b-form-group
                    :state="!!(validatePrimaryApplicantNumber)"
                    :invalid-feedback="primaryApplicantNumberInvalidFeedback"
                    class="col-6 px-0">
                    <label>Applicant Phone Number</label>
                    <input
                      ref="primaryApplicantNumberInput"
                      class="col form-control"
                      :class="{'is-invalid': invalidPrimaryApplicantNumber, 'is-valid': isValidPrimaryApplicantNumber}"
                      v-model="primaryApplicantNumber"
                      v-mask="'000-000-0000'"
                      type="text"
                      placeholder="000-000-0000"
                      :disabled="!isConferenceReadyToStart"
                    >
                  </b-form-group>
                </div>

                <div v-if="isConferenceStarting">
                  <b-card
                    :sub-title-text-variant="conferenceStatusVariant"
                    :border-variant="conferenceStatusVariant"
                  >
                    <b-card-title>{{ conferenceStatusLabel }}
                      <b-spinner v-if="isConferenceStarting"></b-spinner>
                    </b-card-title>
                    <b-card-sub-title class="mb-2">{{ conferenceStatusReason }}</b-card-sub-title>
                    <b-list-group flush>
                      <b-list-group-item>
                        <b-col md="6"><strong>Agent
                          <font-awesome-icon icon="phone"></font-awesome-icon>
                        </strong></b-col>
                        <b-col md="6"><span class="font-weight-lighter">{{ agentNumber }}</span></b-col>
                      </b-list-group-item>
                      <b-list-group-item>
                        <b-col md="6"><strong>Applicant
                          <font-awesome-icon icon="phone"></font-awesome-icon>
                        </strong></b-col>
                        <b-col md="6"><span class="font-weight-lighter">{{ primaryApplicantNumber }}</span></b-col>
                      </b-list-group-item>
                    </b-list-group>
                  </b-card>

                  <b-button
                    class="mt-4"
                    variant="warning"
                    small
                    @click="cancelConferenceAndResetState()"
                    :disabled="isConferenceCancelling"
                  >
                    <b-spinner small v-if="isConferenceCancelling"></b-spinner>
                    <font-awesome-icon v-else icon="times"></font-awesome-icon>

                    <span v-if="isConferenceCancelling">Cancelling …</span>
                    <span v-else>Cancel Call</span>
                  </b-button>
                </div>

                <div v-if="isConferenceInProgress">
                  <b-alert v-text="" variant="success">
                    Conference call started. You can now begin the {{ "enrollment" | terminology({}) }} process.
                  </b-alert>
                </div>

              </b-overlay>

            </div>

            <div v-if="canStartEnrollmentSession">

              <div v-if="isConferenceInProgress">
              <span class="text-success">
                <font-awesome-icon icon="phone"></font-awesome-icon> <b-spinner class="ml-1 mr-1" small type="grow"
                                                                                variant="success"></b-spinner>
                Conference Call in progress.
              </span>
                <b-button
                  class="px-1"
                  variant="link"
                  small
                  @click="cancelConferenceAndResetState()"
                  :disabled="isConferenceCancelling"
                >
                <span v-if="isConferenceCancelling">
                  <b-spinner small>Cancelling</b-spinner>
                </span>
                  <span v-else>
                  <font-awesome-icon icon="times"></font-awesome-icon>
                  Cancel
                </span>
                </b-button>
              </div>

              <div v-if="!newEnrollment.caseId" class="form-group col-6 px-0">
                <label>{{ "case" | terminology({isTitle: true}) }}:</label>
                <b-form-select
                  v-model="caseId"
                  :options="caseFilterOptions"
                  aria-describedby="caseInvalid"
                  :state="validateCase"
                  required>
                </b-form-select>
                <b-form-invalid-feedback id="caseInvalid" class="mb-3">
                  This field is required.
                </b-form-invalid-feedback>
              </div>

              <h5>
                {{ "enrollment"|terminology({isTitle: true}) }} Location
              </h5>

              <div v-if="caseDivisions && caseDivisions.length > 0">
                <!-- we have caseDivisions, so we show that version of the interface -->
                <div class="form-group col-6 px-0">
                  <label>Division:</label>
                  <b-form-select
                    v-model="caseDivisionId"
                    :options="caseDivisionOptions"
                    aria-describedby="caseDivisionInvalid"
                    :state="validateCaseDivision"
                    required>
                  </b-form-select>
                  <b-form-invalid-feedback id="caseDivisionInvalid" class="mb-3">
                    This field is required.
                  </b-form-invalid-feedback>
                </div>
              </div>

              <!-- if we don't have caseDivisions, or we do AND we selected a caseDivisionId -->
              <div v-if="!caseDivisions || caseDivisions.length <= 0 || (caseDivisions && caseDivisionId)">
                <div class="form-group col-6 px-0">
                  <label>City:</label>
                  <b-form-input
                    class="col"
                    v-model="enrollmentCity"
                    type="text"
                    placeholder=""
                    aria-describedby="cityInvalid"
                    :state="validateCity"
                    required>
                  </b-form-input>
                  <b-form-invalid-feedback id="cityInvalid">
                    This field is required.
                  </b-form-invalid-feedback>
                </div>

                <div class="form-group col-6 px-0">
                  <label>State:</label>
                  <b-form-select
                    :disabled="noAvailableStateOptions"
                    v-model="enrollmentState"
                    :options="allStateOptions"
                    aria-describedby="stateInvalid"
                    :state="validateState"
                    required>
                  </b-form-select>
                  <b-form-invalid-feedback id="stateInvalid" class="mb-3">
                    This field is required.
                  </b-form-invalid-feedback>
                  <b-alert v-if="noAvailableStateOptions" variant="warning" show>
                    You are not licensed in any of the offered states. Please contact your administrator if this is an
                    error.
                  </b-alert>
                </div>
              </div>


              <b-form-group
                :state="(validateSsn) ? true : false"
                :invalid-feedback="ssnInvalidFeedback"
                v-show="!enrollmentId && !applicantId" class="col-6 px-0">
                <label>{{ "employee"|terminology({isTitle: true}) }} SSN:</label>
                <input
                  ref="ssnInput"
                  class="col form-control"
                  :class="{'is-invalid': invalidSSN, 'is-valid': isValidSSN}"
                  v-model="ssn"
                  v-mask="'000-00-0000'"
                  type="text"
                  placeholder=""
                >
              </b-form-group>
            </div>

            <b-form-group :label="$root.terminology.formatEnrollment({isTitle: true})+' Mode:'"
                          v-show="shouldShowEnrollmentModeSelection" class="col-6 px-0">
              <b-form-radio v-for="enrollmentModeType in enrollmentModes"
                            v-model="enrollmentMode" name="enrollmentModeRadios" :value="enrollmentModeType.value">
                {{ enrollmentModeType.label }}
              </b-form-radio>

            </b-form-group>

          </b-form>

          <div v-if="matchingApplicant !== null">
            <h5>Found matching record:</h5>
            <b-row>
              <b-col cols="5" class="text-right">Name:</b-col>
              <b-col>{{ matchingApplicant.first_name }} {{ matchingApplicant.last_name }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="5" class="text-right">Date of Birth:</b-col>
              <b-col>{{ matchingApplicantDOB }}</b-col>
            </b-row>
          </div>

          <div v-if="errors.length > 0" class="mt-4">
            <b>Errors<span v-if="errors.length>1">s</span>:</b>
            <ul>
              <li class="text-danger" v-for="error in errors">{{ error.message }}</li>
            </ul>
          </div>

        </div>

      </b-container>


    </b-overlay>
    <!--  <div v-if="loading === true" class="text-center">-->
    <!--    <font-awesome-icon icon="spinner" pulse size="5x"></font-awesome-icon>-->
    <!--  </div>-->


    <div slot="modal-footer" class="row align-self-end">
      <b-btn size="md" class="col-0 mr-3" variant="outline-secondary" @click="closeModal">
        <font-awesome-icon icon="times"></font-awesome-icon>
        Close
      </b-btn>

      <b-btn v-if="isAudioConferenceEnabledForCase && isAudioConferenceEnrollment && isConferenceReadyToStart && !canStartEnrollmentSession"
             :disabled="loading || !isConferenceReadyToStart"
             size="md" class="col-0 mr-3" variant="primary" @click.stop="startEnrollmentSessionConference">
        <font-awesome-icon icon="phone"></font-awesome-icon>
        Start Audio Conference
      </b-btn>

      <b-btn v-if="canStartEnrollmentSession"
             :disabled="loading"
             size="md" class="col-0 mr-3" variant="primary" @click.stop="startEnrollmentSession">
        <font-awesome-icon icon="arrow-right"></font-awesome-icon>
        {{ enrollText }}
      </b-btn>
    </div>

  </b-modal>

</template>

<script>
import states from './models/states'
import CaseValidation from './models/case_validation'
import Api from "./api";
import formats from "./models/formats";
import EnrollmentConferenceSessions from "./models/enrollment-conference-sessions";
import moment from "./vendor/moment-timezone-with-data-1970-2030.js";
import {terminology, siteConfig} from "./app";
import bus from "./bus";

const ENROLLMENT_MODE_INPERSON = "inperson";
const ENROLLMENT_MODE_CALLCENTER = "callcenter";
const ENROLLMENT_MODE_VOICEENROLLMENT = "voiceenroll";

export default {
  props: {
    newEnrollment: {type: Object},
    isCensusEnrollment: {type: Boolean, default: false},
  },
  data: function () {
    return {
      siteConfig: siteConfig,

      caseFilterOptions: [],
      enrollmentId: this.newEnrollment.enrollmentId || null,
      isPaused: this.newEnrollment.isPaused || null,
      applicantId: this.newEnrollment.applicantId || null,
      caseId: this.newEnrollment.caseId || null,
      caseDivisionId: this.newEnrollment.caseDivisionId || null,
      selectedCaseId: this.newEnrollment.caseId || null,
      caseData: null,
      caseDivisions: [],
      enrollmentCity: this.newEnrollment.enrollmentCity || null,
      enrollmentState: this.newEnrollment.enrollmentState || null,
      ssn: this.newEnrollment.ssn || null,
      showModal: true,
      validate: false,
      loading: true,
      isCaseValid: false,
      enrollText: "Next",
      matchingApplicant: null,
      // Is call center mode available and is it forced
      canEnrollmentModeBeChanged: null,
      enrollmentMode: ENROLLMENT_MODE_INPERSON,
      enrollmentModes: [
        {label: "In-person " + terminology.formatEnrollment({}), value: ENROLLMENT_MODE_INPERSON},
        {label: "Call center " + terminology.formatEnrollment({}), value: ENROLLMENT_MODE_CALLCENTER},
      ],

      // These are for enrollment session conferences
      // See watches for persisting this in localStorage -Ed F 2021-11-12
      isAudioConferenceEnrollment: (this.isAudioConferenceEnabledForCase && !!localStorage.isAudioConferenceEnrollment) || false,  // bool
      agentNumber: this.newEnrollment.agentNumber || localStorage.agentNumber || null,
      primaryApplicantNumber: this.newEnrollment.primaryApplicantNumber || null,  // not sticky

      // These are set by status updates that come in from polling
      conferenceStatus: null,
      conferenceStatusReason: null,
      conferenceEnded: null,

      conferenceStatusMap: EnrollmentConferenceSessions.conferenceStatusMap,

      isConferenceCancelling: false,
      isConferenceCancelled: false,

      _confStatusSubTimeout: null,

      errors: [],

      userLicensedStates: null, // null indicates not yet loaded
    }
  },

  async created() {
    try {

      this.loading = true;

      const cases = await Api.searchCases();
      this.caseFilterOptions = this.mapCases(cases);

      if (cases && cases.length === 1) {
        this.caseId = cases[0].id;
        console.debug(`Only one case found, auto-selecting case ${cases[0].id}`);
      }

      if (this.caseId > 0) {
        this.caseData = await Api.getCase(this.caseId);
        this.caseDivisions = await Api.getCaseDivisionsForCaseId(this.caseId);
        if (this.caseDivisions && this.caseDivisions.length === 1) {
          this.caseDivisionId = this.caseDivisions[0].id;
          console.debug(`Only one case division found, auto-selecting case division ${this.caseDivisions[0].id}`);
        }

        if (this.enrollmentCity === null) {
          this.enrollmentCity = this.caseData.situs_city;
        }
        if (this.enrollmentState === null) {
          this.enrollmentState = this.caseData.situs_state;
        }

        this.updateEnrollmentModeOptions();

        // Don't allow enrollment unless case setup is in a valid state (no errors)
        const caseValidator = new CaseValidation(this.caseId);
        this.isCaseValid = await caseValidator.validateCase();
      } else {
        this.isCaseValid = false;
      }


    } catch (e) {
      alert("There was an error validating the case data for enrollment");
    }

    // If we will not need to match the SSN, button should just say "Start Enrollment" or "Resume Enrollment"
    if (this.enrollmentId > 0 || this.applicantId > 0) {
      this.enrollText = this.actionText;
    }

    if (this.isAudioConferenceForcedForCase) {
      console.debug('Forcing audio conference enrollment');
      this.isAudioConferenceEnrollment = true;
    }

    this.loading = false;

    bus.$on("conference-status-updated", (data) => {
      console.debug("Received conference-status-updated data:", data);

      this.conferenceStatus = data['status'];
      this.conferenceStatusReason = data['status_reason'];
      this.conferenceEnded = data['ended'];
    });

    // get the user's licensed states. Do a dynamic call to api.me() to get the latest licensed_states - EF 2023-03-30
    let userPromise = Api.me();
    userPromise.then((user) => {
      if (user && user.licensed_states) {
        this.userLicensedStates = user.licensed_states;
        console.debug('User has licensed states.', this.userLicensedStates);
      }
    });
  },

  methods: {
    mapCases(cases) {
      let options = cases.map(c => {
        return {text: c.group_name, value: c.id};
      });
      options = formats.alphebetize(options, 'text');
      options.unshift({text: 'Select ' + terminology.formatCase({isTitle: true}), value: null});
      return options;
    },

    startEnrollmentSessionConference() {
      this.conferenceStatus = EnrollmentConferenceSessions.STATUS_INITIALIZING;

      this.postNewEnrollmentSessionConference()
        .then((r) => {
          if (r && !r.errors) {
            this.updateConferenceEventsSubscription(true);
          }
        });
    },

    /**
     *
     * @return {Promise<*>}
     */
    postNewEnrollmentSessionConference() {
      const enrollmentSessionConference = {
        agent_number: this.agentNumber,
        primary_applicant_number: this.primaryApplicantNumber,
      };

      return Api.postNewEnrollmentSessionConference(enrollmentSessionConference)
    },

    async startEnrollmentSession() {

      this.validate = true;

      if (!this.validateForm()) {
        return;
      }

      // If no applicant or enrollment, see if we have a matching user in the database (same SSN)
      if (this.matchingApplicant === null && !this.enrollmentId && !this.applicantId) {
        try {
          this.loading = true;
          let matches = await Api.searchCensus(this.caseId, this.ssn);
          if (matches && matches.length > 0) {
            // One additional search for paused enrollments for this applicant
            const pausedEnrollmentResult = await Api.searchPausedEnrollmentForApplicant(this.caseId, matches[0].id);
            if (pausedEnrollmentResult && pausedEnrollmentResult.enrollment_id > 0) {
              this.enrollmentId = pausedEnrollmentResult.enrollment_id;
              this.isPaused = true;
            } else {
              this.enrollmentId = null;
              this.isPaused = false;
            }
            this.matchingApplicant = matches[0];
            this.enrollText = this.actionText;
            this.loading = false;
            this.applicantId = this.matchingApplicant.id;

            // Don't continue, let them read the message and click "Start Enrollment"
            return;
          }
        } catch (err) {
          alert(`There was a problem matching to the census: ${err}`);
          this.loading = false;
          return;
        }

      }

      // Save city and state for next enrollment.
      localStorage.enrollmentState = this.enrollmentState ? this.enrollmentState : '';
      localStorage.enrollmentCity = this.enrollmentCity ? this.enrollmentCity : '';

      // If the enrollment mode was able to be set, also save its setting.
      if (this.canEnrollmentModeBeChanged) {
        this.saveSelectedEnrollmentMode(this.enrollmentMode);
      }

      this.postNewEnrollmentSession()
        .then((response) => {
          this.redirectToEnrollmentSessionWizard();
        });

    },

    /**
     *
     * @return {Promise<*>}
     */
    postNewEnrollmentSession() {

      let enrollment = {
        caseId: this.caseId,
        case_division_id: this.caseDivisionId,
        enrollment_state: this.enrollmentState,
        enrollment_city: this.enrollmentCity,
        enrollment_id: this.enrollmentId,
        ssn: this.ssn,
        applicant_id: this.applicantId,
        enrollment_mode: this.enrollmentMode,
        start_audio_conference: this.isAudioConferenceEnabledForCase && this.isAudioConferenceEnrollment,
        is_census_enrollment: this.isCensusEnrollment,
        is_resuming_enrollment: !!this.isPaused,
      };

      return Api.postNewEnrollmentSession(enrollment)
        .then((response) => {
          if (response.errors && response.errors.length > 0) {
            this.errors = response.errors;
            return;
          }

          return response;
        });

    },

    redirectToEnrollmentSessionWizard() {
      window.location = '/' + terminology.getWizardPathName();
    },

    /**
     * Retrieves the conference status, and will continue to re-poll for new statuses
     * as long as `this.isConferenceStarting` == true
     * @return {Promise<void>}
     */
    async updateConferenceEventsSubscription(tryUntilConferenceStart = false) {
      console.debug('Getting conference status. this.isConferenceStarting: ', this.isConferenceStarting);
      const pollDelay = EnrollmentConferenceSessions.POLL_DELAY;
      window.__EE_confStatusSubTimeout = null;

      // always get at least one status
      Api.getCurrentConferenceStatus()
        .then(responseJson => {
          if (responseJson && !responseJson.errors) {
            if (!this.isConferenceEnded) { // once the conference is ended, we don't want to update anymore
              console.debug('Updating subscription to conf status events:', responseJson);
              bus.$emit('conference-status-updated', responseJson);
            }

            this.$nextTick(() => {
              if (!this.isConferenceReadyToStart) {  // are we active baaed on the first request?
                window.__EE_confStatusSubTimeout = setTimeout(() => {
                  if (!this.isConferenceReadyToStart) {  // are we still active after waiting pollDelay ms?
                    // if so, request and re-evaluate
                    this.updateConferenceEventsSubscription();
                  }
                }, pollDelay);
              }
            });
          } else if (tryUntilConferenceStart) {
            console.debug('tryUntilConferenceStart is true. this.isConferenceStarting: ', this.isConferenceStarting);
            this.updateConferenceEventsSubscription(tryUntilConferenceStart);
          }
        });
    },

    stopConferenceEventsSubscription() {
      // this will stop the timeout that keeps grabbing
      clearTimeout(window.__EE_confStatusSubTimeout);
    },

    cancelConferenceAndResetState() {
      this.stopConferenceEventsSubscription();

      if (this.isAudioConferenceEnrollment && !this.isConferenceReadyToStart) {
        this.isConferenceCancelling = true;
        this.isConferenceCancelled = false;

        bus.$emit('cancel-enrollment-session-conference');

        this._cancelConference()
          .then((r) => {
            this.isConferenceCancelling = false;
            this.isConferenceCancelled = true;
          })
          .catch((reason) => {
            this.isConferenceCancelling = false;
            this.isConferenceCancelled = false;
          });
      }

      this.conferenceStatus = null;
      this.conferenceStatusReason = null;
      this.conferenceEnd = null;
    },
    closeModal(e) {
      e.preventDefault();
      this.$emit('close-new-enrollment-modal');
    },
    onModalHide(e) {
      console.debug('onModalHide', e);
      this.$nextTick(() => {
        console.debug('cancelConferenceAndResetState');
        this.cancelConferenceAndResetState();
      });
    },
    validateForm() {
      if (this.$refs.ssnInput) {
        this.$refs.ssnInput.setCustomValidity("");
      }
      if (!this.enrollmentCity
        || !this.enrollmentState
        || (!this.enrollmentId
          && !this.applicantId
          && (!this.caseId || this.invalidSSN))) {

        if (this.ssn && this.ssn.length !== 11) {
          this.$refs.ssnInput.setCustomValidity("SSN must be in the proper format.")
        }
        return false;
      }
      return true;
    },
    updateEnrollmentModeOptions() {
      // Allow choice of enrollment mode if case settings permit

      if (!this.caseData) {
        return;
      }

      if (this.isAudioConferenceEnabledForCase && this.isAudioConferenceEnrollment) {
        /**
         * If the case is configured to allow audio conference enrollment, and the user has selected
         * audio conference enrollment, then do not show the enrollment mode options - EF 2022-12-06
         */
        this.canEnrollmentModeBeChanged = false;
        this.enrollmentMode = ENROLLMENT_MODE_VOICEENROLLMENT;
      } else if (this.caseData.is_call_center_mode_active && this.caseData.force_call_center_mode) {
        // Call-center mode only
        this.canEnrollmentModeBeChanged = false;
        this.enrollmentMode = ENROLLMENT_MODE_CALLCENTER;
      } else if (this.caseData.is_call_center_mode_active && !this.caseData.force_call_center_mode) {
        // Allow the mode to be changed, and use the previously selected value for this user (localstorage)
        this.canEnrollmentModeBeChanged = true;
        this.enrollmentMode = this.getPreviouslySelectedEnrollmentMode() || ENROLLMENT_MODE_INPERSON;
      } else {
        // Traditional non-call center mode only
        this.canEnrollmentModeBeChanged = false;
        this.enrollmentMode = ENROLLMENT_MODE_INPERSON;
      }
    },
    getPreviouslySelectedEnrollmentMode() {
      return localStorage.enrollmentMode || null;
    },
    saveSelectedEnrollmentMode(mode) {
      localStorage.enrollmentMode = mode;
    },
    _cancelConference() {
      return Api.cancelCurrentConference()
        .then((r) => {
          console.debug("Maybe do something when current conference is cancelled.", r);
        })
        .catch((reason) => {
          console.warn("Problem cancelling current conference.", reason);
        })
        .finally(() => {
        });
    }
  },

  computed: {
    caseAllowedStates() {
      let caseAllowedStates = [];
      if (this.caseData && this.caseData.offered_states) {
        caseAllowedStates = this.caseData.offered_states;
      }
      if (this.siteConfig && this.siteConfig.allowedStates) {
        caseAllowedStates = this.siteConfig.allowedStates.filter(value => caseAllowedStates.includes(value));
      }
      return caseAllowedStates;
    },

    statesOptions() {
      let allowedStates = states.map((state) => state.value);
      console.debug(`Allowed states: ${allowedStates}`);
      if (this.caseData && this.caseData.offered_states) {
        allowedStates = this.caseData.offered_states;
      }

      if (this.siteConfig && this.siteConfig.allowedStates) {
        // this gets us the intersection of the two arrays, so we do not expand what is accessible
        // The case's offered_states takes priority over the siteConfig allowedStates
        allowedStates = this.siteConfig.allowedStates.filter(value => allowedStates.includes(value));
      }

      if (Array.isArray(this.userLicensedStates)) {
        allowedStates = allowedStates.filter(value => this.userLicensedStates.includes(value));
      }

      const stateOptions = states.filter((state) => allowedStates.includes(state.value));

      console.debug(`State options`, {stateOptions});
      return stateOptions;
    },
    selectedDivision() {
      if (!this.caseDivisionId) {
        return;
      }
      return this.caseDivisions.find(cd => cd.id === this.caseDivisionId);
    },
    matchingApplicantDOB() {
      if (this.matchingApplicant !== null && this.matchingApplicant.date_of_birth) {
        return moment(this.matchingApplicant.date_of_birth).format('M/D/YYYY');
      }
      return "";
    },
    validateCase() {
      return this.validate ? (this.caseId > 0) : null;
    },
    validateCaseDivision() {
      return this.validate ? (this.caseDivisionId > 0) : null;
    },
    validateCity() {
      return this.validate ? !!this.enrollmentCity : null;
    },
    validateState() {
      return this.validate ? !!this.enrollmentState : null;
    },
    ssnNotPresent() {
      return this.ssn === null || (this.ssn && this.ssn.length === 0);
    },
    ssnWrongLength() {
      return this.ssn !== null || (this.ssn && this.ssn.length !== 11);
    },
    validateSsn() {
      return (this.validate && this.ssn && this.ssn.length === 11);
    },
    invalidSSN() {
      return this.validate && !this.validateSsn;
    },
    isValidSSN() {
      return this.validate && this.validateSsn;
    },
    validateAgentNumber() {
      if (!this.isAudioConferenceEnabledForCase || !this.isAudioConferenceEnrollment) {
        return true;
      }
      return (this.validate && this.agentNumber && this.agentNumber.length === 12);
    },
    validatePrimaryApplicantNumber() {
      if (!this.isAudioConferenceEnabledForCase || !this.isAudioConferenceEnrollment) {
        return true;
      }
      return (this.validate && this.primaryApplicantNumber && this.primaryApplicantNumber.length === 12);
    },
    invalidAgentNumber() {
      return this.validate && !this.validateAgentNumber;
    },
    isValidAgentNumber() {
      return this.validate && this.validateAgentNumber;
    },
    invalidPrimaryApplicantNumber() {
      return this.validate && !this.validatePrimaryApplicantNumber;
    },
    isValidPrimaryApplicantNumber() {
      return this.validate && this.validatePrimaryApplicantNumber;
    },


    actionText() {
      return (this.isPaused) ? `Resume ${terminology.formatEnrollment({isTitle: true})}` : `Start ${terminology.formatEnrollment({isTitle: true})}`;
    },
    titleText() {
      let prefix = "New";
      if (this.isPaused) {
        prefix = "Resume";
      }
      let applicantText = "";
      if (this.newEnrollment.name) {
        applicantText = ` for ${this.newEnrollment.name}`;
      }
      return `${prefix} ${terminology.formatEnrollment({isTitle: true})}${applicantText}`
    },
    ssnInvalidFeedback() {
      if (!this.validate) {
        return null;
      }
      if (this.ssnNotPresent) {
        return "This field is required.";
      } else if (this.ssnWrongLength) {
        return "SSN must be in the proper format."
      }
      return null;
    },
    agentNumberInvalidFeedback() {
      if (!this.validate) {
        return null;
      }
      if (!this.agentNumber) {
        return "This field is required.";
      }
      return null;
    },
    primaryApplicantNumberInvalidFeedback() {
      if (!this.validate) {
        return null;
      }
      if (!this.primaryApplicantNumber) {
        return "This field is required.";
      }
      return null;
    },
    /**
     * This is the computed() this.stateOptions, with (Select State) prepended
     * @returns {{text: string, value: string || null}[]}
     */
    allStateOptions() {
      return [{text: "(Select State)", value: null}].concat(this.statesOptions);
    },

    noAvailableStateOptions() {
      // if it is null or undefined, return false
      if (this.statesOptions === null || this.statesOptions === undefined) {
        return false; // not yet set
      }

      // if it is an array, return true if it is empty
      if (Array.isArray(this.statesOptions)) {
        return this.statesOptions.length === 0;
      }

      return false; // assume non-error state

    },

    caseDivisionOptions() {
      if (!this.caseDivisions) {
        return []
      }

      let cdOptions = [{text: "(Select Division)", value: null}]

      for (let i = 0; i < this.caseDivisions.length; i++) {
        cdOptions.push({
          text: this.caseDivisions[i].division_name,
          value: this.caseDivisions[i].id
        })
      }

      return cdOptions;
    },
    shouldShowEnrollmentModeSelection() {
      if (this.isAudioConferenceEnrollment) {
        return false;
      }

      // Note: may change this to show it disabled or greyed out even if it can't be changed
      return this.canEnrollmentModeBeChanged;
    },
    isCaseActive() {
      if (this.caseData && 'is_active' in this.caseData) {
        return this.caseData.is_active;
      }
      return true;
    },
    isConferenceReadyToStart() {
      return EnrollmentConferenceSessions.helpers.isConferenceReadyToStart(this.conferenceStatus);
    },
    isConferenceStarting() {
      return EnrollmentConferenceSessions.helpers.isConferenceStarting(this.conferenceStatus);
    },
    isConferenceInitializing() {
      return EnrollmentConferenceSessions.helpers.isConferenceInitializing(this.conferenceStatus);
    },
    isConferenceInProgress() {
      return EnrollmentConferenceSessions.helpers.isConferenceInProgress(this.conferenceStatus);
    },
    isConferenceEnded() {
      return EnrollmentConferenceSessions.helpers.isConferenceEnded(this.conferenceStatus);
    },
    isConferenceEndedUnexpectedly() {
      return EnrollmentConferenceSessions.helpers.isConferenceEndedUnexpectedly(this.conferenceStatus);
    },
    conferenceStatusLabel() {
      return EnrollmentConferenceSessions.helpers.label(this.conferenceStatus);
    },
    conferenceStatusVariant() {
      return EnrollmentConferenceSessions.helpers.variant(this.conferenceStatus);
    },
    canStartEnrollmentSession() {
      if (this.noAvailableStateOptions) {
        return false;
      }

      if (this.isAudioConferenceEnabledForCase && this.isAudioConferenceEnrollment) {
        return this.isConferenceInProgress && this.isCaseActiveAndValid;
      }
      return this.isCaseActiveAndValid;
    },
    isCaseSelected() {
      return (this.caseId > 0);
    },
    isCaseActiveAndValid() {
      if (!this.isCaseSelected) {
        return true;
      }

      return this.caseId > 0 && this.isCaseActive && this.isCaseValid;
    },
    /**
     * @returns {boolean}
     */
    isAudioConferenceEnabledForCase() {
      if (!siteConfig.ffEnableEnrollmentSessionConferences) {
        return false;
      }

      if (this.caseData && (this.caseData.is_audio_conference_enroll_active === true)) {
        return true;
      }

      return false;
    },
    isAudioConferenceForcedForCase() {
      if (!siteConfig.ffEnableEnrollmentSessionConferences) {
        return false;
      }

      // dip out early if audio conference is not enabled for the case -- forcing only matters if it's enabled - EF 2022-12-13
      if (!this.caseData || !this.caseData.is_audio_conference_enroll_active) {
        return false;
      }

      if (this.caseData && (this.caseData.force_audio_conference_enroll === true)) {
        return true;
      }

      return false;
    }

  },
  watch: {
    /**
     * This will store the expected boolean as a string in localStorage. You can convert the stored value
     * back to a bool with `!!` (it will cast properly). We also trigger the Enrollment Mode UI to be updated
     * @param {Boolean} newVal
     * @param {Boolean} oldVal
     */
    isAudioConferenceEnrollment(newVal, oldVal) {
      if (newVal !== oldVal) {
        let storedValue = ''; // we store an empty string for bool(`false`)
        if (newVal) {
          storedValue = 'true'; // store the string for bool(`true`)
        }

        if (!this.isAudioConferenceEnabledForCase) {
          storedValue = '';
        }

        localStorage.isAudioConferenceEnrollment = storedValue;

        // update the Call Center Opitions
        this.updateEnrollmentModeOptions();
      }
    },

    agentNumber(newVal, oldVal) {
      localStorage.agentNumber = newVal;
    },

    // v-mask on input requires us to watch this manually since it is capturing events
    ssn() {
      this.validateForm();
    },

    async isConferenceStarting() {
      // when this changes, we should re-evaluate if we need to subscribe to updates
      await this.updateConferenceEventsSubscription();
    },

    selectedDivision() {
      if (this.selectedDivision && this.selectedDivision.default_city) {
        // @TODO update so we don't overwrite sticky values - EF 2022-05-24
        this.enrollmentCity = this.selectedDivision.default_city;
        this.enrollmentState = this.selectedDivision.default_state;
      }
    },

    // @TODO remove this watch? - Ed F 2021-10-29
    isConferenceInProgress(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        console.debug('Conference is now IN PROGRESS. This watch does not do anything');
      }
    },
    // @TODO remove this watch? - Ed F 2021-10-29
    isConferenceEnded(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        console.debug('Conference is now ENDED. This watch does not do anything');
      }
    },
    // @TODO remove this watch? - Ed F 2021-10-29
    isConferenceEndedUnexpectedly(newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        console.debug('Conference is now ENDED UNEXPECTEDLY. This watch does not do anything');
      }
    },

    async caseId() {
      if (this.caseId > 0) {
        this.loading = true;
        try {
          const caseValidator = new CaseValidation(this.caseId);
          this.isCaseValid = await caseValidator.validateCase();
          this.caseData = await Api.getCase(this.caseId);
          this.caseDivisions = await Api.getCaseDivisionsForCaseId(this.caseId);
          this.updateEnrollmentModeOptions();
          if (this.isAudioConferenceForcedForCase) {
            console.debug('Forcing audio conference enrollment');
            this.isAudioConferenceEnrollment = true;
          }
        } catch (err) {
          alert(`There was an error communicating with the server: ${err}`);

        } finally {
          this.loading = false;
        }
      }
    }
  },
}
</script>
