<template>
  <b-row>
    <b-col md="8" offset-md="2" cols="12" offset="0">
      <b-card :title="modalTitle">

        <div v-if="loading" class="text-center">
          <font-awesome-icon icon="spinner" pulse size="5x"></font-awesome-icon>
        </div>

        <b-alert v-else-if="showError" variant="danger" show> <!-- NOT LOADING and error -->
          Unable to load agent enroll, remote signature wizard. Token may be invalid.
        </b-alert>

        <b-container v-else>  <!-- NOT LOADING -->
          <div v-if="caseId > 0 && !isCaseValid">
            Please correct all validation errors in the case setup before enrolling.
          </div>

          <div v-else-if="noAvailableStateOptions">
            <b-alert v-if="noAvailableStateOptions" variant="warning" show>
              You are not licensed in any of the offered states.

              <table>
                <tr>
                  <th>Offered states:</th>
                  <td>
                           <span v-for="(state, index) in caseAllowedStates" :key="index">
                             {{ state }}<span v-if="index < caseAllowedStates.length - 1">, </span>
                           </span>
                  </td>
                </tr>
                <tr>
                  <th>Licensed states:</th>
                  <td v-if="userLicensedStates && userLicensedStates.length > 0">
                           <span v-for="(state, index) in userLicensedStates" :key="index">
                             {{ state }}<span v-if="index < userLicensedStates.length - 1">, </span>
                           </span>
                  </td>
                  <td v-else>
                    <em>None</em>
                  </td>
                </tr>
              </table>

              Please contact your administrator if this is an error.
            </b-alert>
          </div>

          <b-form v-show="matchingApplicant === null" v-else :validated="validate">
            <h5>
              Enrollment Location
            </h5>

            <div class="form-group px-0">
              <label>City:</label>
              <b-form-input
                class="col"
                v-model="enrollmentCity"
                type="text"
                placeholder=""
                aria-describedby="cityInvalid"
                :state="validateCity"
                required>
              </b-form-input>
              <b-form-invalid-feedback id="cityInvalid">
                This field is required.
              </b-form-invalid-feedback>
            </div>

            <div class="form-group px-0">
              <label>State:</label>
              <b-form-select
                :disabled="noAvailableStateOptions"
                v-model="enrollmentState"
                :options="allStateOptions"
                aria-describedby="stateInvalid"
                :state="validateState"
                required>
              </b-form-select>
              <b-form-invalid-feedback id="stateInvalid" class="mb-3">
                This field is required.
              </b-form-invalid-feedback>

            </div>

            <b-form-group
              :state="(validateSsn) ? true : false"
              :invalid-feedback="ssnInvalidFeedback"
              v-show="!enrollmentId && !applicantId" class="px-0">
              <label>Employee SSN:</label>
              <input
                ref="ssnInput"
                class="col form-control"
                :class="{'is-invalid': invalidSSN, 'is-valid': isValidSSN}"
                v-model="ssn"
                v-mask="'000-00-0000'"
                type="text"
                placeholder=""
              >
            </b-form-group>
          </b-form>

          <div v-if="matchingApplicant !== null" class="mt-3">
            <h5>Found matching census record:</h5>
            <b-row class="mt-3">
              <b-col>Name:</b-col>
              <b-col>{{ matchingApplicant.first_name }} {{ matchingApplicant.last_name }}</b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>Date of Birth:</b-col>
              <b-col>{{ matchingApplicantDOB }}</b-col>
            </b-row>
          </div>
          <b-row class="mt-3">
            <b-col>
              <b-btn block v-if="matchingApplicant !== null" size="md" class="mr-3" variant="outline-secondary"
                     @click.stop="clearMatchingApplicant">
                <font-awesome-icon icon="undo"></font-awesome-icon>
                Try a different SSN
              </b-btn>
            </b-col>
            <b-col v-if="isCaseValid && !noAvailableStateOptions">
              <b-btn block size="md" class="mr-3" variant="primary" @click.stop="startEnrollment">
                <font-awesome-icon icon="arrow-right"></font-awesome-icon>
                {{ enrollText }}
              </b-btn>
            </b-col>
          </b-row>
        </b-container>

      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import states from "../models/states";
import CaseValidation from '../models/case_validation'
import Api from "../api.js";
import formats from "../models/formats";
import moment from "moment";
import {terminology} from "../app.js";

export default {
  name: "AgentEnrollRemoteSig",
  props: {
    caseSlug: {
      type: String,
      required: false
    },
    tokenUUID: {
      type: String,
      required: true
    },

  },
  data: function () {
    return {
      newEnrollment: {},

      enrollmentId: null,
      applicantId: null,
      caseId: null,
      selectedCaseId: null,
      caseName: null,
      enrollmentCity: null,
      enrollmentState: null,
      ssn: null,

      caseData: null,
      caseFilterOptions: [],
      isCaseValid: false,
      matchingApplicant: null,

      validate: false,
      loading: true,
      showModal: true,
      showError: false,
      enrollText: "Next",

      userLicensedStates: null,
    }
  },

  async created() {
    this.loading = true;

    // first, we will "prep" the data and validate the UUID
    let response = await Api.prepAgentEnrollRemoteSigSession(this.caseSlug, this.tokenUUID);
    console.log('AgentEnrollRemoteSig Start', response);
    if (response && response.newEnrollmentSessionData && !response.errors) {
      this.newEnrollment = response.newEnrollmentSessionData;

      // update the local caseData
      this.caseData = this.newEnrollment.case;
      if (this.newEnrollment.case.id) {
        this.caseId = this.newEnrollment.case.id;
      }
      this.caseName = this.newEnrollment.case.group_name;

      console.debug("Prepped AgentEnrollRemoteSig Session. this.newEnrollment: ", this.newEnrollment);
    } else {
      this.showError = true;
      this.errorMessage = response.errors;
    }

    // get the user's licensed states. Do a dynamic call to api.me() to get the latest licensed_states - EF 2023-03-30
    let userPromise = Api.me();
    userPromise.then((user) => {
      if (user && user.licensed_states) {
        this.userLicensedStates = user.licensed_states;
        console.debug('User has licensed states.', this.userLicensedStates);
      }
    });

    try {

      const cases = await Api.searchCases();
      this.caseFilterOptions = this.mapCases(cases);

      if (this.caseId > 0) {
        this.caseData = await Api.getCase(this.caseId);
        if (this.enrollmentCity === null) {
          this.enrollmentCity = this.caseData.situs_city;
        }
        if (this.enrollmentState === null) {
          this.enrollmentState = this.caseData.situs_state;
        }

        // Don't allow enrollment unless case setup is in a valid state (no errors)
        const caseValidator = new CaseValidation(this.caseId);
        this.isCaseValid = await caseValidator.validateCase();
      } else {
        this.isCaseValid = false;
      }


    } catch (e) {
      alert("There was an error validating the case data for enrollment");
    }

    // If we do not need to match the SSN, button should just say "Start Enrollment"
    if (this.enrollmentId > 0 || this.applicantId > 0) {
      this.enrollText = "Start Enrollment";
    }

    this.loading = false;

  },

  methods: {
    mapCases(cases) {
      let options = cases.map(c => {
        return {text: c.group_name, value: c.id};
      });
      options = formats.alphebetize(options, 'text');
      options.unshift({text: 'Select Case', value: null});
      return options;
    },

    async startEnrollment() {

      this.validate = true;

      if (!this.validateForm()) {
        return;
      }

      // If no applicant or enrollment, see if we have a matching user in the database (same SSN)
      if (this.matchingApplicant === null && !this.enrollmentId && !this.applicantId) {
        try {
          this.loading = true;
          let matches = await Api.searchCensus(this.caseId, this.ssn);
          if (matches && matches.length > 0) {
            this.matchingApplicant = matches[0];
            this.enrollText = "Start Enrollment";
            this.loading = false;
            this.applicantId = this.matchingApplicant.id;

            // Don't continue, let them read the message and click "Start Enrollment"
            return;
          }
        } catch (err) {
          alert(`There was a problem matching to the census: ${err}`);
          this.loading = false;
          return;
        }

      }

      // Save city and state for next enrollment.
      localStorage.enrollmentState = this.enrollmentState ? this.enrollmentState : '';
      localStorage.enrollmentCity = this.enrollmentCity ? this.enrollmentCity : '';

      let enrollment = {
        caseId: this.caseId,
        enrollmentState: this.enrollmentState,
        enrollmentCity: this.enrollmentCity,
        enrollment_id: this.enrollmentId,
        ssn: this.ssn,
        applicant_id: this.applicantId,
      };

      Api.postNewEnrollmentSession(enrollment).then(() => {
        console.debug("Enrollment session created. Redirecting to enrollment wizard via router...")
        this.$router.push({name: 'EnrollmentWizard'});
      });
    },

    validateForm() {
      if (this.$refs.ssnInput) {
        this.$refs.ssnInput.setCustomValidity("");
      }
      if (!this.enrollmentCity || !this.enrollmentState || (!this.enrollmentId && !this.applicantId && (!this.caseId || this.invalidSSN))) {

        if (this.ssn && this.ssn.length !== 11) {
          this.$refs.ssnInput.setCustomValidity("SSN must be in the proper format.")
        }
        return false;
      }
      return true;
    },

    clearMatchingApplicant() {
      this.matchingApplicant = null;
      this.enrollmentId = null;
      this.applicantId = null;
    }
  },

  computed: {
    modalTitle() {
      if (this.newEnrollment && this.newEnrollment.name) {
        return `New ${terminology.formatEnrollment({isTitle: true})} for ${this.newEnrollment.name}`
      }
      return `New ${terminology.formatEnrollment({isTitle: true})}`
    },

    matchingApplicantDOB() {
      if (this.matchingApplicant !== null && this.matchingApplicant.date_of_birth) {
        return moment(this.matchingApplicant.date_of_birth).format('M/D/YYYY');
      }
      return "";
    },
    validateCase() {
      return this.validate ? (this.caseId > 0) : null;
    },
    validateCity() {
      return this.validate ? !!this.enrollmentCity : null;
    },
    validateState() {
      return this.validate ? !!this.enrollmentState : null;
    },
    ssnNotPresent() {
      return this.ssn === null || (this.ssn && this.ssn.length === 0);
    },
    ssnWrongLength() {
      return this.ssn !== null || (this.ssn && this.ssn.length !== 11);
    },
    validateSsn() {
      return (this.validate && this.ssn && this.ssn.length === 11);
    },
    invalidSSN() {
      return this.validate && !this.validateSsn;
    },
    isValidSSN() {
      return this.validate && this.validateSsn;
    },
    ssnInvalidFeedback() {
      if (!this.validate) {
        return null;
      }
      if (this.ssnNotPresent) {
        return "This field is required.";
      } else if (this.ssnWrongLength) {
        return "SSN must be in the proper format."
      }
      return null;
    },
    caseAllowedStates() {
      let caseAllowedStates = [];
      if (this.caseData && this.caseData.offered_states) {
        caseAllowedStates = this.caseData.offered_states;
      }
      if (this.siteConfig && this.siteConfig.allowedStates) {
        caseAllowedStates = this.siteConfig.allowedStates.filter(value => caseAllowedStates.includes(value));
      }
      return caseAllowedStates;
    },
    statesOptions() {
      let allowedStates = states.map((state) => state.value);
      console.debug(`Allowed states: ${allowedStates}`);
      if (this.caseData && this.caseData.offered_states) {
        allowedStates = this.caseData.offered_states;
      }

      if (this.siteConfig && this.siteConfig.allowedStates) {
        // this gets us the intersection of the two arrays, so we do not expand what is accessible
        // The case's offered_states takes priority over the siteConfig allowedStates
        allowedStates = this.siteConfig.allowedStates.filter(value => allowedStates.includes(value));
      }

      console.debug(`Allowed states done: ${allowedStates}`);

      const stateOptions = states.filter((state) => allowedStates.includes(state.value));
      console.debug(`State options: ${stateOptions}`);
      return stateOptions;
    },

    allStateOptions() {
      return [{text: "(Select State)", value: null}].concat(this.statesOptions);
    },

    noAvailableStateOptions() {
      // if it is null or undefined, return false
      if (this.statesOptions === null || this.statesOptions === undefined) {
        return false; // not yet set
      }

      // if it is an array, return true if it is empty
      if (Array.isArray(this.statesOptions)) {
        return this.statesOptions.length === 0;
      }

      return false; // assume non-error state
    },
  },
  watch: {
    // v-mask on input requires us to watch this manually since it is capturing events
    ssn() {
      this.validateForm();
    },
    async caseId() {
      if (this.caseId > 0) {
        // this.loading = true;
        try {
          const caseValidator = new CaseValidation(this.caseId);
          this.isCaseValid = await caseValidator.validateCase();
        } catch (err) {
          alert(`There was an error validating the case data for enrollment: ${err}`);

        } finally {
          // this.loading = false;
        }
      }


    }
  }
}
</script>

<style scoped>

</style>
