import loginForm from './reset-password.html'
import Api from './api.js'


export default {
    template: loginForm,
    props: {
      userPermissions: { type: Object },
    },
    data () {
      return {
        invitationPage: false,
        errors: [],
        token: null,
        newPassword: null,
        retypePassword: null,
        passwordUpdateSuccess: false,

        isUpdating: false
      }
    },
    methods: {
      finalize() {
        this.isUpdating = true;

        let endpoint = this.invitationPage ? Api.invitationFinalize : Api.forgotPasswordFinalize;
        endpoint({
          token: this.token,
          password: this.newPassword
        })
          .then(response => {
            if (response.errors && response.errors.length > 0) {
              this.errors = response.errors;
              this.isUpdating = false;
            } else {
              this.passwordUpdateSuccess = true;
              setTimeout(() => {
                this.isUpdating = false;
                this.$router.push('/');
              }, 1500);
            }
          })
          .catch(() => {
            this.isUpdating = false;
          });
      }
    },
    created() {
      if(this.$route.params.token) {
        this.token = this.$route.params.token;
      }

      this.invitationPage = this.$route.path.includes('invitation');
    }
}
