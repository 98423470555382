// Use font-awesome icons; bundles only icons that are referenced in the DOM
import {library, dom} from "@fortawesome/fontawesome-svg-core";
import {
  faCcAmazonPay,
  faCcAmex,
  faCcApplePay,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcPaypal,
  faCcStripe,
  faCcVisa
} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
  faSpinner, faPlus, faUserPlus, faUserMinus, faCheck, faTimes, faTimesCircle,
  faChevronLeft, faChevronRight, faArrowRight, faCog, faPaperPlane,
  faBars, faAngleLeft, faFilePdf, faTrash, faEnvelope,
  faSquare, faMinusSquare, faCheckSquare, faKey, faBan, faTag,
  faCaretUp, faCaretDown, faDownload, faAddressBook, faSearch, faPenAlt, faPencilAlt, faEdit, faUndo,
  faPlayCircle, faPauseCircle, faRecycle, faChartPie, faTags, faPhone, faComment, faClock, faCreditCard,
  faUpload, faPrint, faRedo
} from "@fortawesome/free-solid-svg-icons";
import {
  faFilePdf as farFilePdf,
  faSquare as farSquare
} from "@fortawesome/free-regular-svg-icons"

library.add(farFilePdf, faPlus, faUserPlus, faUserMinus, faCog, faSpinner, faCheck, faCaretUp, faArrowRight,
  faSquare, faMinusSquare, faCheckSquare, faKey, faBan, faTag, faPaperPlane,
  faCaretDown, faTimes, faTimesCircle, faDownload, faAddressBook,
  faChevronLeft, faChevronRight, faSearch, faEnvelope, faTrash, faFilePdf, faAngleLeft, faBars,
  farSquare, faPenAlt, faPencilAlt, faEdit, faUndo, faPlayCircle, faPauseCircle, faRecycle, faChartPie, faTags, faPhone,
  faComment, faClock, faCreditCard, faCcAmazonPay, faCcAmex, faCcApplePay, faCcDinersClub, faCcDiscover, faCcJcb,
  faCcMastercard, faCcPaypal, faCcStripe, faCcVisa, faUpload, faPrint, faRedo);

dom.watch();

// Use this component whenever doing anything dynamic with an icon (hiding, clicking, etc)
import Vue from 'vue';
Vue.component('font-awesome-icon', FontAwesomeIcon);

import loadingSpinner from "./loader"
Vue.component('loading-spinner', loadingSpinner);
