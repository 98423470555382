import bus from './messages'

export default {
  template: `
<div>
  <loading-spinner v-show="isLoading" size="large"></loading-spinner>
  
  <div v-show="!isLoading">
    <div id="enrollment-preview"></div>
  </div>
</div>  
  `,
  props: {
    isSummaryOnly: {type: Boolean, required: false, default: false},
  },
  data: function() {
    return {
      isLoading: false,
      shouldShowPreview: false
    }
  },
  created() {
    bus.$on("preview-ready", this.showPreview);
    if (!this.isLoading) {
      this.previewEnrollment();
    }
  },
  activated() {

  },
  methods: {
    previewEnrollment() {
      this.isLoading = true;
      bus.$emit("review-documents", {summaryOnly: this.isSummaryOnly});
    },
    showPreview() {
      this.isLoading = false;
      this.shouldShowPreview = true;
    }
  }
};