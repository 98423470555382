<template>
  <div>
    <b-table striped class="mb-0 pointer"
             v-if="!isLoading"
             :items="cases"
             :fields="fields"
             @row-clicked="handleCaseRowClicked"
             @head-clicked="headerClick"
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             primary-key="id"
             :tbody-tr-class="getRowClass"
             no-local-sorting>

      <template #head(select)>
        <font-awesome-icon :icon="['far', 'square']" v-if="hasNoCasesSelected"></font-awesome-icon>
        <font-awesome-icon icon="minus-square" v-if="hasSomeCasesSelected"></font-awesome-icon>
        <font-awesome-icon icon="check-square" v-if="hasAllCasesSelected" ></font-awesome-icon>
      </template>

      <template #cell(select)="row">
        <div @click="toggleCaseSelected(row.item)">
          <font-awesome-icon
            icon="check-square"
            v-if="isCaseSelected(row.item)">
          </font-awesome-icon>
          <font-awesome-icon
            :icon="['far', 'square']"
            v-if="!isCaseSelected(row.item)">
          </font-awesome-icon>
        </div>
      </template>

      <div class="sort-component"
           v-for="field in fields"
           :slot="'HEAD_'+field.key"
           v-if="field.label"
      >
        <div class="sort-label"> {{field.label}}</div>
      </div>
  </b-table>

  <loading-spinner v-show="isLoading" class="p-3"></loading-spinner>

  <div v-if="cases.length === 0 && !isLoading" class="p-4 text-center">
    No cases match your search.
  </div>

  <div class="row mx-3 pt-3">
    <b-pagination
      size="sm"
      :total-rows="totalResultsCount"
      v-model="currentPage"
      :per-page="rowsPerPage"
     >
    </b-pagination>

    <div class="pl-3">
      <b-form-select
        class="col-0 pag-row-number"
        v-model="rowsPerPage"
        :options="[
          25,
          50,
          100
        ]">
      </b-form-select>
    </div>
  </div>
</div>

</template>

<script>
import {ref, toRef, watch, computed} from "@vue/composition-api"
import formats from "../models/formats"
import bus from "../bus";
import {terminology} from "../app";

export default {
  name: "cases-table",
  props: {
    cases: {type: Array, required: true},
    totalResultsCount: {required: true},
    isLoading: {type: Boolean, required: true},
    selectedCaseIds: {type: Array, required: true}
  },
  emits: [
    'sort-changed',
    'case-clicked'
  ],
  setup(props, {emit}) {

    const fields = [
      {
        key: 'select',
        label: '',
        class: 'select-column'
      },
      {
        key: 'name',
        label: `${terminology.formatCase({isTitle: true})} Name`,
        sortable: true
      },
      {
        key: 'num_pending',
        label: 'Pending',
        sortable: true
      },
      {
        key: 'num_paused',
        label: 'Paused',
        sortable: true,
        // Only display on MD screen and above
        class:'d-none d-md-table-cell',
      },
      {
        label: 'Delivery',
        key: 'num_queued',
        sortable: true,
        formatter: (v) => {
          // if not a number, return empty string
          if (v===null || v===undefined || isNaN(v)) {
            return '';
          } else if (v < 0) {
            return 'transmit';
          } else {
            return  `${v} queued`;
          }
        },
        // Only display on MD screen and above
        class:'d-none d-md-table-cell',
      },
      {
        label: 'Tags',
        key: 'tags',
        sortable: false,
        // Only display on LG screen and above
        class:'d-none d-lg-table-cell',
      },
      {
        label: 'Census',
        key: 'num_census',
        sortable: true,
        formatter: (val) => formats.numberWithCommas(val, 0, 0),
        // Only display on LG screen and above
        class:'d-none d-lg-table-cell',
      },
      {
        label: 'App Count',
        key: 'num_enrolled',
        sortable: true,
        formatter: (val) => formats.numberWithCommas(val, 0, 0),
      },
      {
        label: 'Premium',
        key: 'total_premium',
        sortable: true,
        formatter: (v) => formats.currency(v),
        // Only display on LG screen and above
        class:'d-none d-lg-table-cell',
      },
      {
        key: 'status',
        label: 'Status',
        sortable: true,
        // Only display on LG screen and above
        class:'d-none d-lg-table-cell',
      }
    ]

    const sortBy = ref('name');
    const sortDesc = ref(false);
    const rowsPerPage = ref(localStorage.rowsPerPage ? localStorage.rowsPerPage: 25);
    const currentPage = ref(1);

    let localSelectedCaseIds =  ref([...props.selectedCaseIds]);  // shallow-copies the array
    watch(localSelectedCaseIds, () => {
      emit('selected-cases-changed', localSelectedCaseIds.value)
    });

    const hasNoCasesSelected = computed(() => {
      return localSelectedCaseIds.value.length === 0;
    })
    const hasSomeCasesSelected = computed( () => {
      return localSelectedCaseIds.value.length > 0 && localSelectedCaseIds.value.length < props.cases.length;
    });
    const hasAllCasesSelected = computed(() => {
      return localSelectedCaseIds.value.length === props.cases.length;
    });

    function toggleCaseSelected(c) {
      const idx = localSelectedCaseIds.value.findIndex((caseId) => {
        return caseId === c.id;
      });

      if (idx < 0) {  // this will be -1 if not in array
        localSelectedCaseIds.value.push(c.id);
        return c;
      }

      // if it's not true, it's false
      localSelectedCaseIds.value = localSelectedCaseIds.value.filter((caseId) => {
        return caseId !== c.id;
      });
    }

    function headerClick(key) {
      if (key === 'select') {
        handleSelectClick();
      }
    }

    function handleSelectClick() {
      if (hasAllCasesSelected.value) {
        deselectAllCases();
      } else {
        selectAllCases();
      }
    }

    function selectAllCases() {
      localSelectedCaseIds.value = props.cases.map((c) => c.id);
    }

    function deselectAllCases() {
      localSelectedCaseIds.value = [];
    }

    bus.$on('deselect-all-cases', () => {
      deselectAllCases();
    });

    bus.$on('select-all-cases', () => {
      selectAllCases();
    });

    function markTableRowSelected(rowCase, state = true) {
      if (state) {
        rowCase.select = true;  // this makes the checkbox appear checked
        rowCase._rowVariant = 'primary';  // set the Bootstrap variant for the row
      } else {
        rowCase.select = false;  // this makes the checkbox appear checked
        delete rowCase._rowVariant;  // delete the property
      }
      return rowCase;
    }

    watch([sortBy, sortDesc], () => {
      if (sortBy.value) {  // if you clicked a header that was not sortable, skip this
        emit("sort-changed", {
          sortBy: sortBy.value,
          sortDirection: (sortDesc.value) ? 'desc':'asc',
        })
      }
    });
    watch([currentPage, rowsPerPage], () => {
      emit("page-changed", {
        currentPage: currentPage.value,
        rowsPerPage: rowsPerPage.value
      });
    });
    watch(rowsPerPage, () => {
      localStorage.rowsPerPage = rowsPerPage.value;
    })

    function handleCaseRowClicked(caseObj, rowIdx, event) {
      if (event.target.localName.toLowerCase() === 'td') {
        emit('case-clicked', caseObj);
      }
    }

    function isCaseSelected(row) {
      return localSelectedCaseIds.value.findIndex((selectedId) => selectedId === row.id) >= 0;
    }

    function getRowClass(row){
      return isCaseSelected(row) ? 'table-primary': '';
    }

    return {
      fields,
      handleCaseRowClicked,
      localSelectedCaseIds,
      sortBy,
      sortDesc,
      rowsPerPage,
      currentPage,
      toggleCaseSelected,
      selectAllCases,
      deselectAllCases,
      isCaseSelected,
      hasNoCasesSelected,
      hasSomeCasesSelected,
      hasAllCasesSelected,
      headerClick,
      handleSelectClick,
      getRowClass,
    }

  }
}
</script>

<style >
  .table thead th {
    vertical-align: middle;
  }
</style>
