import bus from "../bus";
import settingsTemplate from "./enrollment_period_settings.html";
import Api from "../api";
import formats from "../models/formats";

let EnrollmentPeriodSettings = {
  name: "EnrollmentPeriodSettings",

  props: [
    "caseObject",
  ],
  template: settingsTemplate,
  data: function() {
    return {
      start_date: formats.dateToUtc(this.caseObject.enrollment_start_date),
      end_date: formats.dateToUtc(this.caseObject.enrollment_end_date),
    }
  },
  created() {

  },
  methods: {
    updateCaseData() {
      bus.$emit('case-data-changed', {
        enrollment_open: this.caseObject.enrollment_open,// === "true",
        enrollment_start_date: formats.utcToIso(this.start_date),
        enrollment_end_date: formats.utcToIso(this.end_date),
      });
    }
  },
  watch: {

  },
};

export default EnrollmentPeriodSettings;