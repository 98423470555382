
<script>
import bus from "../bus";

export default {
  name: "HideNavigation",
  render() {
    return "";
  },
  created() {
    bus.$emit('hide-navigation');
  }
}
</script>